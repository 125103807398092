import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import { Link, useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import axios from "axios";
import { FaEnvelopeOpenText, FaNetworkWired } from "react-icons/fa";
import PieChart from "../components/PieChart";
import TodayUpdates from "../components/Dashboard/TodayUpdates";

const EmployeeDashboard = () => {
  const navigate = useNavigate();
  const id = localStorage.getItem("userID");
  const [isVisible, setIsVisible] = useState(true);
  const [remainingLeaves, setRemainingLeaves] = useState(0);
  const [totalHoursWorked, setTotalHoursWorked] = useState(0);
  const [timesheetData, setTimesheetData] = useState([]);

  const [employeeCode, setEmployeeCode] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [generatedUrl, setGeneratedUrl] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{
      data: [],
      backgroundColor: [],
    }],
  });

  const closeModal = () => {
    setIsVisible(false);
    getEmployeeById();
  };
  useEffect(() => {
    const userRole = localStorage.getItem("userRole");
    if (userRole === "admin") {
      navigate("/admin-dashboard");
    } else if (userRole === "employee") {
      navigate("/employee-dashboard");
    } else if (userRole === "manager") {
      navigate("/manager-dashboard");
    } else if (userRole === "hr") {
      navigate("/hr-dashboard");
    }
  }, []);
  
  useEffect(() => {
    getEmployeeById();
  }, [id]);

  const generateQRCode = () => {
    const qrUrl = `https://rekonsys.tech/attendance/${id}`;
    setGeneratedUrl(qrUrl);
  };
  const getEmployeeById = async () => {
    try {
      const res = await axios.get(`https://api.rekonsys.tech/auth/user/${id}`);
      setEmployeeName(`${res.data.firstName} ${res.data.lastName}`);
      setEmployeeCode(res.data.employeeCode);
      setRemainingLeaves(res.data.remainingLeaves)
      if (res.data.deviceToken !== "newuser") {
        localStorage.setItem("deviceToken", res.data.deviceToken);
      }
      generateQRCode();
    } catch (error) {
      console.error("Error fetching user", error);
    }
  };

  useEffect(() => {
    fetchTimesheetData();
    fetchCategoriesData();
  }, [employeeCode]);

  
  
  const fetchTimesheetData = async () => {
    try {
      const response = await axios.get(`https://api.rekonsys.tech/employee-timesheet?employeeCode=${employeeCode}`);
      setTimesheetData(response.data);
    } catch (error) {
      console.error('Error fetching timesheet data:', error);
    }
  };
  const fetchCategoriesData = async () => {
    try {
      const response = await axios.get("https://api.rekonsys.tech/categories");
      setCategoriesData(response.data);
    } catch (error) {
      console.error('Error fetching categories data:', error);
    }
  };

  useEffect(() => {
    // Calculate total hours worked when timesheetData changes
    const totalHours = timesheetData.reduce((total, entry) => total + entry.hoursWorked, 0);
    setTotalHoursWorked(totalHours);

    // Calculate total hours worked for each category
    const categoryHours = {};
    timesheetData.forEach(entry => {
      if (categoryHours.hasOwnProperty(entry.category)) {
        categoryHours[entry.category] += entry.hoursWorked;
      } else {
        categoryHours[entry.category] = entry.hoursWorked;
      }
    });

    // Prepare data for the pie chart
    const labels = Object.keys(categoryHours);
    const data = Object.values(categoryHours);

    // Map category names to their corresponding colors
    const categoryColors = {};
    categoriesData.forEach(category => {
      categoryColors[category.categoryName] = category.color;
    });

    // Set backgroundColor in chartData with colors from categoriesData
    const backgroundColor = labels.map(label => categoryColors[label]);

    setChartData({
      labels: labels,
      datasets: [{
        data: data,
        backgroundColor: backgroundColor,
      }],
    });
  }, [timesheetData]);

  
  return (
    <div>
      <Navbar />

      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          <BreadCrumbs
            paths={[
              { url: "/employee-dashboard", label: "Home" },
              { url: "/employee-dashboard", label: "Dashboard" },
              { url: "", label: "Employee Dashboard" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/employee-dashboard", active: true },
              { text: "Attendance", path: "/employee-attendance-list" },
              { text: "Calendar", path: "/employee-calendar" },
              { text: "Timesheets", path: "/employee-timesheet-list" },
              { text: "Leaves", path: "/employee-leaves-list" },
            ]}
          />
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div className="flex flex-col gap-5 w-full">
            <div className="flex flex-col sm:flex-row gap-5 w-full items-center md:justify-between">
              <div className="bg-white w-full md:w-1/2 p-4 rounded-lg shadow-md flex items-center">
                <div className="flex items-center justify-center w-12 h-12 bg-blue-500 text-white rounded-full">
                  <FaNetworkWired className="w-6 h-6" />
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-semibold">Total Work Hours</h3>
                  <p className="text-xl font-bold text-gray-800">
                    {totalHoursWorked}
                  </p>
                </div>
              </div>
              <div className="bg-white w-full md:w-1/2 p-4 rounded-lg shadow-md flex items-center">
                <div className="flex items-center justify-center w-12 h-12 bg-blue-500 text-white rounded-full">
                  <FaEnvelopeOpenText className="w-6 h-6" />
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-semibold">Remaining Leaves</h3>
                  <p className="text-xl font-bold text-gray-800">
                    {remainingLeaves}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-5">
              <div className="bg-white w-full md:w-1/2 p-4 rounded-lg shadow-md flex gap-5 flex-col items-center">
                <h3 className="text-xl font-semibold">Total works</h3>
                <div className="w-fullh-80 flex flex-col justify-center overflow-y-auto">
                  <PieChart data={chartData}/>
                </div>
              </div>
              <TodayUpdates />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDashboard;
