import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import { Link } from "react-router-dom";
import axios from "axios";
import DataTables from "../components/Datatables";
import { FaEye } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import {
  FaUser,
  FaBriefcase,
  FaGraduationCap,
  FaMoneyCheckAlt,
  FaElementor,
  FaRegAddressCard,
} from "react-icons/fa";
import jsPDF from "jspdf";

const PaySlip = () => {
  const { id } = useParams();
  const [employeeData, setEmployeeData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    password: "",
    gender: "",
    dob: "",
    contactNo: "",
    bloodGroup: "",
    emergencyContactNo: "",
    hobbies: "",
    permanentAddress: "",
    presentAddress: "",
    dateOfJoining: "",
    employeeCode: "",
    employeeDocs: "",
    deviceToken: "",
    role: "",
    position: "",
    department: "",
    educationData: [{}],
    workExperienceData: [{}],
    bankAccountHolderName: "",
    bankAccountName: "",
    bankAccountNumber: "",
    IFSCCode: "",
    PANcardNo: "",
    UAN: "",
    permanentPresentSame: false,
  });

  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    employeeID: "",
    PANno: "",
    UANno: "",
    gender: "",
    designation: "",
    dateOfJoining: "",
    bankName: "",
    bankAccountNumber: "",
    standardDays: "",
    daysWorked: "",
    basicSalary: "",
    HRA: "",
    travellingAndMedicalAllowance: "",
    foodAllowance: "",
    telephoneAllowance: "",
    leaveTravelAllowance: "",
    arrears: "",
    specialAllowance: "",
    totalSalary: "",
    netSalary: "",
    professionalTax: "",
    taxDeductedAtSource: "",
    providentFund: "",
    salaryAdvance: "",
    otherDeductions: "",
    totalDeduction: "",
    monthPaySlip: "",
    yearPaySlip: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const [userRole, setUserRole] = useState("");
  useEffect(() => {
    window.history.replaceState({}, '')
    setUserRole(localStorage.getItem("userRole"));
    getEmployeeById();
  }, [id]);

  const getEmployeeById = async () => {
    try {
      const response = await axios.get(
        `https://api.rekonsys.tech/auth/user/${id}`
      );
      setEmployeeData(response.data);
      setEmployeeData((prevData) => ({
        ...prevData,
        dob: new Date(response.data.dob).toISOString().split("T")[0],
        dateOfJoining: new Date(response.data.dateOfJoining)
          .toISOString()
          .split("T")[0],
        educationData: response.data.education,
        workExperienceData: response.data.workExperience,
      }));
      setFormData((prevData) => ({
        ...prevData,
        firstName: response.data.firstName,
        middleName: response.data.middleName,
        lastName: response.data.lastName,
        employeeID: response.data.employeeCode,
        PANno: response.data.PANcardNo,
        UANno: response.data.UAN,
        gender: response.data.gender,
        designation: response.data.position,
        dateOfJoining: new Date(response.data.dateOfJoining)
          .toISOString()
          .split("T")[0],
        bankName: response.data.bankAccountName,
        bankAccountNumber: response.data.bankAccountNumber,
      }));
    } catch (error) {
      console.error("Error fetching user", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const yearOptions = (startYear) => {
    const options = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= startYear; year--) {
      options.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }

    return options;
  };

  const validateForm = () => {
    const requiredFields = [
      "basicSalary",
      "totalSalary",
      "netSalary",
      "professionalTax",
      "totalDeduction",
      "monthPaySlip",
      "yearPaySlip",
    ];
    for (const field of requiredFields) {
      if (!formData[field]) {
        setTimeout(() => {
          setError("");
        }, 3000);

        return { isValid: false, message: `${field} is required` };
      }
    }
    return { isValid: true };
  };
  const successClose = () => {
    setSuccessMessage("");
  };

  const errorClose = () => {
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationResult = validateForm();
    if (validationResult.isValid) {
      setIsLoading(true);
      // generatePDF(formData);
      navigate('/payslip', { 
        state: { 
          salaryDetails: formData
        } 
      });
    } else {
      // Handle form validation error
      setError(validationResult.message);
    }
  };

  const generatePDF = (data) => {
    const {
      employeeID,
      monthPaySlip,
      yearPaySlip,
      firstName,
      lastName,
      PANno,
      UANno,
      gender,
      designation,
      dateOfJoining,
      standardDays,
      daysWorked,
      bankName,
      bankAccountNumber,
      basicSalary,
      HRA,
      travellingAndMedicalAllowance,
      foodAllowance,
      telephoneAllowance,
      leaveTravelAllowance,
      arrears,
      specialAllowance,
      totalSalary,
      netSalary,
      professionalTax,
      taxDeductedAtSource,
      providentFund,
      salaryAdvance,
      otherDeductions,
      totalDeduction,
      netSalaryWords,
    } = data;

    const pdf = new jsPDF();

    // Customize PDF content based on form data
    pdf.text(`Employee ID: ${employeeID}`, 10, 10);
    pdf.text(`Name: ${firstName} ${lastName}`, 10, 20);
    pdf.text(`PAN No: ${PANno}`, 10, 30);
    pdf.text(`UAN No: ${UANno}`, 10, 40);
    pdf.text(`Gender: ${gender}`, 10, 50);
    pdf.text(`Designation: ${designation}`, 10, 60);
    pdf.text(`Date of Joining: ${dateOfJoining}`, 10, 70);
    pdf.text(`Standard Days: ${standardDays}`, 10, 80);
    pdf.text(`Days Worked: ${daysWorked}`, 10, 90);
    pdf.text(`Bank Name: ${bankName}`, 10, 100);
    pdf.text(`Bank Account Number: ${bankAccountNumber}`, 10, 110);
    pdf.text(`Basic Salary: ${basicSalary}`, 10, 120);
    pdf.text(`HRA: ${HRA}`, 10, 130);
    pdf.text(
      `Travelling and Medical Allowance: ${travellingAndMedicalAllowance}`,
      10,
      140
    );
    pdf.text(`Food Allowance: ${foodAllowance}`, 10, 150);
    pdf.text(`Telephone Allowance: ${telephoneAllowance}`, 10, 160);
    pdf.text(`Leave Travel Allowance: ${leaveTravelAllowance}`, 10, 170);
    pdf.text(`Arrears: ${arrears}`, 10, 180);
    pdf.text(`Special Allowance: ${specialAllowance}`, 10, 190);
    pdf.text(`Total Salary: ${totalSalary}`, 10, 200);
    pdf.text(`Net Salary: ${netSalary}`, 10, 210);
    pdf.text(`Professional Tax: ${professionalTax}`, 10, 220);
    pdf.text(`Tax Deducted at Source: ${taxDeductedAtSource}`, 10, 230);
    pdf.text(`Provident Fund: ${providentFund}`, 10, 240);
    pdf.text(`Salary Advance: ${salaryAdvance}`, 10, 250);
    pdf.text(`Other Deductions: ${otherDeductions}`, 10, 260);
    pdf.text(`Total Deduction: ${totalDeduction}`, 10, 270);
    pdf.text(`Net Salary Words: ${netSalaryWords}`, 10, 280);

    // Save PDF
    const filename = `${employeeID}_${monthPaySlip}_${yearPaySlip}.pdf`;
    pdf.save(filename);
  };

  return (
    <div>
      {successMessage && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-success"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-green-100 rounded-lg shadow"
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <span className="sr-only">Check icon</span>
            </div>
            <div className="ms-3 text-md font-normal mx-5">
              {successMessage}
            </div>
            <button
              type="button"
              onClick={successClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
              data-dismiss-target="#toast-success"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      {error && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-danger"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-red-100 rounded-lg shadow"
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="ms-3 text-md font-normal mx-5">{error}</div>
            <button
              type="button"
              onClick={errorClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
              data-dismiss-target="#toast-danger"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          {userRole == "admin" && (
            <>
              <BreadCrumbs
                paths={[
                  { url: "/admin-dashboard", label: "Home" },
                  { url: "/employee-list", label: "Employees" },
                  { url: "", label: "Employees" },
                ]}
              />

              <Sidebar
                menuItems={[
                  { text: "Home", path: "/admin-dashboard" },
                  { text: "Employees", path: "/employee-list", active: true },
                  { text: "Attendance", path: "/attendance-list" },
                  { text: "Calendar", path: "/admin-calendar" },
                  { text: "Timesheets", path: "/timesheet-list" },
                  { text: "Leaves", path: "/leaveslist" },
                  { text: "Projects", path: "/projects-list" },
                  { text: "Categories", path: "/categories-list" },
                  { text: "Roles", path: "/role" },
                  { text: "Departments", path: "/department" },
                  { text: "Positions", path: "/position" },
                  { text: "Reports", path: "/reports" },
                ]}
              />
            </>
          )}
          {userRole == "hr" && (
            <>
              <BreadCrumbs
                paths={[
                  { url: "/hr-dashboard", label: "Home" },
                  { url: "/employee-list", label: "Employees" },
                  { url: "", label: "Employees" },
                ]}
              />
              <Sidebar
                menuItems={[
                  { text: "Home", path: "/hr-dashboard" },
                  { text: "Employees", path: "/employee-list", active: true },
                  { text: "Calendar", path: "/admin-calendar" },
                  { text: "Reports", path: "/reports" },
                ]}
              />
            </>
          )}
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div>
            <header className="bg-white border-gray-300 border-b-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center mb-2">
                <h1 className="text-xl md:text-3xl font-semibold">
                  Pay Slips -
                  {employeeData.firstName + " " + employeeData.lastName}
                </h1>
              </div>
            </header>
          </div>
          <div className="container mx-auto px-4 py-8">
            <form onSubmit={handleSubmit}>
              <div className="mt-4 px-4 leading-relaxed flex flex-col gap-3 text-gray-700">
                <div className="w-full flex flex-col md:flex-row gap-5">
                  <div className="w-full flex flex-col">
                    <label>First Name:</label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      type="text"
                      name="firstName"
                      disabled
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full flex flex-col">
                    <label>Middle Name:</label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      type="text"
                      name="middleName"
                      disabled
                      value={formData.middleName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full flex flex-col">
                    <label>Last Name:</label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      type="text"
                      name="lastName"
                      disabled
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-row gap-5">
                  <div className="w-full flex flex-col">
                    <label>Employee ID:</label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      type="text"
                      disabled
                      name="employeeID"
                      value={formData.employeeID}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full flex flex-col">
                    <label>PAN No:</label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      type="text"
                      disabled
                      name="PANno"
                      value={formData.PANno}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full flex flex-col">
                    <label>UAN No:</label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      type="text"
                      disabled
                      name="UANno"
                      value={formData.UANno}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-row gap-5">
                  <div className="w-full flex flex-col">
                    <label>Gender:</label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      type="text"
                      disabled
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full flex flex-col">
                    <label>Designation:</label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      type="text"
                      disabled
                      name="designation"
                      value={formData.designation}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full flex flex-col">
                    <label>Date of Joining:</label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      type="text"
                      disabled
                      name="dateOfJoining"
                      value={formData.dateOfJoining}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-row gap-5">
                  <div className="w-full flex flex-col">
                    <label>Bank Name:</label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      type="text"
                      disabled
                      name="bankName"
                      value={formData.bankName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full flex flex-col">
                    <label>Bank Account Number:</label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      type="text"
                      disabled
                      name="bankAccountNumber"
                      value={formData.bankAccountNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-row gap-5">
                  <div className="w-full flex flex-col">
                    <label>Month Pay Slip:</label>
                    <select
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      name="monthPaySlip"
                      value={formData.monthPaySlip}
                      onChange={handleChange}
                    >
                      <option value="">Select Month of PaySlip</option>
                      <option value="January">January</option>
                      <option value="February">February</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </select>
                  </div>
                  <div className="w-full flex flex-col">
                    <label>Year Pay Slip:</label>

                    <select
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      name="yearPaySlip"
                      value={formData.yearPaySlip}
                      onChange={handleChange}
                    >
                      <option value="">Select Year of PaySlip</option>
                      {yearOptions(2020)}
                    </select>
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-row gap-5">
                  <div className="w-full flex flex-col">
                    <label>Standard Days:</label>
                    <select
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      name="standardDays"
                      value={formData.standardDays}
                      onChange={handleChange}
                    >
                      <option value="">Select Days in Month</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                    </select>
                  </div>
                  <div className="w-full flex flex-col">
                    <label>Days Worked:</label>

                    <select
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      name="daysWorked"
                      value={formData.daysWorked}
                      onChange={handleChange}
                    >
                      <option value="">Select Days Worked</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                    </select>
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-row gap-5">
                  <div className="w-full flex flex-col gap-5">
                    <h1 className="text-xl font-semibold">Earning:</h1>
                    <div className="w-full flex flex-col">
                      <label>Basic Salary:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        type="number"
                        name="basicSalary"
                        value={formData.basicSalary}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>HRA:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        type="number"
                        name="HRA"
                        value={formData.HRA}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>Travelling and Medical Allowance:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        type="number"
                        name="travellingAndMedicalAllowance"
                        value={formData.travellingAndMedicalAllowance}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>Food Allowance:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        type="number"
                        name="foodAllowance"
                        value={formData.foodAllowance}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>Telephone Allowance:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        type="number"
                        name="telephoneAllowance"
                        value={formData.telephoneAllowance}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>Leave Travel Allowance:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        type="number"
                        name="leaveTravelAllowance"
                        value={formData.leaveTravelAllowance}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>Arrears:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        type="number"
                        name="arrears"
                        value={formData.arrears}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>Special Allowance:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        type="number"
                        name="specialAllowance"
                        value={formData.specialAllowance}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>Total Salary:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        type="number"
                        name="totalSalary"
                        value={formData.totalSalary}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-col gap-5">
                    <h1 className="text-xl font-semibold">Deductions:</h1>
                    <div className="w-full flex flex-col">
                      <label>Professional Tax:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        type="number"
                        name="professionalTax"
                        value={formData.professionalTax}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>Tax Deducted at Source:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        type="number"
                        name="taxDeductedAtSource"
                        value={formData.taxDeductedAtSource}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>Provident Fund:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        type="number"
                        name="providentFund"
                        value={formData.providentFund}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>Salary Advance:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        type="number"
                        name="salaryAdvance"
                        value={formData.salaryAdvance}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>Other Deductions:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        type="number"
                        name="otherDeductions"
                        value={formData.otherDeductions}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>Total Deduction:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        type="number"
                        name="totalDeduction"
                        value={formData.totalDeduction}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full flex flex-col gap-5">
                  <div className="w-full flex flex-col">
                    <h1 className="text-xl font-semibold mb-2">
                      Salary After Deductions:
                    </h1>
                    <label>Net Salary:</label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      type="number"
                      name="netSalary"
                      value={formData.netSalary}
                      onChange={handleChange}
                    />
                  </div>
                  
                </div>
                <div className="flex row justify-center gap-5 pt-3">
                  <button
                    className="block rounded-md bg-blue-700 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-[#063081]"
                    type="submit"
                  >
                    Generate PDF
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaySlip;
