import React from "react";

const Faq = () => {
  return (
    <div id="faq">
  <section className="bg-[#1D1D1D] text-gray-100 py-32 min-h-screen">
    <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
      <h2 className="mb-12 text-4xl font-bold leadi text-center sm:text-5xl">
        Frequently Asked Questions
      </h2>
      <div className="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 divide-gray-700">
        
        {/* <!-- AI FAQs --> */}
        <details>
          <summary className="py-2 outline-none cursor-pointer focus:underline">
            How long does it take to develop a custom AI solution?
          </summary>
          <div className="px-4 pb-4">
            <p>
              The development timeline for an AI solution can vary depending on the complexity of the project and specific requirements. Our team works closely with clients to establish clear timelines and deliverables to ensure timely completion.
            </p>
          </div>
        </details>

        <details>
          <summary className="py-2 outline-none cursor-pointer focus:underline">
            What are the benefits of incorporating Blockchain into my business?
          </summary>
          <div className="px-4 pb-4">
            <p>
              Blockchain technology offers numerous benefits, including enhanced security, transparency, and efficiency. By leveraging Blockchain, businesses can streamline processes, reduce costs, and build trust with stakeholders.
            </p>
          </div>
        </details>

        {/* <!-- Web Applications FAQs --> */}
        <details>
          <summary className="py-2 outline-none cursor-pointer focus:underline">
            How do you approach user research and usability testing for web applications?
          </summary>
          <div className="px-4 pb-4">
            <p>
              We conduct user research to understand the needs and behaviors of your target audience. We then use this data to create user personas, conduct usability testing, and iterate on designs to ensure optimal user experiences.
            </p>
          </div>
        </details>

        <details>
          <summary className="py-2 outline-none cursor-pointer focus:underline">
            Can you create custom web applications that integrate with third-party APIs?
          </summary>
          <div className="px-4 pb-4">
            <p>
              Yes, we have experience integrating custom web applications with various third-party APIs to enhance functionality and streamline processes. Whether it's payment gateways, social media platforms, or CRM systems, we can integrate it seamlessly into your web application.
            </p>
          </div>
        </details>

        {/* <!-- Mobile Applications FAQs --> */}
        <details>
          <summary className="py-2 outline-none cursor-pointer focus:underline">
            What platforms do you develop mobile applications for?
          </summary>
          <div className="px-4 pb-4">
            <p>
              We develop mobile applications for both iOS and Android platforms. Whether you need a native app, cross-platform app, or hybrid app, we have the expertise to deliver high-quality mobile experiences tailored to your target audience.
            </p>
          </div>
        </details>

        <details>
          <summary className="py-2 outline-none cursor-pointer focus:underline">
            How do you ensure cross-device compatibility and performance optimization for mobile applications?
          </summary>
          <div className="px-4 pb-4">
            <p>
              We conduct thorough testing across multiple devices and screen sizes to ensure compatibility. Additionally, we employ performance optimization techniques such as code optimization, image compression, and caching to ensure fast and responsive mobile experiences.
            </p>
          </div>
        </details>

        {/* <!-- UI/UX Designs FAQs --> */}
        <details>
          <summary className="py-2 outline-none cursor-pointer focus:underline">
            How do you approach user research and usability testing for UI/UX designs?
          </summary>
          <div className="px-4 pb-4">
            <p>
              We conduct user research to understand the needs and behaviors of your target audience. We then use this data to create user personas, conduct usability testing, and iterate on designs to ensure optimal user experiences.
            </p>
          </div>
        </details>

        <details>
          <summary className="py-2 outline-none cursor-pointer focus:underline">
            What tools and methodologies do you use for UI/UX design?
          </summary>
          <div className="px-4 pb-4">
            <p>
              We use a combination of tools such as Sketch, Adobe XD, Figma, and InVision for designing prototypes and conducting user testing. We follow design methodologies such as design thinking and agile design to ensure a collaborative and iterative approach to design.
            </p>
          </div>
        </details>

        {/* <!-- Software Consulting FAQs --> */}
        <details>
          <summary className="py-2 outline-none cursor-pointer focus:underline">
            How can software consulting help my business achieve its goals?
          </summary>
          <div className="px-4 pb-4">
            <p>
              Software consulting provides strategic guidance and expertise to help businesses make informed decisions about technology adoption, digital transformation, and software development. We assess your current processes, identify areas for improvement, and develop a roadmap to achieve your business objectives.
            </p>
          </div>
        </details>

        <details>
          <summary className="py-2 outline-none cursor-pointer focus:underline">
            What industries do you specialize in for software consulting?
          </summary>
          <div className="px-4 pb-4">
            <p>
              We have experience working across various industries, including healthcare, finance, e-commerce, manufacturing, and more. Our team of consultants has the expertise to understand industry-specific challenges and tailor solutions to meet your unique needs.
            </p>
          </div>
        </details>

        {/* <!-- Quality Assurance FAQs --> */}
        <details>
          <summary className="py-2 outline-none cursor-pointer focus:underline">
            What testing methodologies do you employ for quality assurance?
          </summary>
          <div className="px-4 pb-4">
            <p>
              We utilize a combination of manual testing and automated testing methodologies such as unit testing, integration testing, regression testing, and performance testing to ensure the reliability, functionality, and performance of your software.
            </p>
          </div>
        </details>

        <details>
          <summary className="py-2 outline-none cursor-pointer focus:underline">
            How do you ensure quality assurance throughout the software development lifecycle?
          </summary>
          <div className="px-4 pb-4">
            <p>
              We integrate quality assurance processes into every stage of the software development lifecycle, from requirements gathering to deployment. This ensures that quality is maintained at every step and reduces the likelihood of defects and issues in the final product.
            </p>
          </div>
        </details>

        {/* <!-- Cloud Solutions FAQs --> */}
        <details>
          <summary className="py-2 outline-none cursor-pointer focus:underline">
            What are the benefits of migrating to the cloud?
          </summary>
          <div className="px-4 pb-4">
            <p>
              Migrating to the cloud offers numerous benefits, including cost savings, scalability, flexibility, enhanced security, and improved collaboration. Cloud solutions enable businesses to streamline operations, innovate faster, and stay competitive in the digital age.
            </p>
          </div>
        </details>

        <details>
          <summary className="py-2 outline-none cursor-pointer focus:underline">
            What cloud platforms do you work with?
          </summary>
          <div className="px-4 pb-4">
            <p>
              We have experience working with leading cloud platforms such as Amazon Web Services (AWS), Microsoft Azure, and Google Cloud Platform (GCP). Whether you need infrastructure-as-a-service (IaaS), platform-as-a-service (PaaS), or software-as-a-service (SaaS) solutions, we can help you leverage the power of the cloud.
            </p>
          </div>
        </details>

      </div>
    </div>
  </section>
</div>

  );
};

export default Faq;
