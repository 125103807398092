import React from "react";

function Features() {
  const dummyData = [
    { id: 1, title: "Web Development", description: "Deliver seamless digital experiences to your customers with our custom web application development services. From e-commerce platforms to content management systems, we design and develop scalable web applications that meet your unique business needs." },
    { id: 2, title: "Mobile App Development", description: "Transform your ideas into reality with our mobile app development services. Whether you need iOS, Android, or cross-platform apps, we've got you covered with our expertise in mobile technologies." },
    { id: 3, title: "UI/UX Design", description: "Enhance user satisfaction and engagement with our UI/UX design services. Our talented designers will create intuitive and visually appealing interfaces that resonate with your target audience." },
    { id: 4, title: "Artificial Intelligence (AI)", description: "Harness the power of AI to unlock valuable insights, automate processes, and drive innovation in your business. Our AI solutions leverage advanced algorithms and machine learning techniques to deliver actionable intelligence and transform the way you operate." },
    { id: 5, title: "Blockchain", description: "Empower your business with Blockchain technology to enhance security, transparency, and trust in your transactions. From supply chain management to decentralized finance, our Blockchain solutions offer unparalleled security and efficiency for your business operations." },
    { id: 6, title: "Cloud Solutions", description: "Optimize your infrastructure and streamline your operations with our cloud solutions. From cloud migration to cloud-native development, we'll help you leverage the power of the cloud for scalability and efficiency." }
  ];  
  return (
    <section>
      <div className="max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
        <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16">
          <div className="mx-auto max-w-lg text-center lg:mx-0 ltr:lg:text-left rtl:lg:text-right">
            <h2 className="text-3xl font-bold sm:text-4xl">
              Find your career path
            </h2>

            <p className="mt-4 text-gray-600">
            In a rapidly evolving digital landscape, finding a better way to solve complex challenges is essential. At Rekonsys, we leverage our expertise in AI, Blockchain, and software solutions to redefine what's possible for your business. By combining innovative technologies with strategic thinking, we help you navigate the complexities of the digital world and find a better way forward.
            </p>

            <a
              href="#contactUs"
              className="mt-8 inline-block rounded bg-indigo-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
            >
              Get Started Today
            </a>
          </div>

          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
            {dummyData.map((item) => (
              <div
                key={item.id}
                className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                
              >
                <span className="inline-block rounded-lg bg-gray-50 p-3">
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                    <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                    ></path>
                  </svg>
                </span>
                <h2 className="mt-2 font-bold">{item.title}</h2>
                <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
