import React, { useState, useEffect } from "react";
import Navbar from "../components/Dashboard/navbar";
import Sidebar from "../components/Dashboard/Sidebar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const EditDepartment = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [department, setDepartment] = useState({
    name: "",
    status: "",
    backgroundColor: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    fetchDepartment();
  }, []);

  const fetchDepartment = async () => {
    try {
      const response = await axios.get(
        `https://api.rekonsys.tech/department/${id}`
      );
      setDepartment(response.data.department);
    } catch (error) {
      console.error("Error fetching department:", error);
    }
  };

  const validateForm = () => {
    const requiredFields = ["name", "status", "backgroundColor"];
    for (const field of requiredFields) {
      if (!department[field]) {
        setTimeout(() => {
          setError("");
        }, 3000);

        return { isValid: false, message: `${field} is required` };
      }
    }
    return { isValid: true };
  };

  const handleChange = (e) => {
    setDepartment({
      ...department,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationResult = validateForm();
    if (validationResult.isValid) {
    setIsLoading(true);
    try {
      const res = await axios.put(
        `https://api.rekonsys.tech/department-update/${id}`,
        department
      );
      if (res.status === 200) {
        setSuccessMessage(res.data.message);
        setTimeout(() => {
          navigate("/department");
        }, 3000);
      } else {
        setError(res.data.error);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setError("An error occurred while submitting the form.");
    } finally {
      setIsLoading(false);
    }}else {
      // Handle form validation error
      setError(validationResult.message);
    }
  };

  const handleCancel = () => {
    navigate("/department");
  };

  const successClose = () => {
    setSuccessMessage("");
  };

  const errorClose = () => {
    setError("");
  };

  return (
    <div>
      {successMessage && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-success"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-green-100 rounded-lg shadow"
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <span className="sr-only">Check icon</span>
            </div>
            <div className="ms-3 text-md font-normal mx-5">
              {successMessage}
            </div>
            <button
              type="button"
              onClick={successClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
              data-dismiss-target="#toast-success"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      {error && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-danger"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-red-100 rounded-lg shadow"
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="ms-3 text-md font-normal mx-5">{error}</div>
            <button
              type="button"
              onClick={errorClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
              data-dismiss-target="#toast-danger"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          <BreadCrumbs
            paths={[
              { url: "/admin-dashboard", label: "Home" },
              { url: "/department", label: "Departments" },
              { url: "", label: "Edit Department" },
            ]}
          />
          <Sidebar
            menuItems={[
              { text: "Home", path: "/admin-dashboard" },
              { text: "Employees", path: "/employee-list" },
              { text: "Attendance", path: "/attendance-list" },
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Timesheets", path: "/timesheet-list" },
              { text: "Leaves", path: "/leaveslist" },
              { text: "Projects", path: "/projects-list" },
              { text: "Categories", path: "/categories-list" },
              { text: "Roles", path: "/role" },
              { text: "Departments", path: "/department", active: true },
              { text: "Positions", path: "/position" },
              { text: "Reports", path: "/reports" },
            ]}
          />
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div>
            <header className="bg-white border-gray-300 border-b-2 m-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center">
                <h1 className="text-xl md:text-3xl pb-4 font-semibold">
                  Edit Department
                </h1>
              </div>
            </header>
          </div>
          <form
            onSubmit={handleSubmit}
            className="mt-4 px-4 leading-relaxed flex flex-col gap-3 text-gray-700"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex flex-col">
                <label className="text-sm font-medium">Department Name :</label>
                <input
                  className="mt-1 w-full rounded-lg border-2 border-gray-300 p-3 text-sm"
                  type="text"
                  name="name"
                  value={department.name}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-sm font-medium">Status :</label>
                <select
                  className="mt-1 w-full rounded-lg border-2 border-gray-300 p-3 text-sm"
                  name="status"
                  value={department.status}
                  onChange={handleChange}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Background Color:
                </label>
                <input
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  type="color"
                  name="backgroundColor"
                  value={department.backgroundColor}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex row justify-center gap-5 pt-3">
              <button
                className="block rounded-md bg-[#00008B] px-5 py-2.5 text-sm font-medium text-white transition hover:bg-[#000435]"
                type="submit"
              >
                {isLoading ? "Submitting..." : "Submit"}
              </button>
              <button
                className="block rounded-md bg-red-500 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-[#8B0000]"
                type="button"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditDepartment;
