import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import DataTables from "../components/Datatables";
function PayRollReports() {
  const [payRoll, setPayRoll] = useState([]);
  const [filteredPayRoll, setFilteredPayRoll] = useState([]); // State to hold filtered data
  const [startDate, setStartDate] = useState(""); // State for start date
  const [endDate, setEndDate] = useState(""); // State for end date
  const [isLoading, setIsLoading] = useState(false); //for page loading
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    setUserRole(localStorage.getItem("userRole"));
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://api.rekonsys.tech/auth/users");
      const formattedData = response.data.map((data) => {
        const date = new Date(data.dateOfJoining);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        
        return {
          ...data,
          dateOfJoining: formattedDate,
        };
      });
      setPayRoll(formattedData);
      setFilteredPayRoll(formattedData); // Initialize filtered data with all data
  
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  

  const filterUsersByDate = () => {
    let filteredUsers;
    if (startDate && !endDate) {
      // If only start date is provided
      const startDateObj = new Date(startDate);
      filteredUsers = payRoll.filter((user) => {
        const userJoinDate = new Date(user.dateOfJoining);
        return userJoinDate.getTime() === startDateObj.getTime();
      });
    } else {
      // If both start date and end date are provided
      filteredUsers = payRoll.filter((user) => {
        const userJoinDate = new Date(user.dateOfJoining);
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        return userJoinDate >= startDateObj && userJoinDate <= endDateObj;
      });
    }
    return filteredUsers; // Return filtered users instead of setting state
  };

  const clearFilters = () => {
    setStartDate("");
    setEndDate("");
    fetchUsers(); // Reset table data to original state
  };

  // Function to download Excel file
  const downloadExcel = () => {
    const selectedColumns = [
      "firstName",
      "lastName",
      "employeeCode",
      "email",
      "UAN",
      "bankAccountName",
      "bankAccountNumber",
      "dateOfJoining",
    ];
    const dataToExport = filteredPayRoll.map((item) => {
      return Object.fromEntries(
        Object.entries(item).filter(([key, value]) =>
          selectedColumns.includes(key)
        )
      );
    });

    const ws = XLSX.utils.json_to_sheet(dataToExport, {
      header: selectedColumns,
    });
    const csv = XLSX.utils.sheet_to_csv(ws);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "PayRollReports.csv");
  };

  const columns = [
    { Header: "Sno.", accessor: "rowNumber", Cell: ({ row }) => row.index + 1 },
    { Header: "First Name", accessor: "firstName" },
    { Header: "Last Name", accessor: "lastName" },
    { Header: "Employee Code", accessor: "employeeCode" },
    { Header: "Email", accessor: "email" },
    { Header: "UAN", accessor: "UAN" },
    { Header: "Bank Account Name", accessor: "bankAccountName" },
    { Header: "Bank Account Number", accessor: "bankAccountNumber" },
    { Header: "Date Of Joining", accessor: "dateOfJoining" },
  ];

  useEffect(() => {
    const filteredUsers = filterUsersByDate(); // Get filtered users
    setFilteredPayRoll(filteredUsers); // Update filtered data
  }, [startDate, endDate]); // Trigger effect when startDate or endDate changes

  return (
    <div>
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
        {userRole == "admin" && 
          <><BreadCrumbs
            paths={[
              { url: "/admin-dashboard", label: "Home" },
              { url: "/reports", label: "Reports" },
              { url: "", label: "PayRoll Reports" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/admin-dashboard" },
              { text: "Employees", path: "/employee-list" },
              { text: "Attendance", path: "/attendance-list" },
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Timesheets", path: "/timesheet-list" },
              { text: "Leaves", path: "/leaveslist" },
              { text: "Projects", path: "/projects-list" },
              { text: "Categories", path: "/categories-list" },
              { text: "Roles", path: "/role" },
              { text: "Departments", path: "/department" },
              { text: "Positions", path: "/position" },
              { text: "Reports", path: "/reports", active: true },
            ]}
          /></>}
          {userRole == "hr" && 
          <><BreadCrumbs
            paths={[
              { url: "/hr-dashboard", label: "Home" },
              { url: "/reports", label: "Reports" },
              { url: "", label: "PayRoll Reports" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/hr-dashboard" },
              { text: "Employees", path: "/employee-list"},
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Reports", path: "/reports", active: true },
            ]}
          /></>}
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <nav
            aria-label="Breadcrumb"
            className="flex justify-center bg-white border-gray-300 border-b-2 pb-2"
          >
            <ol className="flex rounded-lg h-10 md:h-14 border border-gray-200 text-gray-600 text-lg">
              <li className="flex items-center border-r border-gray-200">
                <Link
                  to="/reports"
                  className="flex items-center h-full rounded-l-lg bg-white px-2 md:px-6 text-xs font-medium transition hover:text-gray-900"
                >
                  <span className="mx-1 text-[10px] md:text-[14px] font-medium text-center">
                    Employee Reports
                  </span>
                </Link>
                {/* <div className="h-full border-r border-gray-200"></div> */}
              </li>
              <li className="flex items-center border-r border-gray-200">
                <Link
                  to="/leave-reports"
                  className="flex items-center h-full bg-white px-2 md:px-6 text-xs font-medium transition hover:text-gray-900"
                >
                  <span className="mx-1 text-[10px] md:text-[14px] font-medium text-center">
                    Leave Reports
                  </span>
                </Link>
                {/* <div className="h-full border-r border-gray-200"></div> */}
              </li>
              <li className="flex items-center border-r border-gray-200">
                <Link
                  to="/payroll-reports"
                  className="flex items-center h-full bg-[#f1bd06] px-2 md:px-6 text-xs font-medium transition hover:text-gray-900"
                >
                  <span className="mx-1 text-[10px] md:text-[14px] font-medium text-center">
                    Payroll Reports
                  </span>
                </Link>
                {/* <div className="h-full border-r border-gray-200"></div> */}
              </li>
              <li className="flex items-center">
                <Link
                  to="/contact-reports"
                  className="flex items-center h-full bg-white px-2 md:px-6 text-xs font-medium transition hover:text-gray-900"
                >
                  <span className="mx-1 text-[10px] md:text-[14px] font-medium text-center">
                    Contact Reports
                  </span>
                </Link>
                {/* <div className="h-full border-r border-gray-200"></div> */}
              </li>
            </ol>
          </nav>
          <div className="my-4">
            <div className="w-full flex flex-col md:flex-row gap-5">
              <div className="w-full flex flex-col">
                <label htmlFor="startDate" className="mr-2">
                  Start Date:
                </label>
                <input
                  id="startDate"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md mr-2"
                  placeholder="Start Date"
                />
              </div>
              <div className="w-full flex flex-col">
                <label htmlFor="endDate" className="mr-2">
                  End Date:
                </label>
                <input
                  id="endDate"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  min={startDate} // Set min attribute to the selected start date
                  disabled={!startDate} // Disable end date if start date is not selected
                  className="border border-gray-300 p-2 rounded-md mr-2"
                  placeholder="End Date"
                />
              </div>
              <div className="w-full flex md:mt-6">
                <button
                  onClick={clearFilters}
                  className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="my-2">
            {isLoading ? (
              <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
                Loading Records.....
              </div>
            ) : (
              <div>
                {filteredPayRoll && filteredPayRoll.length > 0 ? (
                  <div>
                    <div>
                      <DataTables columns={columns} data={filteredPayRoll} />
                    </div>
                    <div className="my-6">
                      <button
                        onClick={downloadExcel}
                        className="bg-blue-700 hover:bg-[#063081] text-white font-bold py-2 px-4 rounded"
                      >
                        Download CSV
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="mt-6 text-center text-gray-600">
                    No data to display
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PayRollReports;
