import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import { Link } from "react-router-dom";
import axios from "axios";
import DataTables from "../components/Datatables";
import { FaRegEdit } from "react-icons/fa";import { FaTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
const LeavesList = () => {
  const [leaves, setLeaves] = useState([]);
  const [error, setError] = useState("");
  const [selectLeaveType, setSelectLeaveType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    setUserRole(localStorage.getItem("userRole"));
    fetchLeaves();
  }, []);

  const fetchLeaves = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://api.rekonsys.tech/all-leaves");
      const formattedData = response.data.leaves.map((data) => ({
        ...data,
        fromDate: data.fromDate.split("T")[0].slice(0, 10),
        toDate: data.toDate.split("T")[0].slice(0, 10),
      }));
      // console.log(formattedData)
      setLeaves(formattedData.reverse());
      setIsLoading(false);

    } catch (error) {
      console.error("Error fetching leaves:", error);
    }
  };

  const errorClose = () => {
    setError("");
  };
  const handleSelectLeaveChange = async (e) => {
    if (e.target.value) {
      const response = await axios.get(
        `https://api.rekonsys.tech/filter-leaves?status=${e.target.value}`
      );
      setLeaves(response.data);
      setSelectLeaveType(e.target.value);
    } else {
      fetchLeaves();
    }
  };
  const actionsChange = (status, id) => {
    if (status == "pending") {
      return (
        <div className="flex flex-col justify-center gap-1">
          {/* Approve Button */}
          <button
            type="button"
            onClick={() => {
              handleApprove(id);
            }}
            className="text-white bg-green-700 hover:bg-green-800  font-medium rounded-lg text-sm px-2 py-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-green-500"
          >
            Approve
          </button>
          {/* Reject Button */}
          <button
            onClick={() => {
              handleReject(id);
            }}
            type="button"
            className="text-white bg-red-700 hover:bg-red-800 font-medium rounded-lg text-sm px-2 py-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-red-500"
          >
            Reject
          </button>
        </div>
      );
    } else if (status == "approved") {
      return (
        <button
          type="button"
          className="text-white bg-green-700 hover:bg-green-800  font-medium rounded-lg text-sm px-3 py-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-green-500"
        >
          Approved
        </button>
      );
    } else if (status == "rejected") {
      return (
        <button
          type="button"
          className="text-white bg-red-700 hover:bg-red-800 font-medium rounded-lg text-sm px-4 py-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-red-500"
        >
          Rejected
        </button>
      );
    }
  };

  const columns = [
    { Header: "Sno.", accessor: "rowNumber", Cell: ({ row }) => row.index + 1 },
    { Header: "Employee Code", accessor: "employeeCode" },
    { Header: "Employee Email", accessor: "employeeEmail" },
    { Header: "Leave Type", accessor: "leavetype" },
    { Header: "From Date", accessor: "fromDate" },
    { Header: "To Date", accessor: "toDate" },
    { Header: "No Of Leaves", accessor: "noOfLeaves" },
    { Header: "Day", accessor: "day" },
    { Header: "Status", accessor: "status" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ column, row }) =>
        actionsChange(row.original.status, row.original._id),
      show: true,
      disableSortBy: true,
    },
  ];

  const handleApprove = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once Approved, you will not be able to change this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, Approve it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Approved!",
          "Your leave request has been Approved.",
          "success"
        );
        try {
          const response = await axios.put(
            `https://api.rekonsys.tech/status-leave/${id}`,
            { status: "approved" }
          );
          fetchLeaves();
        } catch (error) {
          setError("Leave not found");
          setTimeout(() => {
            setError("");
          }, 5000);
        }
      }
    });
  };

  const handleReject = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once Rejected, you will not be able to change this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, Reject it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Rejected!",
          "Your leave request has been Rejected.",
          "error"
        );
        try {
          const response = await axios.put(
            `https://api.rekonsys.tech/status-leave/${id}`,
            { status: "rejected" }
          );
          fetchLeaves();
        } catch (error) {
          setError("Leave not found");
          setTimeout(() => {
            setError("");
          }, 5000);
        }
      }
    });
  };

  return (
    <div>
      <Navbar />
      {error && (
        <div className="fixed flex justify-center top-0 left-1/2 transform -translate-x-1/2 w-2/4 z-50 p-2 mt-4">
          <div
            id="toast-danger"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-red-100  rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg ">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="ms-3 text-md font-normal">{error}</div>
            <button
              type="button"
              onClick={errorClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 "
              data-dismiss-target="#toast-danger"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          
          {userRole == "admin" &&
          <>
          <BreadCrumbs
           paths={[
            { url: "/admin-dashboard", label: "Home" },
            { url: "/leaveslist", label: "Leaves" },
              { url: "", label: "Leaves" },
          ]}
          />

          <Sidebar
             menuItems={[
              { text: "Home", path: "/admin-dashboard" },
              { text: "Employees", path: "/employee-list" },
              { text: "Attendance", path: "/attendance-list" },
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Timesheets", path: "/timesheet-list" },
              { text: "Leaves", path: "/leaveslist", active: true },
              { text: "Projects", path: "/projects-list" },
              { text: "Categories", path: "/categories-list" },
              { text: "Roles", path: "/role" },
              { text: "Departments", path: "/department" },
              { text: "Positions", path: "/position" },
              { text: "Reports", path: "/reports" },
            ]}
          />
          </>}
          {userRole == "manager" &&
          <>
          <BreadCrumbs
            paths={[
              { url: "/manager-dashboard", label: "Home" },
              { url: "/leaveslist", label: "Leaves" },
              { url: "", label: "Leaves" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/manager-dashboard"},
              { text: "Attendance", path: "/attendance-list"},
              { text: "Timesheets", path: "/timesheet-list"},
              { text: "Leaves", path: "/leaveslist", active: true },
              { text: "Projects", path: "/projects-list" }
            ]}
          /> </>}
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div>
            <header className="bg-white border-gray-300 border-b-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center mb-2">
                <h1 className="text-xl md:text-3xl font-semibold">
                  Leaves List
                </h1>

                <div className="my-3">
                  <select
                    onChange={handleSelectLeaveChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  w-28 md:w-48 p-2.5 "
                  >
                    <option value="" selected>
                      All Leaves
                    </option>
                    <option value="approved">Approved</option>
                    <option value="pending">Pending</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>
              </div>
            </header>
          </div>
          {/* <div className="w-[20%] my-3">
            <select
              onChange={handleSelectLeaveChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="" selected>
                All Leaves
              </option>
              <option value="approved">Approved</option>
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
            </select>
          </div> */}

          {/* Datatables */}
          <div>
            {/**{leaves.length > 0 ? (
              <div className="overflow-x-auto">
                <DataTables columns={columns} data={leaves} />
              </div>
            ) : (
              <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
                No records found
              </div>
            )} */}
            {isLoading ? (
              <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
                Loading Records.....
              </div>
            ) : (
              <div className="overflow-x-auto">
                {leaves && leaves.length > 0 ? (
                  <DataTables columns={columns} data={leaves} />
                ) : (
                  <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
                    No records found
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeavesList;
