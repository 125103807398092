import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import axios from "axios";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import DataTables from "../components/Datatables";
function Reports() {
  const [roles, setRoles] = useState([]);
  const [department, setDepartment] = useState([]);
  const [position, setPosition] = useState([]);
  const [users, setUsers] = useState([]);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [positionFilter, setPositionFilter] = useState("");
  const [departmentFilter, setDepartmentFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);  
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    setUserRole(localStorage.getItem("userRole"));
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://api.rekonsys.tech/auth/users");
      const formattedData = response.data.map((data) => {
        const date = new Date(data.dateOfJoining);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        
        return {
          ...data,
          dateOfJoining: formattedDate,
        };
      });
      setUsers(formattedData);
      setOriginalUsers(formattedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  
  

  useEffect(() => {
    filterUsers();
  }, [startDate, endDate, roleFilter, positionFilter, departmentFilter]);

  const filterUsers = () => {
    const filteredUsers = originalUsers.filter((user) => {
      const userJoinDate = new Date(user.dateOfJoining);
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      // Check if the user's role matches the selected role filter
      const roleMatch = !roleFilter || user.role === roleFilter;

      // Check if the user's position matches the selected position filter
      const positionMatch = !positionFilter || user.position === positionFilter;

      // Check if the user's department matches the selected department filter
      const departmentMatch =
        !departmentFilter || user.department === departmentFilter;

      // Check if startDate and endDate are selected
      if (startDate && endDate) {
        // If both startDate and endDate are selected, use them as range filter
        const dateMatch =
          userJoinDate >= startDateObj && userJoinDate <= endDateObj;
        return roleMatch && positionMatch && departmentMatch && dateMatch;
      } else if (startDate) {
        // If only startDate is selected, filter users whose join date matches startDate
        const dateMatch = userJoinDate.getTime() === startDateObj.getTime(); // Check for exact match
        return roleMatch && positionMatch && departmentMatch && dateMatch;
      } else {
        // If neither startDate nor endDate is selected, return all users
        return roleMatch && positionMatch && departmentMatch;
      }
    });

    setUsers(filteredUsers);
  };

  const clearFilters = () => {
    setStartDate("");
    setEndDate("");
    setRoleFilter("");
    setPositionFilter("");
    setDepartmentFilter("");
    setUsers(originalUsers);
  };

  const downloadCsv = () => {
    const selectedColumns = [
      "firstName",
      "lastName",
      "employeeCode",
      "role",
      "position",
      "department",
      "dateOfJoining",
    ];

    const selectedUserData = users.map((user) => {
      const selectedUser = {};
      selectedColumns.forEach((column) => {
        selectedUser[column] = user[column];
      });
      return selectedUser;
    });

    const ws = XLSX.utils.json_to_sheet(selectedUserData, {
      header: selectedColumns,
    });
    const csv = XLSX.utils.sheet_to_csv(ws);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "Filtered_Reports.csv"); // Change the file name if needed
  };
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get("https://api.rekonsys.tech/role-list");
        const activeRoles = response.data.filter(
          (role) => role.status === "active"
        );
        setRoles(activeRoles); // Update roles state with only active roles
      } catch (error) {
        console.error("Error fetching roles:", error);
        // Handle error if any
      }
    };

    fetchRoles(); // Call the fetchRoles function
  }, []);
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(
          "https://api.rekonsys.tech/department-list"
        );
        const activeDepartments = response.data.filter(
          (department) => department.status === "active"
        );
        setDepartment(activeDepartments); // Update departments state with only active departments
      } catch (error) {
        console.error("Error fetching departments:", error);
        // Handle error if any
      }
    };

    fetchDepartments(); // Call the fetchDepartments function
  }, []);

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await axios.get("https://api.rekonsys.tech/position-list");
        const activePositions = response.data.filter(
          (position) => position.status === "active"
        );
        setPosition(activePositions); // Update positions state with only active positions
      } catch (error) {
        console.error("Error fetching positions:", error);
        // Handle error if any
      }
    };

    fetchPositions(); // Call the fetchPositions function
  }, []);
  const columns = [
    { Header: "Sno.", accessor: "rowNumber", Cell: ({ row }) => row.index + 1 },
    { Header: "First Name", accessor: "firstName" },
    { Header: "Last Name", accessor: "lastName" },
    { Header: "Employee Code", accessor: "employeeCode" },
    { Header: "Role", accessor: "role" },
    { Header: "Position", accessor: "position" },
    { Header: "Department", accessor: "department" },
    { Header: "Date Of Joining", accessor: "dateOfJoining" },
  ];

  return (
    <div>
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          {userRole == "admin" && 
          <>
          <BreadCrumbs
            paths={[
              { url: "/admin-dashboard", label: "Home" },
              { url: "/reports", label: "Reports" },
              { url: "", label: "Employee Reports" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/admin-dashboard" },
              { text: "Employees", path: "/employee-list" },
              { text: "Attendance", path: "/attendance-list" },
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Timesheets", path: "/timesheet-list" },
              { text: "Leaves", path: "/leaveslist" },
              { text: "Projects", path: "/projects-list" },
              { text: "Categories", path: "/categories-list" },
              { text: "Roles", path: "/role" },
              { text: "Departments", path: "/department" },
              { text: "Positions", path: "/position" },
              { text: "Reports", path: "/reports", active: true },
            ]}
          /></>}
          {userRole == "hr" && 
          <><BreadCrumbs
            paths={[
              { url: "/hr-dashboard", label: "Home" },
              { url: "/reports", label: "Reports" },
              { url: "", label: "Employee Reports" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/hr-dashboard" },
              { text: "Employees", path: "/employee-list"},
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Reports", path: "/reports", active: true },
            ]}
          /></>}
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <nav
            aria-label="Breadcrumb"
            className="flex justify-center bg-white border-gray-300 border-b-2 pb-2"
          >
            <ol className="flex rounded-lg h-10 md:h-14 border border-gray-200 text-gray-600 text-lg">
              <li className="flex items-center border-r border-gray-200">
                <Link
                  to="/reports"
                  className="flex items-center h-full rounded-l-lg bg-[#f1bd06] px-2 md:px-6 text-xs font-medium transition hover:text-gray-900"
                >
                  <span className="mx-1 text-[10px] md:text-[14px] font-medium text-center">
                    Employee Reports
                  </span>
                </Link>
                {/* <div className="h-full border-r border-gray-200"></div> */}
              </li>
              <li className="flex items-center border-r border-gray-200">
                <Link
                  to="/leave-reports"
                  className="flex items-center h-full bg-white px-2 md:px-6 text-xs font-medium transition hover:text-gray-900"
                >
                  <span className="mx-1 text-[10px] md:text-[14px] font-medium text-center">
                    Leave Reports
                  </span>
                </Link>
                {/* <div className="h-full border-r border-gray-200"></div> */}
              </li>
              <li className="flex items-center border-r border-gray-200">
                <Link
                  to="/payroll-reports"
                  className="flex items-center h-full bg-white px-2 md:px-6 text-xs font-medium transition hover:text-gray-900"
                >
                  <span className="mx-1 text-[10px] md:text-[14px] font-medium text-center">
                    Payroll Reports
                  </span>
                </Link>
                {/* <div className="h-full border-r border-gray-200"></div> */}
              </li>
              <li className="flex items-center">
                <Link
                  to="/contact-reports"
                  className="flex items-center h-full bg-white px-2 md:px-6 text-xs font-medium transition hover:text-gray-900"
                >
                  <span className="mx-1 text-[10px] md:text-[14px] font-medium text-center">
                    Contact Reports
                  </span>
                </Link>
                {/* <div className="h-full border-r border-gray-200"></div> */}
              </li>
            </ol>
          </nav>
          <div className="my-4">
            <div className="w-full flex flex-col md:flex-row gap-5">
              <div className="w-full flex flex-col">
                <label htmlFor="roleFilter" className="">
                  Role:
                </label>
                <select
                  id="roleFilter"
                  value={roleFilter}
                  onChange={(e) => setRoleFilter(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md mr-2"
                >
                  <option value="">All</option>
                  {roles.map((role) => (
                    <option key={role._id} value={role.name}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full flex flex-col">
                <label htmlFor="positionFilter" className="mr-2">
                  Position:
                </label>
                <select
                  id="positionFilter"
                  value={positionFilter}
                  onChange={(e) => setPositionFilter(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md mr-2"
                >
                  <option value="">All</option>
                  {position.map((position) => (
                    <option key={position._id} value={position.name}>
                      {position.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full flex flex-col">
                <label htmlFor="departmentFilter" className="mr-2">
                  Department:
                </label>
                <select
                  id="departmentFilter"
                  value={departmentFilter}
                  onChange={(e) => setDepartmentFilter(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md mr-2"
                >
                  <option value="">All</option>
                  {department.map((department) => (
                    <option key={department._id} value={department.name}>
                      {department.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-5 mt-2">
              <div className="w-full flex flex-col">
                <label htmlFor="startDate" className="mr-2">
                  Start Date:
                </label>
                <input
                  id="startDate"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md mr-2"
                  placeholder="Start Date"
                />
              </div>
              <div className="w-full flex flex-col">
                <label htmlFor="endDate" className="mr-2">
                  End Date:
                </label>
                <input
                  id="endDate"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  min={startDate} // Set min attribute to the selected start date
                  disabled={!startDate} // Disable end date if start date is not selected
                  className="border border-gray-300 p-2 rounded-md mr-2"
                  placeholder="End Date"
                />
              </div>
              <div className="w-full flex md:mt-6">
                <button
                  onClick={clearFilters}
                  className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="my-2">
            {isLoading ? (
              <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
                Loading Records.....
              </div>
            ) : (
              <div>
                {users && users.length > 0 ? ( // Check if there are filtered users
                  <div>
                    <div>
                      <DataTables columns={columns} data={users} />
                    </div>
                    <div className="my-6">
                      <button
                        onClick={downloadCsv}
                        className="bg-blue-700 hover:bg-[#063081] text-white font-bold py-2 px-4 rounded"
                        disabled={users.length === 0} // Disable the button if there are no filtered users
                      >
                        Download CSV
                      </button>
                    </div>
                  </div>
                ) : (
                  // If there are no filtered users
                  <div className="mt-6 text-center text-gray-600">
                    No data to display
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reports;
