import React, { useState, useEffect } from 'react';
import Header from "../components/Header";
import Features from "../components/Features";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import Benefits from "../components/Benifit";
import Testmonial from "../components/Testmonial";
import { useNavigate } from "react-router-dom";
import AboutUs from '../components/AboutUs';
import Faq from '../components/Faq';
import ContactUs from '../components/ContactUs';


const HomePage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn"); 
    const role = localStorage.getItem("userRole");
    if (loggedIn && role) {
      setIsLoggedIn(true);
      setUserRole(role);
      if (role === 'admin') {
        navigate("/admin-dashboard");
      } else if (role === 'employee') {
        navigate("/employee-dashboard");
      }
    }else if (loggedIn && !role) {      
      localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userRole");
    } else {      
      localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userRole");
    }
  }, []);

  return (
    <div >
        <Header />
        <Hero />
        <Features />
        <AboutUs />
        <Benefits/>
        <Testmonial/>
        <ContactUs />
        <Faq />
        <Footer />


    </div>
  )
}

export default HomePage