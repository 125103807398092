import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import axios from "axios";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver"; // Import FileSaver
import DataTables from "../components/Datatables";
function ContactReports() {
  const [contactData, setContactData] = useState([]);
  const [filteredContactData, setFilteredContactData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    setUserRole(localStorage.getItem("userRole"));
    fetchContactData();
  }, []);

  useEffect(() => {
    filterContactsByDate();
  }, [startDate, endDate]); // Trigger filtering whenever startDate or endDate changes

  const fetchContactData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://api.rekonsys.tech/auth/users");
      const formattedData = response.data.map((data) => {
        const date = new Date(data.dateOfJoining);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        
        return {
          ...data,
          dateOfJoining: formattedDate,
        };
      });
      setContactData(formattedData);
      setFilteredContactData(formattedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching contact data:", error);
    }
  };
  

  const filterContactsByDate = () => {
    let filteredContacts;
    if (startDate && !endDate) {
      const startDateObj = new Date(startDate);
      filteredContacts = contactData.filter((contact) => {
        const contactDate = new Date(contact.dateOfJoining);
        return contactDate.getTime() === startDateObj.getTime();
      });
    } else {
      filteredContacts = contactData.filter((contact) => {
        const contactDate = new Date(contact.dateOfJoining);
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        return contactDate >= startDateObj && contactDate <= endDateObj;
      });
    }
    setFilteredContactData(filteredContacts);
  };

  const clearFilters = () => {
    setStartDate("");
    setEndDate("");
    fetchContactData();
  };

  const downloadExcel = () => {
    const selectedColumns = [
      "firstName",
      "lastName",
      "employeeCode",
      "email",
      "contactNo",
      "dateOfJoining",
    ];

    const filteredDataForDownload = filteredContactData.map((contact) => {
      return selectedColumns.reduce((acc, curr) => {
        acc[curr] = contact[curr];
        return acc;
      }, {});
    });

    const ws = XLSX.utils.json_to_sheet(filteredDataForDownload);
    const csv = XLSX.utils.sheet_to_csv(ws);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "ContactReports.csv");
  };

  const columns = [
    { Header: "Sno.", accessor: "rowNumber", Cell: ({ row }) => row.index + 1 },
    { Header: "First Name", accessor: "firstName" },
    { Header: "Last Name", accessor: "lastName" },
    { Header: "Employee Code", accessor: "employeeCode" },
    { Header: "Email", accessor: "email" },
    { Header: "Contact No", accessor: "contactNo" },
    { Header: "Date Of Joining", accessor: "dateOfJoining" },
  ];

  return (
    <div>
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
        {userRole == "admin" && 
          <><BreadCrumbs
            paths={[
              { url: "/admin-dashboard", label: "Home" },
              { url: "/reports", label: "Reports" },
              { url: "", label: "Contact Reports" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/admin-dashboard" },
              { text: "Employees", path: "/employee-list" },
              { text: "Attendance", path: "/attendance-list" },
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Timesheets", path: "/timesheet-list" },
              { text: "Leaves", path: "/leaveslist" },
              { text: "Projects", path: "/projects-list" },
              { text: "Categories", path: "/categories-list" },
              { text: "Roles", path: "/role" },
              { text: "Departments", path: "/department" },
              { text: "Positions", path: "/position" },
              { text: "Reports", path: "/reports", active: true },
            ]}
          /></>}
          {userRole == "hr" && 
          <><BreadCrumbs
            paths={[
              { url: "/hr-dashboard", label: "Home" },
              { url: "/reports", label: "Reports" },
              { url: "", label: "Contact Reports" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/hr-dashboard" },
              { text: "Employees", path: "/employee-list"},
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Reports", path: "/reports", active: true },
            ]}
          /></>}
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <nav
            aria-label="Breadcrumb"
            className="flex justify-center bg-white border-gray-300 border-b-2 pb-2"
          >
            <ol className="flex rounded-lg h-10 md:h-14 border border-gray-200 text-gray-600 text-lg">
              <li className="flex items-center border-r border-gray-200">
                <Link
                  to="/reports"
                  className="flex items-center h-full rounded-l-lg bg-white px-2 md:px-6 text-xs font-medium transition hover:text-gray-900"
                >
                  <span className="mx-1 text-[10px] md:text-[14px] font-medium text-center">
                    Employee Reports
                  </span>
                </Link>
                {/* <div className="h-full border-r border-gray-200"></div> */}
              </li>
              <li className="flex items-center border-r border-gray-200">
                <Link
                  to="/leave-reports"
                  className="flex items-center h-full bg-white px-2 md:px-6 text-xs font-medium transition hover:text-gray-900"
                >
                  <span className="mx-1 text-[10px] md:text-[14px] font-medium text-center">
                    Leave Reports
                  </span>
                </Link>
                {/* <div className="h-full border-r border-gray-200"></div> */}
              </li>
              <li className="flex items-center border-r border-gray-200">
                <Link
                  to="/payroll-reports"
                  className="flex items-center h-full bg-white px-2 md:px-6 text-xs font-medium transition hover:text-gray-900"
                >
                  <span className="mx-1 text-[10px] md:text-[14px] font-medium text-center">
                    Payroll Reports
                  </span>
                </Link>
                {/* <div className="h-full border-r border-gray-200"></div> */}
              </li>
              <li className="flex items-center">
                <Link
                  to="/contact-reports"
                  className="flex items-center h-full rounded-r-lg bg-[#f1bd06] px-2 md:px-6 text-xs font-medium transition hover:text-gray-900"
                >
                  <span className="mx-1 text-[10px] md:text-[14px] font-medium text-center">
                    Contact Reports
                  </span>
                </Link>
                {/* <div className="h-full border-r border-gray-200"></div> */}
              </li>
            </ol>
          </nav>
          <div className="my-4">
            <div className="w-full flex flex-col md:flex-row gap-5">
              <div className="w-full flex flex-col">
                <label htmlFor="startDate" className="mr-2">
                  Start Date:
                </label>
                <input
                  id="startDate"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md mr-2"
                  placeholder="Start Date"
                />
              </div>
              <div className="w-full flex flex-col">
                <label htmlFor="endDate" className="mr-2">
                  End Date:
                </label>
                <input
                  id="endDate"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  min={startDate} // Set min attribute to the selected start date
                  disabled={!startDate} // Disable end date if start date is not selected
                  className="border border-gray-300 p-2 rounded-md mr-2"
                  placeholder="End Date"
                />
              </div>
              <div className="w-full flex md:mt-6">
                <button
                  onClick={clearFilters}
                  className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="my-2"></div>
          {isLoading ? (
            <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
              Loading Records.....
            </div>
          ) : (
            <div>
              {filteredContactData && filteredContactData.length > 0 ? (
                <div>
                  <div>
                    <DataTables columns={columns} data={filteredContactData} />
                  </div>
                  <div className="my-6">
                    <button
                      onClick={downloadExcel}
                      className="bg-blue-700 hover:bg-[#063081] text-white font-bold py-2 px-4 rounded"
                    >
                      Download CSV
                    </button>
                  </div>
                </div>
              ) : (
                <div className="mt-6 text-center text-gray-600">
                  No data to display
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContactReports;
