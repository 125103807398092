import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import { Link, useNavigate } from "react-router-dom";
import { FaUsers, FaProjectDiagram, FaMegaport, FaCalendarAlt } from "react-icons/fa";
import PieChart from "../components/PieChart";
import axios from "axios";
import TodayUpdates from "../components/Dashboard/TodayUpdates";

const ManagerDashboard = () => {
  const navigate = useNavigate();
  const [numberOfEmployees, setNumberOfEmployees] = useState(0);
  const [numberOfProjects, setNumberOfProjects] = useState(0);
  const [numberOfEvents, setNumberOfEvents] = useState(0);
  const [numberOfDepartments, setNumberOfDepartments] = useState(0);
  const [employeesData, setEmployeesData] = useState([]);
  const [departmentsData, setDepartmentsData] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });
  const todayDate = new Date();

  useEffect(() => {
    const userRole = localStorage.getItem("userRole");
    if (userRole === "admin") {
      navigate("/admin-dashboard");
    } else if (userRole === "employee") {
      navigate("/employee-dashboard");
    } else if (userRole === "manager") {
      navigate("/manager-dashboard");
    } else if (userRole === "hr") {
      navigate("/hr-dashboard");
    }

    fetchEmployees();
    fetchProjects();
    fetchEvents();
    fetchDeparments();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get("https://api.rekonsys.tech/auth/users");
      setNumberOfEmployees(response.data.length);
      setEmployeesData(response.data);

    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const fetchProjects = async () => {
    try {
      const response = await axios.get("https://api.rekonsys.tech/all-projects");
      setNumberOfProjects(response.data.length);

    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const fetchDeparments = async () => {
    try {
      const response = await axios.get("https://api.rekonsys.tech/department-list");
      setNumberOfDepartments(response.data.length);
      setDepartmentsData(response.data);

    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const fetchEvents = async () => {
    try {
      const response = await axios.get("https://api.rekonsys.tech/events");
      setNumberOfEvents(response.data.length);

    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    // Count the number of employees in each department
    const departmentEmployeesCount = {};
    employeesData.forEach((employee) => {
      if (departmentEmployeesCount.hasOwnProperty(employee.department)) {
        departmentEmployeesCount[employee.department]++;
      } else {
        departmentEmployeesCount[employee.department] = 1;
      }
    });

    // Prepare data for the pie chart
    const labels = Object.keys(departmentEmployeesCount);
    const data = Object.values(departmentEmployeesCount);

    // Map department names to their corresponding colors
    const departmentColors = {};
    departmentsData.forEach((department) => {
      departmentColors[department.name] = department.backgroundColor;
    });

    // Set backgroundColor in chartData with colors from categoriesData
    const backgroundColor = labels.map((label) => departmentColors[label]);


    setChartData({
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: backgroundColor,
        },
      ],
    });
  }, [employeesData]);

  return (
    <div>
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          <BreadCrumbs
            paths={[
              { url: "/manager-dashboard", label: "Home" },
              { url: "/manager-dashboard", label: "Dashboard" },
              { url: "", label: "Manager Dashboard" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/manager-dashboard", active: true },
              // { text: "Employees", path: "/employee-list" },
              { text: "Attendance", path: "/attendance-list" },
              // { text: "Calendar", path: "/admin-calendar" },
              { text: "Timesheets", path: "/timesheet-list" },
              { text: "Leaves", path: "/leaveslist" },
              { text: "Projects", path: "/projects-list" },
              // { text: "Categories", path: "/categories-list" },
              // { text: "Roles", path: "/role" },
              // { text: "Departments", path: "/department" },
              // { text: "Positions", path: "/position" },
              // { text: "Reports", path: "/reports" },
            ]}
          />
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div className="flex flex-col gap-5 w-full">
            <div className="flex flex-col md:flex-row gap-5 w-full items-center md:justify-between">
              <div className="flex flex-col sm:flex-row gap-5 w-full items-center justify-center ">
                <div className="bg-white w-full md:w-1/2 p-4 rounded-lg shadow-md flex items-center">
                  <div className="flex items-center justify-center w-12 h-12 bg-blue-500 text-white rounded-full">
                    <FaUsers className="w-6 h-6" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-semibold">Employees</h3>
                    <p className="text-xl font-bold text-gray-800">
                      {numberOfEmployees}
                    </p>
                  </div>
                </div>
                <div className="bg-white w-full md:w-1/2 p-4 rounded-lg shadow-md flex items-center">
                  <div className="flex items-center justify-center w-12 h-12 bg-blue-500 text-white rounded-full">
                    <FaProjectDiagram className="w-6 h-6" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-semibold">Projects</h3>
                    <p className="text-xl font-bold text-gray-800">
                      {numberOfProjects}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-5 w-full items-center justify-center ">
                <div className="bg-white w-full md:w-1/2 p-4 rounded-lg shadow-md flex items-center">
                  <div className="flex items-center justify-center w-12 h-12 bg-blue-500 text-white rounded-full">
                    <FaMegaport className="w-6 h-6" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-semibold">Departments</h3>
                    <p className="text-xl font-bold text-gray-800">
                      {numberOfDepartments}
                    </p>
                  </div>
                </div>
                <div className="bg-white w-full md:w-1/2 p-4 rounded-lg shadow-md flex items-center">
                  <div className="flex items-center justify-center w-12 h-12 bg-blue-500 text-white rounded-full">
                    <FaCalendarAlt className="w-6 h-6" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-semibold">Events</h3>
                    <p className="text-xl font-bold text-gray-800">
                      {numberOfEvents}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-5">
              <div className="bg-white w-full md:w-1/2 p-4 rounded-lg shadow-md flex gap-5 flex-col items-center">
                <h3 className="text-xl font-semibold">Total Employees</h3>
                <div className="w-fullh-80 flex flex-col justify-center overflow-y-auto">
                  <PieChart data={chartData} />
                </div>
              </div>
              <TodayUpdates />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagerDashboard;
