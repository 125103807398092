import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const AttendanceForm = () => {
  const { id } = useParams();
  let deviceToken;
  const [employeeCode, setEmployeeCode] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [employeeDeviceToken, setEmployeeDeviceToken] = useState("");
  const [welcome, setWelcome] = useState(false);
  const [error, setError] = useState(false);
  const [deviceNotFound, setDeviceNotFound] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [attendanceData, setAttendanceData] = useState({
    employeeCode: "",
    employeeName: "",
  });
  useEffect(() => {
    getEmployeeById();
    setWelcome(true);
  }, [id]);
  const getEmployeeById = async () => {
    try {
      const res = await axios.get(`https://api.rekonsys.tech/auth/user/${id}`);
      setEmployeeName(`${res.data.firstName} ${res.data.lastName}`);
      setEmployeeCode(res.data.employeeCode);
      setEmployeeDeviceToken(res.data.deviceToken);
      setAttendanceData((prevState) => ({
        ...prevState,
        employeeCode: res.data.employeeCode,
        employeeName: `${res.data.firstName} ${res.data.lastName}`,
      }));
    } catch (error) {
      console.error("Error fetching user", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    employeeDeviceToken == "newuser"
      ? (deviceToken = "newuser")
      : (deviceToken = localStorage.getItem("deviceToken"));

    try {

      const res = await axios.post(
        "https://api.rekonsys.tech/attendance",
        {attendanceData, deviceToken}
      );
      //console.log(res);
      if (res.data.status === "submitted") {
        setWelcome(false);
        setSuccess(true);
      } else if (res.data.status === "recorded") {
        setWelcome(false);
        setError(true);
      }else if (res.data.status === "newdevice") {
        setWelcome(false);
        setDeviceNotFound(true);
      }

      if (res.data.deviceToken) {
        localStorage.setItem("deviceToken", res.data.deviceToken);
        //console.log(res.data.deviceToken);
      }
    } catch (error) {
      setWelcome(false);
      setDeviceNotFound(true);
      console.error("Error submitting attendance:", error.message);
    }
  };

  return (
    <div className="flex w-screen h-screen justify-center items-center">
      {welcome && (
        <section className="rounded-3xl shadow-2xl">
          <div className="p-8 text-center sm:p-12">
            <p className="text-md font-bold uppercase tracking-widest text-teal-500">
              Fill Daily Attendance
            </p>

            <h2 className="mt-6 text-3xl font-bold">Welcome {employeeName}</h2>

            <button
              onClick={handleSubmit}
              className="mt-8 inline-block w-full rounded-full bg-teal-600 py-4 text-sm font-bold text-white shadow-xl"
            >
              Submit Attendance
            </button>
          </div>
        </section>
      )}
      {success && (
        <section className="rounded-3xl shadow-2xl">
          <div className="p-8 text-center sm:p-12">
            <p className="text-md font-bold uppercase tracking-widest text-teal-500">
              Your Daily Attendance has be recorded
            </p>

            <h2 className="mt-6 text-3xl font-bold">
              Thanks for submitting attendance!
            </h2>

            <Link
              className="mt-8 inline-block w-full rounded-full bg-teal-600 py-4 text-sm font-bold text-white shadow-xl"
              to="/"
            >
              Return Home
            </Link>
          </div>
        </section>
      )}
      {error && (
        <section className="rounded-3xl shadow-2xl w-96">
          <div className="p-8 text-center sm:p-12">
            <h2 className="mt-6 text-2xl md:text-3xl font-semibold">
              Your Daily Attendance has already been recorded for today!
            </h2>

            <Link
              className="mt-8 inline-block w-full rounded-full bg-red-400 py-4 text-sm font-bold text-white shadow-xl"
              to="/"
            >
              Return Home
            </Link>
          </div>
        </section>
      )}
      {deviceNotFound && (
        <section className="rounded-3xl shadow-2xl w-96">
          <div className="p-8 text-center sm:p-12">
            <h2 className="mt-6 text-2xl md:text-3xl font-semibold">
              Attendance attempt from new device detected, please fill
              attendance from your original device!
            </h2>

            <Link
              className="mt-8 inline-block w-full rounded-full bg-red-400 py-4 text-sm font-bold text-white shadow-xl"
              to="/"
            >
              Return Home
            </Link>
          </div>
        </section>
      )}
    </div>
  );
};

export default AttendanceForm;
