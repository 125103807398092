import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const PieChart = ( {data} ) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const chartData = data

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const myChartRef = chartRef.current.getContext("2d");

    chartInstance.current = new Chart(myChartRef, {
      type: "pie",
      data: chartData,
    });
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [chartData]);

  return (
    <div>
      <canvas
        ref={chartRef}
      ></canvas>
    </div>
  );
};

export default PieChart;
