import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import DataTables from "../components/Datatables";
import { FaRegEdit } from "react-icons/fa";import { FaTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";

const AttendanceList = () => {
  const navigate = useNavigate();
  const [attendance, setAttendance] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userRole, setUserRole] = useState('');
  useEffect(() => {
    setUserRole(localStorage.getItem("userRole"));
    fetchAttendance();
  }, []);
  const errorClose = () => {
    setError("");
  };

  const fetchAttendance = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://api.rekonsys.tech/attendance");
      const formattedData = response.data.map((data)=> ({...data, date: data.date.split("T")[0].slice(0, 10),}))
      setAttendance(formattedData.reverse());
      setIsLoading(false);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const columns = [
    { Header: "Sno.", accessor: "rowNumber", Cell: ({ row }) => row.index + 1 },
    { Header: "Employee Name", accessor: "employeeName" },
    { Header: "Date", accessor: "date" },
    { Header: "Employee Code", accessor: "employeeCode" },
    { Header: "Time", accessor: "time" },
  ];

  return (
    <div>
      {error && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-danger"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-red-100  rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg ">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="ms-3 text-md font-normal">{error}</div>
            <button
              type="button"
              onClick={errorClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 "
              data-dismiss-target="#toast-danger"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          {userRole == "admin" &&
          <>
          <BreadCrumbs
           paths={[
            { url: "/admin-dashboard", label: "Home" },
            { url: "/attendance-list", label: "Attendance" },
            { url: "", label: "Attendance" },
          ]}
          />

          <Sidebar
             menuItems={[
              { text: "Home", path: "/admin-dashboard" },
              { text: "Employees", path: "/employee-list" },
              { text: "Attendance", path: "/attendance-list", active: true },
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Timesheets", path: "/timesheet-list" },
              { text: "Leaves", path: "/leaveslist" },
              { text: "Projects", path: "/projects-list" },
              { text: "Categories", path: "/categories-list"},
              { text: "Roles", path: "/role" },
              { text: "Departments", path: "/department" },
              { text: "Positions", path: "/position" },
              { text: "Reports", path: "/reports" },
            ]}
          />
          </>}
          {userRole == "manager" &&
          <>
          <BreadCrumbs
            paths={[
              { url: "/manager-dashboard", label: "Home" },
              { url: "/attendance-list", label: "Attendance" },
              { url: "", label: "Attendance" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/manager-dashboard"},
              { text: "Attendance", path: "/attendance-list", active: true },
              { text: "Timesheets", path: "/timesheet-list" },
              { text: "Leaves", path: "/leaveslist" },
              { text: "Projects", path: "/projects-list" }
            ]}
          /> </>}
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div>
            <header className="bg-white border-gray-300 border-b-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center mb-2">
                <h1 className="text-xl md:text-3xl font-semibold">
                  Attendance List
                </h1>
              </div>
            </header>
          </div>
          {/* Datatables */}
          <div>
            {isLoading ? (
              <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
                Loading Records.....
              </div>
            ) : (
              <div className="overflow-x-auto">
                {attendance && attendance.length > 0 ? (
                  <DataTables columns={columns} data={attendance} />
                ) : (
                  <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
                    No records found
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceList;
