import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import { Link } from "react-router-dom";
import axios from "axios";
import DataTables from "../components/Datatables";
import { FaEye } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import {
  FaUser,
  FaBriefcase,
  FaGraduationCap,
  FaMoneyCheckAlt,
  FaElementor,
  FaRegAddressCard,
} from "react-icons/fa";
// Core viewer
import { Viewer, Worker } from "@react-pdf-viewer/core";
import packageJson from "../../package.json"


// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const EmployeeDetails = () => {
  const pdfjsVersion = packageJson.dependencies['pdfjs-dist'].replace("^", "");
  const workerUrl = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`
  const { id } = useParams();
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    password: "",
    gender: "",
    dob: "",
    contactNo: "",
    bloodGroup: "",
    emergencyContactNo: "",
    hobbies: "",
    permanentAddress: "",
    presentAddress: "",
    dateOfJoining: "",
    employeeCode: "",
    employeeDocs: "",
    deviceToken: "",
    role: "",
    position: "",
    department: "",
    educationData: [{}],
    workExperienceData: [{}],
    bankAccountHolderName: "",
    bankAccountName: "",
    bankAccountNumber: "",
    IFSCCode: "",
    PANcardNo: "",
    UAN: "",
    permanentPresentSame: false,
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [activeTab, setActiveTab] = useState("personal");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePaySlip = () => {
    navigate(`/pay-slip/${id}`);
  };

  const [userRole, setUserRole] = useState('');
  useEffect(() => {
    setUserRole(localStorage.getItem("userRole"));
    getEmployeeById();
  }, [id]);

  const getEmployeeById = async () => {
    try {
      const response = await axios.get(
        `https://api.rekonsys.tech/auth/user/${id}`
      );
      setFormData(response.data);
      // console.log(response.data);
      setFormData((prevData) => ({
        ...prevData,
        dob: new Date(response.data.dob).toISOString().split("T")[0],
        dateOfJoining: new Date(response.data.dateOfJoining)
          .toISOString()
          .split("T")[0],
        educationData: response.data.educationData,
        workExperienceData: response.data.workExperienceData,
      }));
    } catch (error) {
      console.error("Error fetching user", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
        {userRole == "admin" &&
          <>
          <BreadCrumbs
            paths={[
              { url: "/admin-dashboard", label: "Home" },
              { url: "/employee-list", label: "Employees" },
              { url: "", label: "Employees" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/admin-dashboard" },
              { text: "Employees", path: "/employee-list" , active: true},
              { text: "Attendance", path: "/attendance-list" },
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Timesheets", path: "/timesheet-list" },
              { text: "Leaves", path: "/leaveslist" },
              { text: "Projects", path: "/projects-list" },
              { text: "Categories", path: "/categories-list"},
              { text: "Roles", path: "/role" },
              { text: "Departments", path: "/department" },
              { text: "Positions", path: "/position" },
              { text: "Reports", path: "/reports" },
            ]}
          />
          </>}
          {userRole == "hr" &&
          <>
          <BreadCrumbs
            paths={[
              { url: "/hr-dashboard", label: "Home" },
              { url: "/employee-list", label: "Employees" },
              { url: "", label: "Employees" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/hr-dashboard" },
              { text: "Employees", path: "/employee-list" , active: true},
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Reports", path: "/reports" },
            ]}
          /> </>}
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div>
            <header className="bg-white border-gray-300 border-b-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center mb-2">
                <h1 className="text-xl md:text-3xl font-semibold">
                  Employee - {formData.firstName + " " + formData.lastName}
                </h1>

                <button
                  className="block rounded-md bg-blue-700 px-3 md:px-5 py-2 text-sm font-medium text-white transition hover:bg-[#063081]"
                  onClick={handlePaySlip}
                >
                  Pay Slip
                </button>
              </div>
            </header>
          </div>
          <div className="container mx-auto px-4 py-8">
            {/* Tab Navigation */}
            <div className="w-full flex flex-wrap justify-center items-center mb-4">
              <button
                className={`mb-2 md:mb-0 mr-4 p-2 w-32 flex justify-center items-center rounded-lg shadow-lg ${
                  activeTab === "personal"
                    ? "bg-teal-400 text-white  font-semibold"
                    : "bg-teal-50"
                }`}
                onClick={() => handleTabClick("personal")}
              >
                <FaUser
                  className={`mr-2 h-5 w-5 text-blue-500 ${
                    activeTab === "personal" ? "text-white" : "text-blue-500"
                  }`}
                />
                Personal
              </button>
              <button
                className={`mb-2 md:mb-0 mr-4 p-2 w-32 flex justify-center items-center rounded-lg shadow-lg ${
                  activeTab === "employment"
                    ? "bg-teal-400 text-white  font-semibold"
                    : "bg-teal-50"
                }`}
                onClick={() => handleTabClick("employment")}
              >
                <FaBriefcase
                  className={`mr-2 h-5 w-5 text-blue-500 ${
                    activeTab === "employment" ? "text-white" : "text-blue-500"
                  }`}
                />
                Employment
              </button>
              <button
                className={`mb-2 md:mb-0 mr-4 p-2 w-32 flex justify-center items-center rounded-lg shadow-lg ${
                  activeTab === "education"
                    ? "bg-teal-400 text-white  font-semibold"
                    : "bg-teal-50"
                }`}
                onClick={() => handleTabClick("education")}
              >
                <FaGraduationCap
                  className={`mr-2 h-5 w-5 text-blue-500 ${
                    activeTab === "education" ? "text-white" : "text-blue-500"
                  }`}
                />
                Education
              </button>
              <button
                className={`mb-2 md:mb-0 mr-4 p-2 w-32 flex justify-center items-center rounded-lg shadow-lg ${
                  activeTab === "bank"
                    ? "bg-teal-400 text-white  font-semibold"
                    : "bg-teal-50"
                }`}
                onClick={() => handleTabClick("bank")}
              >
                <FaMoneyCheckAlt
                  className={`mr-2 h-5 w-5 text-blue-500 ${
                    activeTab === "bank" ? "text-white" : "text-blue-500"
                  }`}
                />
                Bank
              </button>
              <button
                className={`mb-2 md:mb-0 mr-4 p-2 w-32 flex justify-center items-center rounded-lg shadow-lg ${
                  activeTab === "docs"
                    ? "bg-teal-400 text-white  font-semibold"
                    : "bg-teal-50"
                }`}
                onClick={() => handleTabClick("docs")}
              >
                <FaElementor
                  className={`mr-2 h-5 w-5 text-blue-500 ${
                    activeTab === "docs" ? "text-white" : "text-blue-500"
                  }`}
                />
                Docs
              </button>
            </div>
            {/* Tab Content */}
            <div>
              {activeTab === "personal" && (
                <div className="bg-gray-100 rounded-lg p-6 shadow-md">
                <h2 className="text-xl font-semibold mb-4">Personal Information</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  {/* Name */}
                  <div>
                    <h3 className="text-lg font-semibold mb-2">Name</h3>
                    <p className="text-gray-700">
                      <span className="font-semibold">First Name:</span> {formData.firstName}
                    </p>
                    <p className="text-gray-700">
                      <span className="font-semibold">Middle Name:</span> {formData.middleName}
                    </p>
                    <p className="text-gray-700">
                      <span className="font-semibold">Last Name:</span> {formData.lastName}
                    </p>
                  </div>
                  
                  {/* Contact Info */}
                  <div>
                    <h3 className="text-lg font-semibold mb-2">Contact Information</h3>
                    <p className="text-gray-700">
                      <span className="font-semibold">Email:</span> {formData.email}
                    </p>
                    <p className="text-gray-700">
                      <span className="font-semibold">Contact Number:</span> {formData.contactNo}
                    </p>
                    <p className="text-gray-700">
                      <span className="font-semibold">Emergency Contact:</span> {formData.emergencyContactNo}
                    </p>
                  </div>
                  
                  {/* Personal Details */}
                  <div>
                    <h3 className="text-lg font-semibold mb-2">Personal Details</h3>
                    <p className="text-gray-700">
                      <span className="font-semibold">Gender:</span> {formData.gender}
                    </p>
                    <p className="text-gray-700">
                      <span className="font-semibold">Date of Birth:</span> {formData.dob}
                    </p>
                    <p className="text-gray-700">
                      <span className="font-semibold">Blood Group:</span> {formData.bloodGroup}
                    </p>
                    <p className="text-gray-700">
                      <span className="font-semibold">Hobbies:</span> {formData.hobbies}
                    </p>
                  </div>
                  
                  {/* Addresses */}
                  <div>
                    <h3 className="text-lg font-semibold mb-2">Addresses</h3>
                    <p className="text-gray-700">
                      <span className="font-semibold">Permanent Address:</span> {formData.permanentAddress}
                    </p>
                    <p className="text-gray-700">
                      <span className="font-semibold">Present Address:</span> {formData.presentAddress}
                    </p>
                  </div>
                </div>
              </div>
              
              )}
              {activeTab === "employment" && (
               <div className="bg-gray-100 rounded-lg p-6 shadow-md">
               <h2 className="text-xl font-semibold mb-4">Employment Information</h2>
               <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                 {/* Date of Joining */}
                 <div>
                   <h3 className="text-lg font-semibold mb-2">Date of Joining</h3>
                   <p className="text-gray-700">{formData.dateOfJoining}</p>
                 </div>
                 
                 {/* Employee Code */}
                 <div>
                   <h3 className="text-lg font-semibold mb-2">Employee Code</h3>
                   <p className="text-gray-700">{formData.employeeCode}</p>
                 </div>
                 
                 {/* Device Token */}
                 <div>
                   <h3 className="text-lg font-semibold mb-2">Device Token</h3>
                   <p className="text-gray-700">{formData.deviceToken}</p>
                 </div>
                 
                 {/* Role */}
                 <div>
                   <h3 className="text-lg font-semibold mb-2">Role</h3>
                   <p className="text-gray-700">{formData.role}</p>
                 </div>
                 
                 {/* Position */}
                 <div>
                   <h3 className="text-lg font-semibold mb-2">Position</h3>
                   <p className="text-gray-700">{formData.position}</p>
                 </div>
                 
                 {/* Department */}
                 <div>
                   <h3 className="text-lg font-semibold mb-2">Department</h3>
                   <p className="text-gray-700">{formData.department}</p>
                 </div>
               </div>
             </div>
             
              )}
              {activeTab === "education" && (
               <div className="bg-gray-100 rounded-lg p-6 shadow-md">
               <h2 className="text-xl font-semibold mb-4">Education and Work Experience</h2>
               <div className="space-y-6">
                 {/* Education data */}
                 {formData.educationData.map((education, index) => (
                   <div key={index}>
                     <h3 className="text-lg font-semibold mb-2">Education {index + 1}:</h3>
                     <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                       {/* Degree */}
                       <div>
                         <h4 className="text-lg font-semibold mb-2">Degree</h4>
                         <p className="text-gray-700">{education.degree}</p>
                       </div>
                       {/* School University */}
                       <div>
                         <h4 className="text-lg font-semibold mb-2">School University</h4>
                         <p className="text-gray-700">{education.schoolUniversity}</p>
                       </div>
                       {/* Passing Of Year */}
                       <div>
                         <h4 className="text-lg font-semibold mb-2">Passing Of Year</h4>
                         <p className="text-gray-700">{education.passingOfYear}</p>
                       </div>
                       {/* CGPA */}
                       <div>
                         <h4 className="text-lg font-semibold mb-2">CGPA</h4>
                         <p className="text-gray-700">{education.cgpa}</p>
                       </div>
                     </div>
                   </div>
                 ))}
                 {/* Work experience data */}
                 {formData.workExperienceData.map((experience, index) => (
                   <div key={index}>
                     <h3 className="text-lg font-semibold mb-2">Work Experience {index + 1}:</h3>
                     <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                       {/* Company Name */}
                       <div>
                         <h4 className="text-md font-medium">Company Name</h4>
                         <p className="text-gray-700">{experience.companyName}</p>
                       </div>
                       {/* Designation */}
                       <div>
                         <h4 className="text-md font-medium">Designation</h4>
                         <p className="text-gray-700">{experience.designation}</p>
                       </div>
                       {/* Start Date */}
                       <div>
                         <h4 className="text-md font-medium">Start Date</h4>
                         <p className="text-gray-700">{experience.startDate}</p>
                       </div>
                       {/* End Date */}
                       <div>
                         <h4 className="text-md font-medium">End Date</h4>
                         <p className="text-gray-700">{experience.endDate}</p>
                       </div>
                     </div>
                   </div>
                 ))}
               </div>
             </div>
             
              )}
              {activeTab === "bank" && (
               <div className="bg-gray-100 rounded-lg p-6 shadow-md">
               <h2 className="text-xl font-semibold mb-4">Bank Information</h2>
               <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                 <div>
                   <h3 className="text-lg font-semibold mb-2">Bank Account Holder Name</h3>
                   <p className="text-gray-700">{formData.bankAccountHolderName}</p>
                 </div>
                 <div>
                   <h3 className="text-lg font-semibold mb-2">Bank Account Name</h3>
                   <p className="text-gray-700">{formData.bankAccountName}</p>
                 </div>
                 <div>
                   <h3 className="text-lg font-semibold mb-2">Bank Account Number</h3>
                   <p className="text-gray-700">{formData.bankAccountNumber}</p>
                 </div>
                 <div>
                   <h3 className="text-lg font-semibold mb-2">IFSC Code</h3>
                   <p className="text-gray-700">{formData.IFSCCode}</p>
                 </div>
                 <div>
                   <h3 className="text-lg font-semibold mb-2">PAN Card Number</h3>
                   <p className="text-gray-700">{formData.PANcardNo}</p>
                 </div>
                 <div>
                   <h3 className="text-lg font-semibold mb-2">UAN</h3>
                   <p className="text-gray-700">{formData.UAN}</p>
                 </div>
                 <div>
                   <h3 className="text-lg font-semibold mb-2">Permanent and Present Address Same</h3>
                   <p className="text-gray-700">{formData.permanentPresentSame ? "Yes" : "No"}</p>
                 </div>
               </div>
             </div>
             
              )}
              {activeTab === "docs" && (
                <div>
                  {/* docs Information */}
                  <h2 className="text-xl font-semibold mb-2">
                    Docs Information
                  </h2>
                  {formData.employeeDocs ? 
                  <div className="flex flex-col space-y-4">
                    <Worker workerUrl={workerUrl}>
                      <Viewer
                        fileUrl={formData.employeeDocs}
                        onError={(error) => console.error('Error loading PDF:', error)}
                        plugins={[
                          // Register plugins
                          defaultLayoutPluginInstance,
                        ]}
                      />
                    </Worker>
                  </div> : 
                  <div className="flex flex-col space-y-4">
                    No Docs to view
                  </div>}
                  </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;
