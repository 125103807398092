import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import { Link } from "react-router-dom";
import axios from "axios";
import DataTables from "../components/Datatables";
// import { FaRegEdit } from "react-icons/fa";// import { FaTrashAlt } from "react-icons/fa";
// import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";

const EmployeeLeavesList = () => {
  const id = localStorage.getItem("userID");

  const [leaves, setLeaves] = useState([]);
  const [employeeCode, setEmployeeCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // const navigate = useNavigate();

  useEffect(() => {
    getEmployeeById();
  }, []);
  useEffect(() => {
    fetchEmployeeLeaves();
    // console.log(leaves);
  }, [employeeCode]);

  const getEmployeeById = async () => {
    try {
      const res = await axios.get(`https://api.rekonsys.tech/auth/user/${id}`);
      setEmployeeCode(res.data.employeeCode);
    } catch (error) {
      console.error("Error fetching user", error);
    }
  };
  const fetchEmployeeLeaves = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://api.rekonsys.tech/employee-leaves?employeeCode=${employeeCode}`
      );
      const formattedData = response.data.map((data) => ({
        ...data,
        fromDate: data.fromDate.split("T")[0].slice(0, 10),
        toDate: data.toDate.split("T")[0].slice(0, 10),
      }));
      setLeaves(formattedData.reverse());
    } catch (error) {
      console.error("Error fetching leaves:", error);
    }
    setIsLoading(false);
  };

  const errorClose = () => {
    setError("");
  };

  const columns = [
    { Header: "Sno.", accessor: "rowNumber", Cell: ({ row }) => row.index + 1 },
    { Header: "Employee Code", accessor: "employeeCode" },
    { Header: "Employee Email", accessor: "employeeEmail" },
    { Header: "Leave Type", accessor: "leavetype" },
    { Header: "From Date", accessor: "fromDate" },
    { Header: "To Date", accessor: "toDate" },
    { Header: "No Of Leaves", accessor: "noOfLeaves" },
    { Header: "Day", accessor: "day" },
    { Header: "Status", accessor: "status" },
  ];

  return (
    <div>
      <Navbar />
      {error && (
        <div className="fixed flex justify-center top-0 left-1/2 transform -translate-x-1/2 w-2/4 z-50 p-2 mt-4">
          <div
            id="toast-danger"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-red-100  rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg ">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="ms-3 text-md font-normal">{error}</div>
            <button
              type="button"
              onClick={errorClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 "
              data-dismiss-target="#toast-danger"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          <BreadCrumbs
            paths={[
              { url: "/employee-dashboard", label: "Home" },
              { url: "/employee-leaves-list", label: "Leaves" },
              { url: "", label: "Leaves" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/employee-dashboard" },
              { text: "Attendance", path: "/employee-attendance-list" },
              { text: "Calendar", path: "/employee-calendar" },
              { text: "Timesheets", path: "/employee-timesheet-list" },
              { text: "Leaves", path: "/employee-leaves-list", active: true },
            ]}
          />
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div>
            <header className="bg-white border-gray-300 border-b-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center mb-2">
                <h1 className="text-xl md:text-3xl font-semibold">
                  Leaves List
                </h1>

                <Link
                  className="block rounded-md bg-blue-700 hover:bg-[#063081] px-5 py-2.5 text-sm font-medium text-white transition"
                  to="/apply-leave"
                >
                  Apply Leave
                </Link>
              </div>
            </header>
          </div>
          {/* Datatables */}
          <div>
            {isLoading ? (
              <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
                Loading Records.....
              </div>
            ) : (
              <div className="overflow-x-auto">
                {leaves && leaves.length > 0 ? (
                  <DataTables columns={columns} data={leaves} />
                ) : (
                  <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
                    No records found
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeLeavesList;
