import React, { useRef, useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import numberToWords from "number-to-words";

const Payslipgen = () => {
  const location = useLocation();
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    setUserRole(localStorage.getItem("userRole"));
  }, []);

  const { salaryDetails } = location.state || {};

  // console.log(salaryDetails);

  const successClose = () => {
    setSuccessMessage("");
  };

  const errorClose = () => {
    setError("");
  };
  const payslipRef = useRef();
  const netSalaryInWords = numberToWords.toWords(salaryDetails.netSalary);
  const currentYear = new Date().getFullYear();

  const handlePrint = useReactToPrint({
    content: () => payslipRef.current,
  });

  function formatCurrency(number) {
    let numStr = number.toString();
    let len = numStr.length;
    let formattedNum = '';

    // Handle the last three digits
    if (len > 3) {
        let lastThreeDigits = numStr.slice(-3);
        let restDigits = numStr.slice(0, -3);
        formattedNum = ',' + lastThreeDigits;

        // Handle the remaining digits in groups of two
        while (restDigits.length > 2) {
            formattedNum = ',' + restDigits.slice(-2) + formattedNum;
            restDigits = restDigits.slice(0, -2);
        }

        formattedNum = restDigits + formattedNum;
    } else {
        // If the number has 3 or fewer digits, return it as is
        formattedNum = numStr;
    }

    return formattedNum;
}

  //   html2canvas(payslipRef.current).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF();
  //     const imgProps = pdf.getImageProperties(imgData);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

  //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //     pdf.save('payslip.pdf');
  //   });
  // };
  const handleDownloadPDF = () => {
    const employee = salaryDetails.firstName + salaryDetails.lastName;
    const employeeName = employee.replace(/\s+/g, "_");
    const month = salaryDetails.monthPaySlip;
    const year = salaryDetails.yearPaySlip;

    const filename = `${employeeName}_${month}_${year}_payslip.pdf`;
    html2canvas(payslipRef.current, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(filename);
      // html2canvas(payslipRef.current).then((canvas) => {
      //   const imgData = canvas.toDataURL("image/png");
      //   const pdf = new jsPDF();
      //   const imgProps = pdf.getImageProperties(imgData);
      //   const pdfWidth = pdf.internal.pageSize.getWidth();
      //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      //   pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      //   pdf.save(filename);
    });
  };

  if (!salaryDetails) {
    return <p>No salary details provided.</p>;
  }

  return (
    <div>
      {successMessage && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-success"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-green-100 rounded-lg shadow"
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <span className="sr-only">Check icon</span>
            </div>
            <div className="ms-3 text-md font-normal mx-5">
              {successMessage}
            </div>
            <button
              type="button"
              onClick={successClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
              data-dismiss-target="#toast-success"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      {error && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-danger"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-red-100 rounded-lg shadow"
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="ms-3 text-md font-normal mx-5">{error}</div>
            <button
              type="button"
              onClick={errorClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
              data-dismiss-target="#toast-danger"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          {userRole == "admin" && (
            <>
              <BreadCrumbs
                paths={[
                  { url: "/admin-dashboard", label: "Home" },
                  { url: "/employee-list", label: "Employees" },
                  { url: "", label: "Employees" },
                ]}
              />

              <Sidebar
                menuItems={[
                  { text: "Home", path: "/admin-dashboard" },
                  { text: "Employees", path: "/employee-list", active: true },
                  { text: "Attendance", path: "/attendance-list" },
                  { text: "Calendar", path: "/admin-calendar" },
                  { text: "Timesheets", path: "/timesheet-list" },
                  { text: "Leaves", path: "/leaveslist" },
                  { text: "Projects", path: "/projects-list" },
                  { text: "Categories", path: "/categories-list" },
                  { text: "Roles", path: "/role" },
                  { text: "Departments", path: "/department" },
                  { text: "Positions", path: "/position" },
                  { text: "Reports", path: "/reports" },
                ]}
              />
            </>
          )}
          {userRole == "hr" && (
            <>
              <BreadCrumbs
                paths={[
                  { url: "/hr-dashboard", label: "Home" },
                  { url: "/employee-list", label: "Employees" },
                  { url: "", label: "Employees" },
                ]}
              />
              <Sidebar
                menuItems={[
                  { text: "Home", path: "/hr-dashboard" },
                  { text: "Employees", path: "/employee-list", active: true },
                  { text: "Calendar", path: "/admin-calendar" },
                  { text: "Reports", path: "/reports" },
                ]}
              />
            </>
          )}
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div className="bg-white shadow-md rounded-lg p-6" ref={payslipRef}>
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center">
                <img
                  className="w-16 h-16 mr-4"
                  src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=110,fit=crop,q=95/dJoB8GyOMNfKEGN3/1699858533334-Aq26ZoR9X1Irwyok.jpg"
                  alt="logo"
                />
                <div>
                  <h1 className="text-lg font-bold">
                    Rekonsys Software Services
                  </h1>
                  <p>
                    #47, Sri Sai Shine, 2nd Floor, Peddanna Reddy Layout
                    Horamavu,
                    <br />
                    Bengaluru, 560043 India
                  </p>
                </div>
              </div>
              <div className="text-right">
                <h2 className="text-lg font-bold">Payslip For the Month</h2>
                <p>
                  {salaryDetails.monthPaySlip}-{salaryDetails.yearPaySlip}
                </p>
              </div>
            </div>
            <div className="mb-6">
              <h3 className="text-lg font-bold mb-2">EMPLOYEE SUMMARY</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="grid grid-cols-2">
                  <p className="font-bold">Employee Name:</p>
                  <p>{salaryDetails.firstName + salaryDetails.lastName}</p>
                  <p className="font-bold">Employee ID:</p>
                  <p>{salaryDetails.employeeID}</p>
                  <p className="font-bold">Gender:</p>
                  <p>{salaryDetails.gender}</p>
                  <p className="font-bold">Designation:</p>
                  <p>{salaryDetails.designation}</p>
                  <p className="font-bold">PAN NO:</p>
                  <p>{salaryDetails.PANno}</p>
                  <p className="font-bold">UAN NO:</p>
                  <p>{salaryDetails.UANno}</p>
                </div>
                <div className="grid grid-cols-2">
                  <p className="font-bold">Date of Joining:</p>
                  <p>{salaryDetails.dateOfJoining}</p>
                  <p className="font-bold">Standard Days:</p>
                  <p>{salaryDetails.standardDays}</p>
                  <p className="font-bold">Days Worked:</p>
                  <p>{salaryDetails.daysWorked}</p>
                  <p className="font-bold">Bank Name:</p>
                  <p>{salaryDetails.bankName}</p>
                  <p className="font-bold">Bank A/c Number:</p>
                  <p>{salaryDetails.bankAccountNumber}</p>
                </div>
              </div>
            </div>
            <hr />
            <div className="mb-6">
              <table className="w-full text-left border-collapse">
                <thead>
                  <tr className="border-b">
                    <th className="py-2">EARNINGS</th>
                    <th className="py-2">AMOUNT</th>
                    <th className="py-2">DEDUCTIONS</th>
                    <th className="py-2">AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    <td className="py-2">Basic</td>
                    <td className="py-2">
                      ₹{formatCurrency(salaryDetails.basicSalary)}
                    </td>
                    <td className="py-2">Professional Tax</td>
                    <td className="py-2">
                      ₹{formatCurrency(salaryDetails.professionalTax)}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2">House Rent Allowance</td>
                    <td className="py-2">
                      ₹{formatCurrency(salaryDetails.HRA)}
                    </td>
                    <td className="py-2">Tax Deducted at Source</td>
                    <td className="py-2">
                      ₹{formatCurrency(salaryDetails.taxDeductedAtSource)}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2">Special Allowance</td>
                    <td className="py-2">
                      ₹{formatCurrency(salaryDetails.specialAllowance)}
                    </td>
                    <td className="py-2">Provident Fund</td>
                    <td className="py-2">
                      ₹{formatCurrency(salaryDetails.providentFund)}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2">Food Allowance</td>
                    <td className="py-2">
                      ₹{formatCurrency(salaryDetails.foodAllowance)}
                    </td>
                    <td className="py-2">Salary Advance</td>
                    <td className="py-2">
                      ₹{formatCurrency(salaryDetails.salaryAdvance)}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2">Telephone Allowance</td>
                    <td className="py-2">
                      ₹{formatCurrency(salaryDetails.telephoneAllowance)}
                    </td>
                    <td className="py-2">Other Deductions</td>
                    <td className="py-2">
                      ₹{formatCurrency(salaryDetails.otherDeductions)}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2">Travelling and Medical Allowance</td>
                    <td className="py-2">
                      ₹
                      {formatCurrency(
                        salaryDetails.travellingAndMedicalAllowance
                      )}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2">Leave Travel Allowance</td>
                    <td className="py-2">
                      ₹{formatCurrency(salaryDetails.leaveTravelAllowance)}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2">Arrears</td>
                    <td className="py-2">
                      ₹{formatCurrency(salaryDetails.arrears)}
                    </td>
                  </tr>
                  <tr className="border-t">
                    <td className="py-2 font-bold">Total Earnings</td>
                    <td className="py-2 font-bold">
                      ₹{formatCurrency(salaryDetails.totalSalary)}
                    </td>
                    <td className="py-2 font-bold">Total Deductions</td>
                    <td className="py-2 font-bold">
                      ₹{formatCurrency(salaryDetails.totalDeduction)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
            {/* <div className="text-right mt-4">
          <h3 className="text-xl font-bold">Net Salary: ₹{salaryDetails.netSalary}</h3>
        </div> */}
            <div className="bg-gray-200 p-4 rounded-lg">
              <div className="grid grid-cols-2">
                <div>
                  <h4 className="text-lg font-bold mb-2">TOTAL NET PAYABLE</h4>
                  <h4>Amount In Words:</h4>
                </div>

                <div>
                  <p className="text-lg font-bold mb-2">
                    ₹{formatCurrency(salaryDetails.netSalary)}
                  </p>
                  {/* <p> Indian Rupee Fifteen Thousand Only</p> */}
                  <p>
                    {netSalaryInWords.charAt(0).toUpperCase() +
                      netSalaryInWords.slice(1)}
                    rupees only
                  </p>
                </div>
              </div>
            </div>
            <div className="text-center text-gray-600 mt-6">
              <p>
                -- This document has been automatically generated by Rekonsys
                payroll; therefore, a signature is not required. --
              </p>
            </div>
            <div className="mt-6 flex justify-center print:hidden">
              <button
                onClick={handlePrint}
                className="bg-blue-500 text-white px-4 py-2 rounded mr-4"
              >
                Print
              </button>
              {/* <button
            onClick={handleDownloadPDF}
            className="bg-green-500 text-white px-4 py-2 rounded"
          >
            Download as PDF
          </button> */}
            </div>

            {/* <div className="mt-6 flex justify-center print:hidden">
        <button
          onClick={handlePrint}
          className="bg-blue-500 text-white px-4 py-2 rounded mr-4"
        >
          Print
        </button>
        <button
          onClick={handleDownloadPDF}
          className="bg-green-500 text-white px-4 py-2 rounded"
        >
          Download as PDF
        </button>
      </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payslipgen;
