import React from 'react';

const Benefits = () => {
 return (
    <section className="bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Benefits</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            A better way to work
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Showcase the Value Proposition of Your Solution
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {/* Benefit 1 */}
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                 {/* Icon */}
                 <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                 </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Unmatched Expertise</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">With years of experience in AI, Blockchain, and software development, we bring unparalleled expertise to every project.</dd>
            </div>

            {/* Benefit 2 */}
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                 {/* Icon */}
                 <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                 </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Tailored Solutions</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500"> Our solutions are customized to meet the unique needs of your business, ensuring maximum impact and ROI.</dd>
            </div>
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                 {/* Icon */}
                 <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                 </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Scalability</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">From startups to enterprises, our solutions are designed to scale with your business, enabling you to grow without limitations.</dd>
            </div>

            {/* Benefit 2 */}
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                 {/* Icon */}
                 <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                 </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Cost-Efficiency</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">By leveraging the latest technologies and best practices, we help you optimize costs and maximize efficiency.</dd>
            </div>

          </dl>
        </div>
      </div>
    </section>
 );
};

export default Benefits;

