import React, { useState, useEffect } from "react";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import Sidebar from "../components/Dashboard/Sidebar";
import { Link } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";import axios from "axios";
import { FaTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import DataTables from "../components/Datatables";
import { useNavigate } from "react-router-dom";

function Department() {
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Initially set to true
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await axios.get("https://api.rekonsys.tech/department-list");
      setDepartments(response.data);
      setIsLoading(false); // Set isLoading to false after fetching data
    } catch (error) {
      console.error("Error fetching departments:", error);
      setError("Failed to fetch departments");
      setIsLoading(false); // Set isLoading to false in case of error
    }
  };

  const errorClose = () => {
    setError("");
  };

  const handleEdit = (departmentId) => {
    navigate(`/department-update/${departmentId}`);
  };

  const handleDelete = async (departmentId) => {
    const confirmDelete = await Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this department!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmDelete.isConfirmed) {
      try {
        await axios.delete(
          `https://api.rekonsys.tech/department-delete/${departmentId}`
        );
        setDepartments((prevDepartments) =>
          prevDepartments.filter(
            (department) => department._id !== departmentId
          )
        );
        Swal.fire("Deleted!", "The department has been deleted.", "success");
      } catch (error) {
        console.error("Error deleting department:", error);
        Swal.fire("Error", "Failed to delete the department.", "error");
      }
    }
  };

  const columns = [
    { Header: "Sno.", accessor: "rowNumber", Cell: ({ row }) => row.index + 1 },
    { Header: "Department Name", accessor: "name" },
    {
      Header: "Color",
      accessor: "backgroundColor",
      Cell: ({ value }) => (
        <div className="flex justify-center items-center">
          <div
            className="w-4 h-4 rounded-full mr-2"
            style={{ backgroundColor: value }}
          ></div>
          <span>{value}</span>
        </div>
      ),
    },
    { Header: "Status", accessor: "status" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <div className="flex justify-center items-center">
          <FaRegEdit
            onClick={() => handleEdit(row.original._id)}
            style={{ cursor: "pointer" }}
            className="h-5 w-5 mr-2 text-blue-500"
          />
          <FaTrashAlt
            onClick={() => handleDelete(row.original._id)}
            style={{ cursor: "pointer" }}
            className="h-4 w-4 text-red-500"
          />
        </div>
      ),
      disableSortBy: true,
    },
  ];

  return (
    <>
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          <BreadCrumbs
            paths={[
              { url: "/admin-dashboard", label: "Home" },
              { url: "/department", label: "Departments" },
              { url: "", label: "Departments" },
            ]}
          />
          <Sidebar
            menuItems={[
              { text: "Home", path: "/admin-dashboard" },
              { text: "Employees", path: "/employee-list" },
              { text: "Attendance", path: "/attendance-list" },
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Timesheets", path: "/timesheet-list" },
              { text: "Leaves", path: "/leaveslist" },
              { text: "Projects", path: "/projects-list" },
              { text: "Categories", path: "/categories-list" },
              { text: "Roles", path: "/role" },
              { text: "Departments", path: "/department", active: true },
              { text: "Positions", path: "/position" },
              { text: "Reports", path: "/reports" },
            ]}
          />
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div>
            <header className="bg-white border-gray-300 border-b-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center mb-2">
                <h1 className="text-xl md:text-3xl font-semibold">
                  Department List
                </h1>
                <Link
                  className="block rounded-md bg-[#6610f2] px-5 py-2.5 text-sm font-medium text-white transition hover:bg-teal-700"
                  to="/add-department"
                >
                  Add Department
                </Link>
              </div>
            </header>
          </div>
          {isLoading ? (
            <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
              Loading Records.....
            </div>
          ) : (
            <div>
              <DataTables columns={columns} data={departments} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Department;
