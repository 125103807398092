import React, { useState, useEffect } from "react";
import Navbar from '../components/Dashboard/navbar'
import BreadCrumbs from '../components/Dashboard/BreadCrumbs'
import Sidebar from '../components/Dashboard/Sidebar'
import { Link } from 'react-router-dom'
import { FaRegEdit } from "react-icons/fa";import axios from "axios";
 import { FaTrashAlt } from "react-icons/fa";
 import Swal from "sweetalert2";
 import DataTables from "../components/Datatables";
 import { useNavigate } from "react-router-dom";

const CategoriesList = () => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);


  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setIsLoading(true);
    try{
    const response = await axios.get("https://api.rekonsys.tech/categories");
    setCategories(response.data);
    setIsLoading(false);
    } catch(error){
      console.error("Error fetching categories:", error);
    }
  };
    
    const errorClose = () => {
      setError("");
    };
  const columns = [
    { Header: "Sno.", accessor: "rowNumber", Cell: ({ row }) => row.index + 1 },
    { Header: "Category Name", accessor: "categoryName" },
    {
      Header: "Color",
      accessor: "color",
      Cell: ({ value }) => (
        <div className="flex justify-center items-center">
          <div
            className="w-4 h-4 rounded-full mr-2"
            style={{ backgroundColor: value }}
          ></div>
          <span>{value}</span>
        </div>
      ),
    },
    { Header: "Status", accessor: "status" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <div className="flex justify-center items-center">
          {/* Edit Button */}
          <FaRegEdit
            onClick={() => {
             handleRedirect(row.original._id);
            }}
            style={{ cursor: "pointer" }}
            className="h-5 w-5 text-blue-500"
          />
          {/* Delete Button */}
          <FaTrashAlt
            onClick={() => {
             handleDelete(row.original._id);
            }}
            className="mx-2 h-4 w-4 text-red-500"
            style={{ cursor: "pointer" }}
          />
        </div>
      ),
      show: true,
      disableSortBy: true,
    },
  ];
  const handleRedirect = (id) => {
    navigate(`/edit-category/${id}`);
  };

  const handleDelete = async (id) => {
    
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your record has been deleted.", "success");
        try {
          const response = await axios.delete(
            `https://api.rekonsys.tech/delete-category/${id}`
          );
          fetchCategories();
        } catch (error) {
          setError("Category not found");
          setTimeout(() => {
            setError("");
          }, 5000);
        }
      }
    });
  };
  return (
    <div>
    <Navbar />
    {error && (
        <div className="fixed flex justify-center top-0 left-1/2 transform -translate-x-1/2 w-2/4 z-50 p-2 mt-4">
          <div
            id="toast-danger"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-red-100  rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg ">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="ms-3 text-md font-normal">{error}</div>
            <button
              type="button"
              onClick={errorClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 "
              data-dismiss-target="#toast-danger"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
        <BreadCrumbs
          paths={[
            { url: "/admin-dashboard", label: "Home" },
            { url: "/categories-list", label: "Categories" },
            { url: "", label: "Categories" },
          ]}
        />

        <Sidebar
          menuItems={[
            { text: "Home", path: "/admin-dashboard" },
            { text: "Employees", path: "/employee-list" },
            { text: "Attendance", path: "/attendance-list" },
            { text: "Calendar", path: "/admin-calendar" },
            { text: "Timesheets", path: "/timesheet-list" },
            { text: "Leaves", path: "/leaveslist" },
            { text: "Projects", path: "/projects-list"},
            { text: "Categories", path: "/categories-list", active: true },
            { text: "Roles", path: "/role" },
            { text: "Departments", path: "/department" },
            { text: "Positions", path: "/position" },
            { text: "Reports", path: "/reports" },
          ]}
        />
      </div>
      <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
      <div>
            <header className="bg-bg-white border-gray-300 border-b-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center mb-2">
                <h1 className="text-xl md:text-3xl font-semibold">Categories List</h1>

                <Link
                  className="block rounded-md bg-[#6610f2] px-5 py-2.5 text-sm font-medium text-white transition hover:bg-teal-700"
                  to="/add-category"
                >
                  Add Category
                </Link>
              </div>
            </header>
          </div>
         
         <div>
         {isLoading ? (
              <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
                Loading Records.....
              </div>
            ) : (
              <div className="overflow-x-auto">
                {categories && categories.length > 0 ? (
                  <DataTables columns={columns} data={categories} />
                ) : (
                  <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
                    No records found
                  </div>
                )}
              </div>
            )}
       {/* <DataTables columns={columns} data={categories} />  */}
          </div>
      </div>
    </div>
  </div>
  )
}

export default CategoriesList;