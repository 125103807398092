import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
const EditLeave = () => {
  const id = useParams().id;
  let navigate = useNavigate();
  const [leaveData, setLeaveData] = useState({
    leavetype: "",
    fromDate: "",
    toDate: "",
    reasonForLeave: "",
    day: "",
    noOfLeaves: 0,
    status: "pending",
  });
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [employeeRemainingLeaves, setEmployeeRemainingLeaves] = useState(null);

  useEffect(() => {
    getLeaveById();
  }, [id]);

  const getLeaveById = async () => {
    try {
      const res = await axios.get(`https://api.rekonsys.tech/leave/${id}`);
      setLeaveData(res.data.leave);
      setEmployeeEmail(res.data.leave.employeeEmail);
      setEmployeeCode(res.data.leave.employeeCode);
      setEmployeeRemainingLeaves(res.data.leave.employeeRemainingLeaves);
    } catch (error) {
      console.error("Error fetching user", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaveData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const errorClose = () => {
    setError("");
  };
  const successClose = () => {
    setSuccessMessage("");
  };
  //calculate the total leaves
  const handleFromDateChange = (event) => {
    setLeaveData((prevState) => ({
      ...prevState,
      fromDate: event.target.value,
    }));
    calculateLeavesInDays(event.target.value, leaveData.toDate);
  };

  const handleToDateChange = (event) => {
    setLeaveData((prevState) => ({
      ...prevState,
      toDate: event.target.value,
    }));
    calculateLeavesInDays(leaveData.fromDate, event.target.value);
  };

  const calculateLeavesInDays = (fromDate, toDate) => {
    if (!fromDate || !toDate) return;
    const fromDateTime = new Date(fromDate).getTime();
    const toDateTime = new Date(toDate).getTime();
    const differenceInTime = toDateTime - fromDateTime;
    const leavesInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

    setLeaveData((prevState) => ({
      ...prevState,
      noOfLeaves: leavesInDays,
    }));
  };

  const validateForm = () => {
    const requiredFields = [
      "leavetype",
      "fromDate",
      "toDate",
      "reasonForLeave",
      "day",
      "noOfLeaves",
    ];

    for (const field of requiredFields) {
      if (!leaveData[field]) {
        return { isValid: false, message: `${field} is required` };
      }
    }
    if (!employeeCode) {
      return { isValid: false, message: `Employee Code is required` };
    }
    if (!employeeEmail) {
      return { isValid: false, message: `Employee Email is required` };
    }
    if (!employeeRemainingLeaves) {
      return { isValid: false, message: `Employee Remaining Leaves is required` };
    }

    return { isValid: true };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationResult = validateForm();
    if (validationResult.isValid) {
      const formData = { ...leaveData, "employeeRemainingLeaves": employeeRemainingLeaves, "employeeCode": employeeCode, "employeeEmail": employeeEmail}
      
      const res = await axios.put(
        `https://api.rekonsys.tech/edit-leave/${id}`,
        formData
      );
      if (res.status == 200) {
        setSuccessMessage(res.data.message);
        setTimeout(() => {          
            navigate("/employee-dashboard");          
        }, 4000);
      } else {
        setError(res.data.message);
      }
    } else {
      setError(validationResult.message);
    }
  };

  const handleCancel = () => {
    navigate("/employee-dashboard");
  };

  return (
    <div>
      
      {successMessage && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-success"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-green-100 rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg ">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <span className="sr-only">Check icon</span>
            </div>
            <div className="ms-3 text-sm font-normal mx-5">{successMessage}</div>
            <button
              type="button"
              onClick={successClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
              data-dismiss-target="#toast-success"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      {error && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-danger"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-red-100  rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg ">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="ms-3 text-md font-normal mx-5">{error}</div>
            <button
              type="button"
              onClick={errorClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 "
              data-dismiss-target="#toast-danger"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-32 rounded-lg m-2 w-full">
          <BreadCrumbs
            paths={[
              { url: "/", label: "Home" },
              { url: "/employee-dashboard", label: "DashBoard" },
              { url: "", label: "Edit Leave" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/employee-dashboard" },
              { text: "Attendance", path: "/employee-attendance-list" },
              { text: "Calender", path: "/admin-calendar" },
              { text: "Timesheets", path: "/employee-timesheet-list" },
              { text: "Apply Leave", path: "/apply-leave", active: true },
            ]}
          />
        </div>

        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div>
            <header className="bg-white border-gray-300 border-b-2">
              <div className="mx-auto w-100 flex h-16 max-w-screen-xl justify-between items-center gap-8 px-4 sm:px-6 lg:px-8">
                <h1 className="text-xl md:text-3xl pb-4 font-semibold">Edit Leave </h1>
              </div>
            </header>
          </div>
          <div>
            <div className="mt-4 px-4 leading-relaxed flex flex-col gap-3 text-gray-700">
              <div className="w-full flex gap-5">
                <div className="w-full flex flex-col">
                  <label>Leave Type</label>
                  <select
                    className="w-full rounded-lg  border-2 border-gray-300 p-3 text-md"
                    name="leavetype"
                    value={leaveData.leavetype}
                    onChange={handleChange}
                  >
                    <option value="">Select Leave</option>
                    <option value="workfromhome">Work From Home</option>
                    <option value="sickleave">Sick Leave</option>
                    <option value="normalleave">Normal Leave</option>
                  </select>
                </div>
                <div className="w-full flex flex-col">
                  <label>Remaining Leaves</label>
                  <input
                    className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm bg-gray-300"
                    type="number"
                    value={employeeRemainingLeaves}
                  />
                </div>
              </div>
              <div className="w-full flex gap-5">
                <div className="w-full flex flex-col">
                  <div className="mb-4">
                    <label
                      htmlFor="fromDate"
                      className="block text-gray-700  mb-2"
                    >
                      From Date
                    </label>
                    <input
                      type="date"
                      name="fromDate"
                      required
                      value={leaveData.fromDate}
                      onChange={handleFromDateChange}
                      className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col">
                  <div className="mb-4">
                    <label
                      htmlFor="toDate"
                      className="block text-gray-700  mb-2"
                    >
                      To Date
                    </label>
                    <input
                      type="date"
                      name="toDate"
                      required
                      value={leaveData.toDate}
                      onChange={handleToDateChange}
                      className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex gap-5">
                <div className="w-full flex flex-col">
                  <label>Day</label>
                  <select
                    className="w-full rounded-lg  border-2 border-gray-300 p-3 text-md"
                    name="day"
                    value={leaveData.day}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="fullday">Full Day</option>
                    <option value="halfday">Half Day</option>
                  </select>
                </div>
                <div className="w-full flex flex-col">
                  <label>Number of Days Leave</label>
                  <input
                    className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm bg-gray-300"
                    type="number"
                    value={leaveData.noOfLeaves}
                    readOnly
                  />
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="reason" className="block text-gray-700 mb-2">
                  Reason:
                </label>
                <textarea
                  name="reasonForLeave"
                  required
                  value={leaveData.reasonForLeave}
                  onChange={handleChange}
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="w-full flex justify-center gap-5">
                <button
                  className="block rounded-md bg-[#00008B] px-5 py-2.5 text-sm font-medium text-white transition hover:bg-[#000435]"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <button
                  className="block rounded-md bg-red-500 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-[#8B0000]"
                  type="cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLeave;
