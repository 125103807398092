import React from "react";
import { Link } from "react-router-dom";

const BreadCrumbs = ({ paths }) => {
  return (
    <div className="flex flex-col justify-center items-start rounded-lg border pl-7 border-gray-200 text-xl font-semibold text-gray-600 h-20 shadow-md mb-2">
    
      <ul className="flex justify-center items-center text-sm">
        {paths.slice(0, 2).map((path, index) => (
          <span key={path.url} className="breadcrumb-item">
            <Link to={path.url} className="breadcrumb-link">
              {path.label}
            </Link>
            {index < paths.length - 2 && (
              <span className="breadcrumb-separator pr-1">{" > "}</span>
            )}
          </span>
        ))}
        
      </ul>
      <ul className="flex justify-center items-center text-lg">
  
        {paths.length > 2 && <li className="">{paths[2].label}</li>}
      </ul>
    </div>
  );
};

export default BreadCrumbs;
