import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import Sidebar from "../components/Dashboard/Sidebar";
import Calendar from "../components/Dashboard/Calendar";
import { useState, useEffect } from "react";
import axios from "axios";

const EmployeeCalendar = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      const events = await axios.get("https://api.rekonsys.tech/events");
      const formatedEvents = events.data.map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      }));
      setEvents(formatedEvents);
      setIsLoading(false);
      // console.log(events.data)
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getStartEndTimes = (startTime, endTime) => {
    let startHours = startTime.getHours();
    if (startHours < 10) {
      startHours = "0" + startHours;
    }
    let startMinutes = startTime.getMinutes();
    if (startMinutes < 10) {
      startMinutes = "0" + startMinutes;
    }
    let endHours = endTime.getHours();
    if (endHours < 10) {
      endHours = "0" + endHours;
    }
    let endMinutes = endTime.getMinutes();
    if (endMinutes < 10) {
      endMinutes = "0" + endMinutes;
    }

    const start = `${startHours}:${startMinutes}`;
    const end = `${endHours}:${endMinutes}`;

    return [start, end];
  };

  const handleSelectEvent = (event) => {
    const { start, end } = event;
    const [startTime, endTime] = getStartEndTimes(start, end);
    const selectedEvent = {
      id: event._id,
      title: event.title,
      desc: event.desc,
      startDate: start,
      endDate: end,
      startTime,
      endTime,
    };
    setSelectedEvent(selectedEvent);
  };

  const handleModalClose = () => {
    setSelectedEvent(null);
  };

  const updateEventModal = () => {
    const { title, startDate, endDate, desc, startTime, endTime } =
      selectedEvent;
    return (
      <div className="w-screen h-screen flex justify-center items-center fixed top-0 left-1/2 transform -translate-x-1/2 z-50 p-2 mt-4">
        <div className="w-[350px] md:w-[500px] h-100 bg-[white] shadow-[rgba(0,0,0,0.35)_0px_5px_15px] flex flex-col p-[25px] rounded-x">
          <div className="flex justify-between mb-2">
            <h1 className="text-xl md:text-3xl pb-4 font-semibold">
              Event Details
            </h1>
            <button
              className="bg-transparent text-[25px] cursor-pointer border-[none] mr-2"
              onClick={handleModalClose}
            >
              X
            </button>
          </div>
          <form>
            <div className="mb-4">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                value={title}
                disabled={selectedEvent}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="desc"
                className="block text-sm font-medium text-gray-700"
              >
                Descriprion
              </label>
              <input
                type="text"
                id="desc"
                value={desc}
                disabled={selectedEvent}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
            <div className="w-full flex flex-col md:flex-row gap-5">
              <div className="mb-4 w-full flex flex-col">
                <label
                  htmlFor="startDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  id="startDate"
                  disabled={selectedEvent}
                  value={startDate.toISOString().split("T")[0]}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>
              <div className="mb-4 w-full flex flex-col">
                <label
                  htmlFor="endDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  End Date
                </label>
                <input
                  type="date"
                  id="endDate"
                  disabled={selectedEvent}
                  value={endDate.toISOString().split("T")[0]}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-5">
              <div className="mb-4 w-full flex flex-col">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="startTime"
                >
                  Start Time
                </label>
                <input
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  id="startTime"
                  type="time"
                  disabled={selectedEvent}
                  value={startTime}
                />
              </div>
              <div className="mb-4 w-full flex flex-col">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="endTime"
                >
                  End Time
                </label>
                <input
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  id="endTime"
                  type="time"
                  disabled={selectedEvent}
                  value={endTime}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          <BreadCrumbs
            paths={[
              { url: "/employee-dashboard", label: "Home" },
              { url: "/employee-calendar", label: "Calendar" },
              { url: "", label: "Calendar" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/employee-dashboard" },
              { text: "Attendance", path: "/employee-attendance-list" },
              { text: "Calendar", path: "/employee-calendar", active: true },
              { text: "Timesheets", path: "/employee-timesheet-list" },
              { text: "Leaves", path: "/employee-leaves-list" },
            ]}
          />
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div>
            <header className="bg-white border-gray-300 border-b-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center mb-2">
                <h1 className="text-xl md:text-3xl font-semibold">Calendar</h1>
              </div>
            </header>
          </div>
          {selectedEvent && updateEventModal()}
          {isLoading ? (
            <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
              Loading Calendar, Please Wait
            </div>
          ) : (
            <Calendar
              selectable
              onSelectEvent={handleSelectEvent}
              events={events}
            />
          )}
          {/**<Calendar events={events} /> */}
        </div>
      </div>
    </div>
  );
};

export default EmployeeCalendar;
