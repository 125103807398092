import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";

import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver"; // Import FileSaver
import DataTables from "../components/Datatables";
function LeaveReports() {
  const [leaves, setLeaves] = useState([]);
  const [filteredLeaves, setFilteredLeaves] = useState([]); // State to hold filtered data
  const [startDate, setStartDate] = useState(""); // State for start date
  const [endDate, setEndDate] = useState(""); // State for end date
  const [leaveType, setLeaveType] = useState("");
  const [dayType, setDayType] = useState("");
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false); //for page loading
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    setUserRole(localStorage.getItem("userRole"));    
    fetchLeaves();
  }, []);

  const fetchLeaves = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://api.rekonsys.tech/all-leaves");
      const formattedData = response.data.leaves.map((data) => ({
        ...data,
        fromDate: data.fromDate.split("T")[0].slice(0, 10),
      }));
      setLeaves(formattedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching leaves:", error);
    }
  };

  useEffect(() => {
    const filteredData = leaves.filter(
      (leave) =>
        (!leaveType || leave.leavetype === leaveType) &&
        (!dayType || leave.day === dayType) &&
        (!status || leave.status === status) &&
        ((!startDate && !endDate) ||
          (startDate &&
            new Date(leave.fromDate) >= new Date(startDate) &&
            (!endDate || new Date(leave.fromDate) <= new Date(endDate))))
    );

    // Check if only the end date is provided
    if (!startDate && endDate) {
      // setFilteredLeaves(filteredData);
    } else {
      // Apply the existing filtering logic for other scenarios
      if (startDate && endDate) {
        const rangeFilter = filteredData.filter(
          (leave) =>
            new Date(leave.fromDate) >= new Date(startDate) &&
            new Date(leave.fromDate) <= new Date(endDate)
        );
        setFilteredLeaves(rangeFilter);
      } else if (startDate) {
        const startDateFilter = filteredData.filter(
          (leave) =>
            new Date(leave.fromDate).getTime() === new Date(startDate).getTime()
        );
        setFilteredLeaves(startDateFilter);
      } else {
        setFilteredLeaves(filteredData);
      }
    }
  }, [leaveType, startDate, endDate, dayType, status, leaves]);

  const clearFilters = () => {
    setStartDate("");
    setEndDate("");
    setLeaveType("");
    setDayType("");
    setStatus("");
    fetchLeaves(); // Reset table data to original state
  };
  // Function to download CSV file
  const downloadExcel = () => {
    // Define the selected columns
    const selectedColumns = [
      "employeeCode",
      "employeeEmail",
      "leavetype",
      "noOfLeaves",
      "employeeRemainingLeaves",
      "day",
      "status",
      "fromDate",
    ];
    // Extract data for selected columns from filteredLeaves
    const selectedLeavesData = filteredLeaves.map((leave) => {
      const selectedLeave = {};
      selectedColumns.forEach((column) => {
        selectedLeave[column] = leave[column];
      });
      return selectedLeave;
    });

    // Convert the selected data to CSV
    const ws = XLSX.utils.json_to_sheet(selectedLeavesData, {
      header: selectedColumns,
    });
    const csv = XLSX.utils.sheet_to_csv(ws);

    // Create a Blob and save the file
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "LeaveReports.csv");
  };

  const columns = [
    { Header: "Sno.", accessor: "rowNumber", Cell: ({ row }) => row.index + 1 },
    { Header: "Employee Code", accessor: "employeeCode" },
    { Header: "Employee Email", accessor: "employeeEmail" },
    { Header: "Leave Type", accessor: "leavetype" },
    { Header: "No Of Leaves", accessor: "noOfLeaves" },
    { Header: "Remaining Leaves", accessor: "employeeRemainingLeaves" },
    { Header: "Day", accessor: "day" },
    { Header: "Status", accessor: "status" },
    { Header: "Date", accessor: "fromDate" },
  ];

  return (
    <div>
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
        {userRole == "admin" && 
          <><BreadCrumbs
            paths={[
              { url: "/admin-dashboard", label: "Home" },
              { url: "/reports", label: "Reports" },
              { url: "", label: "Leave Reports" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/admin-dashboard" },
              { text: "Employees", path: "/employee-list" },
              { text: "Attendance", path: "/attendance-list" },
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Timesheets", path: "/timesheet-list" },
              { text: "Leaves", path: "/leaveslist" },
              { text: "Projects", path: "/projects-list" },
              { text: "Categories", path: "/categories-list" },
              { text: "Roles", path: "/role" },
              { text: "Departments", path: "/department" },
              { text: "Positions", path: "/position" },
              { text: "Reports", path: "/reports", active: true },
            ]}
          /></>}
          {userRole == "hr" && 
          <><BreadCrumbs
            paths={[
              { url: "/hr-dashboard", label: "Home" },
              { url: "/reports", label: "Reports" },
              { url: "", label: "Leave Reports" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/hr-dashboard" },
              { text: "Employees", path: "/employee-list"},
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Reports", path: "/reports", active: true },
            ]}
          /></>}
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <nav
            aria-label="Breadcrumb"
            className="flex justify-center bg-white border-gray-300 border-b-2 pb-2"
          >
            <ol className="flex rounded-lg h-10 md:h-14 border border-gray-200 text-gray-600 text-lg">
              <li className="flex items-center border-r border-gray-200">
                <Link
                  to="/reports"
                  className="flex items-center h-full rounded-l-lg bg-white  px-2 md:px-6 text-xs font-medium transition hover:text-gray-900"
                >
                  <span className="mx-1 text-[10px] md:text-[14px] font-medium text-center">
                    Employee Reports
                  </span>
                </Link>
                {/* <div className="h-full border-r border-gray-200"></div> */}
              </li>
              <li className="flex items-center border-r border-gray-200">
                <Link
                  to="/leave-reports"
                  className="flex items-center h-full bg-[#f1bd06] px-2 md:px-6 text-xs font-medium transition hover:text-gray-900"
                >
                  <span className="mx-1 text-[10px] md:text-[14px] font-medium text-center">
                    Leave Reports
                  </span>
                </Link>
                {/* <div className="h-full border-r border-gray-200"></div> */}
              </li>
              <li className="flex items-center border-r border-gray-200">
                <Link
                  to="/payroll-reports"
                  className="flex items-center h-full bg-white px-2 md:px-6 text-xs font-medium transition hover:text-gray-900"
                >
                  <span className="mx-1 text-[10px] md:text-[14px] font-medium text-center">
                    Payroll Reports
                  </span>
                </Link>
                {/* <div className="h-full border-r border-gray-200"></div> */}
              </li>
              <li className="flex items-center">
                <Link
                  to="/contact-reports"
                  className="flex items-center h-full bg-white px-2 md:px-6 text-xs font-medium transition hover:text-gray-900"
                >
                  <span className="mx-1 text-[10px] md:text-[14px] font-medium text-center">
                    Contact Reports
                  </span>
                </Link>
              </li>
            </ol>
          </nav>
          <div className="my-4">
            <div className="w-full flex flex-col md:flex-row gap-5">
              <div className="w-full flex flex-col">
                <label htmlFor="leaveType" className="mr-2">
                  Leave Type:
                </label>
                <select
                  id="leaveType"
                  value={leaveType}
                  onChange={(e) => setLeaveType(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md mr-2"
                >
                  <option value="">All</option>
                  <option value="sickleave">Sick Leave</option>
                  <option value="workfromhome">Work From Home</option>
                  <option value="normalleave">Normal Leave</option>
                </select>
              </div>
              <div className="w-full flex flex-col">
                <label htmlFor="dayType" className="mr-2">
                  Day Type:
                </label>
                <select
                  id="dayType"
                  value={dayType}
                  onChange={(e) => setDayType(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md mr-2"
                >
                  <option value="">All</option>
                  <option value="halfday">Half Day</option>
                  <option value="fullday">Full Day</option>
                </select>
              </div>
              <div className="w-full flex flex-col">
                <label htmlFor="status" className="mr-2">
                  Status:
                </label>
                <select
                  id="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md mr-2"
                >
                  <option value="">All</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                  <option value="pending">Pending</option>
                </select>
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-5 mt-2">
              <div className="w-full flex flex-col">
                <label htmlFor="startDate" className="mr-2">
                  Start Date:
                </label>
                <input
                  id="startDate"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md mr-2"
                  placeholder="Start Date"
                />
              </div>
              <div className="w-full flex flex-col">
                <label htmlFor="endDate" className="mr-2">
                  End Date:
                </label>
                <input
                  id="endDate"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  min={startDate} // Set min attribute to the selected start date
                  disabled={!startDate} // Disable end date if start date is not selected
                  className="border border-gray-300 p-2 rounded-md mr-2"
                  placeholder="End Date"
                />
              </div>
              <div className="w-full flex md:mt-6">
                <button
                  onClick={clearFilters}
                  className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>

          <div className="my-2">
            {isLoading ? (
              <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
                Loading Records.....
              </div>
            ) : (
              <div>
                {filteredLeaves && filteredLeaves.length > 0 ? ( // Check if there are filtered leaves
                  <div>
                    <div>
                      <DataTables columns={columns} data={filteredLeaves} />
                    </div>
                    <div className="my-6">
                      <button
                        onClick={downloadExcel}
                        className="bg-blue-700 hover:bg-[#063081] text-white font-bold py-2 px-4 rounded"
                      >
                        Download CSV
                      </button>
                    </div>
                  </div>
                ) : (
                  // If there are no filtered leaves
                  <div className="mt-6 text-center text-gray-600">
                    No data to display
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaveReports;
