import React, { useState, useEffect } from "react";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import HomePage from "./pages/HomePage";
import AdminDashboard from "./pages/AdminDashboard";
import EmployeeList from "./pages/EmployeeList";
import EmployeeDashboard from "./pages/EmployeeDashboard";
import AddEmployee from "./pages/AddEmployee";
import EditEmployee from "./pages/EditEmployee";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ForgotPassword from "./pages/ForgotPassword";
import NotFound from "./pages/NotFound";
import PrivateRoutes from "./utils/PrivateRoutes";
import AddLeave from "./pages/AddLeave";
import EditLeave from "./pages/EditLeave";
import LeavesList from "./pages/LeavesList";
import TimeSheetList from "./pages/TimeSheetList";
import EmployeeTimesheetList from "./pages/EmployeeTimesheetList";
import AddTimeSheet from "./pages/AddTimeSheet";
import EditTimeSheet from "./pages/EditTimeSheet";
import EditProfile from "./pages/EditProfile";
import EmployeeLeavesList from "./pages/EmployeeLeavesList";
import AdminCalendar from "./pages/AdminCalendar";
import EmployeeCalendar from "./pages/EmployeeCalendar";
import Reports from "./pages/Reports";
import LeaveReports from "./pages/LeaveReports";
import PayRollReports from "./pages/PayRollReports";
import ContactReports from "./pages/ContactReports";
import AttendanceForm from "./pages/AttendanceForm";
import AttendanceList from "./pages/AttendanceList";
import EmployeeAttendanceList from "./pages/EmployeeAttendanceList";

import ProjectsList from "./pages/ProjectsList";
import AddProject from "./pages/AddProject";
import EditProject from "./pages/EditProject";
import CategoriesList from "./pages/CategoriesList";
import AddCategory from "./pages/AddCategory";
import EditCategory from "./pages/EditCategory";
import Role from "./pages/Role";
import AddRole from "./pages/AddRole";
import EditRole from "./pages/EditRole";

import Department from "./pages/Department";
import AddDepartment from "./pages/AddDepartment";
import EditDepartment from "./pages/EditDepartment";

import Position from "./pages/Position";
import AddPosition from "./pages/AddPosition";
import EditPosition from "./pages/EditPosition";
import EmployeeDetails from "./pages/EmployeeDetails";
import PaySlip from "./pages/PaySlip";
import ManagerDashboard from "./pages/ManagerDashboard";
import HRDashboard from "./pages/HRDashboard";
import ResetPassword from "./pages/ResetPassword";
import Payslipgen from "./pages/PaySlipGen";
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/manager-dashboard" element={<ManagerDashboard />} />
            <Route path="/hr-dashboard" element={<HRDashboard />} />
            <Route path="/employee-list" element={<EmployeeList />} />
            <Route path="/add-employee" element={<AddEmployee />} />
            <Route path="/edit-employee/:id" element={<EditEmployee />} />
            <Route path="/employee-details/:id" element={<EmployeeDetails />} />
            <Route path="/pay-slip/:id" element={<PaySlip />} />
            <Route path="/payslip" element={<Payslipgen />} />
            <Route path="/apply-leave" element={<AddLeave />} />
            <Route path="/edit-leave/:id" element={<EditLeave />} />
            <Route path="/leaveslist" element={<LeavesList />} />
            <Route
              path="/employee-leaves-list"
              element={<EmployeeLeavesList />}
            />
            <Route path="/employee-dashboard" element={<EmployeeDashboard />} />
            <Route path="/timesheet-list" element={<TimeSheetList />} />
            <Route
              path="/employee-timesheet-list"
              element={<EmployeeTimesheetList />}

/>
            <Route path="/add-timesheet" element={<AddTimeSheet />} />
            <Route path="/edit-timesheet/:id" element={<EditTimeSheet />} />
            <Route path="/edit-profile/:id" element={<EditProfile />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/leave-reports" element={<LeaveReports />} />
            <Route path="/payroll-reports" element={<PayRollReports />} />
            <Route path="/contact-reports" element={<ContactReports />} />
            <Route path="/admin-calendar" element={<AdminCalendar />} />
            <Route path="/employee-calendar" element={<EmployeeCalendar />} />
            <Route path="/attendance-list" element={<AttendanceList />} />
            <Route
              path="/employee-attendance-list"
              element={<EmployeeAttendanceList />}
            />
             <Route path="/projects-list" element={<ProjectsList />} />
             <Route path="/add-project" element={<AddProject />} />
             <Route path="/edit-project/:id" element={<EditProject />} />
             <Route path="/categories-list" element={<CategoriesList />} />
             <Route path="/add-category" element={<AddCategory />} />
             <Route path="/edit-category/:id" element={<EditCategory />} />
          </Route>
          <Route element={<Login />} path="/login" />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/reset-password/:id/:token" element={<ResetPassword />} />
          <Route path="/attendance/:id" element={<AttendanceForm />} />

          <Route path="/role" element={<Role/>} />
          <Route path="/role-update/:id" element={<EditRole/>} />
          <Route path="/add-role" element={<AddRole/>} />
      
          <Route path="/department" element={<Department/>} />
          <Route path="/add-department" element={<AddDepartment/>} />
          <Route path="/department-update/:id" element={<EditDepartment/>} />

          <Route path="/position" element={<Position/>} />
          <Route path="add-position" element={<AddPosition/>} />
          <Route path="position-update/:id" element={<EditPosition/>} />

          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Routes></Routes>
      </Router>
    </>
  );
}

export default App;
