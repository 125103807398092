import React, { useState } from "react";
import { useRef, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";

const adminMenuItems = [
  { label: "Home", link: "/admin-dashboard" },
  { label: "Employees", link: "/employee-list" },
  { label: "Attendance", link: "/attendance-list" },
  { label: "Calendar", link: "/admin-calendar" },
  { label: "Timesheets", link: "/timesheet-list" },
  { label: "Leaves", link: "/leaveslist" },
  { label: "Projects", link: "/projects-list" },
  { label: "Categories", link: "/categories-list" },
  { label: "Roles", link: "/role" },
  { label: "Departments", link: "/department" },
  { label: "Positions", link: "/position" },
  { label: "Reports", link: "/reports" },
];

const employeeMenuItems = [
  { label: "Home", link: "/employee-dashboard" },
  { label: "Attendance", link: "/employee-attendance-list" },
  { label: "Calendar", link: "/employee-calendar" },
  { label: "Timesheets", link: "/employee-timesheet-list" },
  { label: "Leaves", link: "/employee-leaves-list" },
];
const managerMenuItems = [
  { label: "Home", link: "/manager-dashboard" },
  { label: "Attendance", link: "/attendance-list" },
  { label: "Timesheets", link: "/timesheet-list" },
  { label: "Leaves", link: "/leaveslist" },
  { label: "Projects", link: "/projects-list" },
];
const hrMenuItems = [
  { label: "Home", link: "/hr-dashboard" },
  { label: "Employees", link: "/employee-list" },
  { label: "Calendar", link: "/admin-calendar" },
  { label: "Reports", link: "/reports" },
];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const userRole = localStorage.getItem("userRole");
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const email = localStorage.getItem("email");
  // console.log(firstName`)
  // console.log(lastName)`
  let navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && event.target.classList.contains("bg-black")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  const id = localStorage.getItem("userID");
  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userRole");
    localStorage.removeItem("userID");
    localStorage.clear();

    navigate("/", { replace: true });
  };

  const handleEditProfile = () => {
    navigate(`/edit-profile/${id}`, { replace: true });
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  return (
    <nav className="w-full flex items-center justify-center bg-[#440089] px-7 py-2 md:py-3 lg:py-4 shadow-lg">
      <Link
        to={`/${userRole}-dashboard`}
        className="text-white font-bold text-lg md:text-2xl cursor-pointer"
      >
        REKONSYS
      </Link>
      <div className="ml-auto" ref={dropdownRef}>
        <div className="flex justify-center items-center">
          <h1 className="mr-5 text-white text-xl hidden md:block">
            
            Hello, <span>{firstName}</span>
          </h1>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="focus:outline-none"
          >
            <div className="w-11 h-11  bg-[#0078d4] flex justify-center items-center rounded-full text-xl font-medium text-white overflow-hidden ">
              <span className="uppercase">{initials}</span>
            </div>
          </button>
          {isOpen && (
            <div className="w-screen h-screen bg-black bg-opacity-25 flex justify-end items-start fixed top-0 left-1/2 -translate-x-1/2 z-50">
              <div className="w-[200px] md:w-[250px] h-full bg-[white] shadow-[rgba(0,0,0,0.35)_0px_5px_15px] flex flex-col rounded-x overflow-y-auto py-2">
                <div className="text-start ml-5">
                  <button
                    className="bg-transparent text-xl cursor-pointer border-[none]"
                    onClick={handleModalClose}
                  >
                    X
                  </button>
                </div>
                <div className="flex flex-col justify-between items-center">
                  <div className="flex flex-col justify-between items-center gap-3">
                    <div className="w-12 md:w-16 h-12 md:h-16  bg-[#0078d4] flex justify-center items-center rounded-full text-2xl md:text-3xl font-medium text-white overflow-hidden ">
                      <span className="uppercase">{initials}</span>
                    </div>
                    <h1 className=" text-black text-sm md:text-lg font-semibold">
                      {firstName + " " + lastName}
                    </h1>
                  </div>
                  <div className="text-black text-xs md:text-base">{email}</div>

                  <div className="w-full mt-5">
                    {/* {userRole === "admin" ? (
                      <div className="block lg:hidden">
                        {adminMenuItems.map((item, index) => (
                          <Link
                            key={index}
                            to={item.link}
                            className={`block px-5 py-2 text-sm ${
                              location.pathname === item.link
                                ? "text-white bg-[#f1bd06]"
                                : "text-black hover:bg-indigo-500 hover:text-white"
                            } cursor-pointer`}
                          >
                            {item.label}
                          </Link>
                        ))}
                      </div>
                    ) : (
                      <div className="block lg:hidden">
                        {employeeMenuItems.map((item, index) => (
                          <Link
                            key={index}
                            to={item.link}
                            className={`block px-5 py-2 text-sm ${
                              location.pathname === item.link
                                ? "text-white bg-[#f1bd06]"
                                : "text-black hover:bg-indigo-500 hover:text-white"
                            } cursor-pointer`}
                          >
                            {item.label}
                          </Link>
                        ))}
                      </div>
                    )} */}
                    {userRole === "admin" ? (
                      <div className="block lg:hidden">
                        {adminMenuItems.map((item, index) => (
                          <Link
                            key={index}
                            to={item.link}
                            className={`block px-5 py-2 text-sm ${
                              location.pathname === item.link
                                ? "text-white bg-[#f1bd06]"
                                : "text-black hover:bg-indigo-500 hover:text-white"
                            } cursor-pointer`}
                          >
                            {item.label}
                          </Link>
                        ))}
                      </div>
                    ) : userRole === "manager" ? (
                      <div className="block lg:hidden">
                        {managerMenuItems.map((item, index) => (
                          <Link
                            key={index}
                            to={item.link}
                            className={`block px-5 py-2 text-sm ${
                              location.pathname === item.link
                                ? "text-white bg-[#f1bd06]"
                                : "text-black hover:bg-indigo-500 hover:text-white"
                            } cursor-pointer`}
                          >
                            {item.label}
                          </Link>
                        ))}
                      </div>
                    ) : userRole === "hr" ? (
                      <div className="block lg:hidden">
                        {hrMenuItems.map((item, index) => (
                          <Link
                            key={index}
                            to={item.link}
                            className={`block px-5 py-2 text-sm ${
                              location.pathname === item.link
                                ? "text-white bg-[#f1bd06]"
                                : "text-black hover:bg-indigo-500 hover:text-white"
                            } cursor-pointer`}
                          >
                            {item.label}
                          </Link>
                        ))}
                      </div>
                    ) : userRole === "employee" ? (
                      <div className="block lg:hidden">
                        {employeeMenuItems.map((item, index) => (
                          <Link
                            key={index}
                            to={item.link}
                            className={`block px-5 py-2 text-sm ${
                              location.pathname === item.link
                                ? "text-white bg-[#f1bd06]"
                                : "text-black hover:bg-indigo-500 hover:text-white"
                            } cursor-pointer`}
                          >
                            {item.label}
                          </Link>
                        ))}
                      </div>
                    ) : null}

                    <a
                      onClick={handleEditProfile}
                      // className="block px-5 py-2 text-sm text-black hover:bg-indigo-500 hover:text-white cursor-pointer"
                      className={`block px-5 py-2 text-sm ${
                        location.pathname === `/edit-profile/${id}`
                          ? "text-white bg-[#f1bd06]"
                          : "text-black hover:bg-indigo-500 hover:text-white"
                      } cursor-pointer`}
                    >
                      Edit Profile
                    </a>
                    <a
                      onClick={handleLogout}
                      className="block px-5 py-2 text-sm text-black hover:bg-indigo-500 hover:text-white cursor-pointer"
                    >
                      Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
