import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import Sidebar from "../components/Dashboard/Sidebar";
import Calendar from "../components/Dashboard/Calendar";
import { useState, useEffect } from "react";
import axios from "axios";

const AdminCalendar = () => {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [newEvent, setNewEvent] = useState({
    title: "",
    startDate: "",
    endDate: "",
    desc: "",
    startTime: "",
    endTime: "",
  });
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCalendarLoading, setIsCalendarLoading] = useState(false);

  useEffect(() => {
    setUserRole(localStorage.getItem("userRole"));
    fetchEvents();
  }, []);

  // Fetch events from the server and update local state
  const fetchEvents = async () => {
    setIsCalendarLoading(true);
    try {
      const events = await axios.get("https://api.rekonsys.tech/events");
      const formatedEvents = events.data.map((event) => {
        const startDate = new Date(event.start);
        const endDate = new Date(event.end);
        return { ...event, start: new Date(startDate), end: new Date(endDate) };
      });
      setEvents(formatedEvents);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
    setTimeout(() => {
      setIsCalendarLoading(false);
    }, 1000);
  };

  const eventFormValidation = (event) => {
    const requiredFields = [
      "title",
      "desc",
      "startDate",
      "endDate",
      "startTime",
      "endTime",
    ];

    const fieldsTitles = {
      title: "Title",
      desc: "Description",
      startDate: "Start Date",
      endDate: "End Date",
      startTime: "Start Time",
      endTime: "End Time",
    };

    for (let field of requiredFields) {
      if (!event[field]) {
        return {
          isValid: false,
          message: `${fieldsTitles[field]} is required`,
        };
      }
    }

    if (event.endDate < event.startDate){
      return {
        isValid: false,
        message: "End Date must be greater than or equal to Start Date.",
      }
    }

    return { isValid: true };
  };

  //addEvent Method
  const addEvent = async () => {
    const { title, startDate, endDate, startTime, endTime, desc } = newEvent;
    const [startHour, startMinute] = startTime.split(":");
    const [endHour, endMinute] = endTime.split(":");
    const start = new Date(startDate);
    start.setHours(parseInt(startHour));
    start.setMinutes(parseInt(startMinute));
    const end = new Date(endDate);
    end.setHours(parseInt(endHour));
    end.setMinutes(parseInt(endMinute));
    const event = {
      title,
      start,
      end,
      desc,
    };
    // console.log(event);
    try {
      await axios.post("https://api.rekonsys.tech/events/event", event);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  //handleAddEvent  is used to add a new Event to the database
  const handleAddEvent = async () => {
    const validationResult = eventFormValidation(newEvent);
    if (validationResult.isValid) {
      setIsLoading(true);
      try {
        await addEvent();
        await fetchEvents();
        setShowModal(false);
        setIsLoading(false);
        setNewEvent({
          title: "",
          start: "",
          end: "",
          desc: "",
        });
        setError("");
      } catch (error) {
        console.error("error fetching events:", error);
      }
    } else {
      setError(validationResult.message);
    }
  };

  //Handle Update Event
  const handleUpdateEvent = async () => {
    const validationResult = eventFormValidation(selectedEvent);
    if (validationResult.isValid) {
      const { title, startDate, endDate, startTime, endTime, desc, id } =
        selectedEvent;
      const [startHour, startMinute] = startTime.split(":");
      const [endHour, endMinute] = endTime.split(":");
      const start = new Date(startDate);
      start.setHours(parseInt(startHour));
      start.setMinutes(parseInt(startMinute));
      const end = new Date(endDate);
      end.setHours(parseInt(endHour));
      end.setMinutes(parseInt(endMinute));
      const event = {
        title,
        start,
        end,
        desc,
      };
      try {
        const response = await axios.put(
          `https://api.rekonsys.tech/events/edit-event/${id}`,
          event
        );
        fetchEvents();
        // console.log(response)
        setSelectedEvent(null);
        setError("");
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    } else {
      setError(validationResult.message);
    }
  };

  const handleDeleteEvent = async () => {
    const { id } = selectedEvent;
    try {
      const response = await axios.delete(
        `https://api.rekonsys.tech/events/event/${id}`
      );
      fetchEvents();
      // console.log(response);
      setSelectedEvent(null);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const getStartEndTimes = (startTime, endTime) => {
    let startHours = startTime.getHours();
    if (startHours < 10) {
      startHours = "0" + startHours;
    }
    let startMinutes = startTime.getMinutes();
    if (startMinutes < 10) {
      startMinutes = "0" + startMinutes;
    }
    let endHours = endTime.getHours();
    if (endHours < 10) {
      endHours = "0" + endHours;
    }
    let endMinutes = endTime.getMinutes();
    if (endMinutes < 10) {
      endMinutes = "0" + endMinutes;
    }

    const start = `${startHours}:${startMinutes}`;
    const end = `${endHours}:${endMinutes}`;

    return [start, end];
  };

  const handleSelectEvent = (event) => {
    const { start, end } = event;
    const [startTime, endTime] = getStartEndTimes(start, end);
    const selectedEvent = {
      id: event._id,
      title: event.title,
      desc: event.desc,
      startDate: new Date(start).toISOString().split("T")[0],
      endDate: new Date(end).toISOString().split("T")[0],
      startTime,
      endTime,
    };
    setSelectedEvent(selectedEvent);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedEvent(null);
    setNewEvent({
      title: "",
      start: "",
      end: "",
      desc: "",
      startTime: "",
      endTime: "",
    });
    setError("");
  };

  const handleChange = (e, setEvent) => {
    const { id, value } = e.target;
    setEvent((prev) => ({ ...prev, [id]: value }));
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  };

  const popupModal = () => {
    const { title, startDate, endDate, desc, startTime, endTime } = newEvent;
    // console.log(newEvent);
    return (
      <div className="w-screen h-screen flex justify-center items-center fixed top-0 left-1/2 transform -translate-x-1/2 z-50 p-2 mt-4">
        <div className="w-[350px] md:w-[500px] h-100 bg-[white] shadow-[rgba(0,0,0,0.35)_0px_5px_15px] flex flex-col p-[25px] rounded-x">
          {error && (
            <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
              <div
                id="toast-danger"
                className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-red-100  rounded-lg shadow "
                role="alert"
              >
                <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg ">
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
                  </svg>
                  <span className="sr-only">Error icon</span>
                </div>
                <div className="ms-3 text-md font-normal mr-2">{error}</div>
                <button
                  type="button"
                  onClick={() => setError("")}
                  className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 "
                  data-dismiss-target="#toast-danger"
                  aria-label="Close"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
          <div className="flex justify-between mb-4">
            <h1 className="text-xl md:text-3xl pb-4 font-semibold">
              Add Event
            </h1>
            <button
              className="bg-transparent text-[25px] cursor-pointer border-[none] pr-2"
              onClick={handleModalClose}
            >
              X
            </button>
          </div>
          <form>
            <div className="mb-4">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                Title <span className="text-red-700">*</span>
              </label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => handleChange(e, setNewEvent)}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="desc"
                className="block text-sm font-medium text-gray-700"
              >
                Description <span className="text-red-700">*</span>
              </label>
              <input
                type="text"
                id="desc"
                value={desc}
                onChange={(e) => handleChange(e, setNewEvent)}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                required
              />
            </div>
            <div className="w-full flex flex-col md:flex-row gap-5">
              <div className="mb-4 w-full flex flex-col">
                <label
                  htmlFor="startDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Start Date <span className="text-red-700">*</span>
                </label>
                <input
                  type="date"
                  id="startDate"
                  min={getCurrentDate()}
                  value={startDate}
                  onChange={(e) => handleChange(e, setNewEvent)}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>
              <div className="mb-4 w-full flex flex-col">
                <label
                  htmlFor="endDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  End Date <span className="text-red-700">*</span>
                </label>
                <input
                  type="date"
                  id="endDate"
                  min={startDate}
                  value={endDate}
                  onChange={(e) => handleChange(e, setNewEvent)}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-5">
              <div className="mb-4 w-full flex flex-col">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="startTime"
                >
                  Start Time <span className="text-red-700">*</span>
                </label>
                <input
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  id="startTime"
                  type="time"
                  value={startTime}
                  onChange={(e) => handleChange(e, setNewEvent)}
                  required
                />
              </div>
              <div className="mb-4 w-full flex flex-col">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="endTime"
                >
                  End Time <span className="text-red-700">*</span>
                </label>
                <input
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  id="endTime"
                  type="time"
                  value={endTime}
                  onChange={(e) => handleChange(e, setNewEvent)}
                  required
                />
              </div>
            </div>
            <div className="flex row justify-center gap-5 pt-3">
              <button
                type="button"
                onClick={handleModalClose}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-700 focus:outline-none"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleAddEvent}
                className="px-4 py-2 bg-blue-700 text-white rounded-md  focus:outline-none hover:bg-[#063081]"
              >
                {isLoading ? "Creating..." : "Create Event"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const updateEventModal = () => {
    const { title, startDate, endDate, desc, startTime, endTime } =
      selectedEvent;
    return (
      <div className="w-screen h-screen flex justify-center items-center fixed top-0 left-1/2 transform -translate-x-1/2 z-50 p-2 mt-4">
        <div className="w-[350px] md:w-[500px] h-100 bg-[white] shadow-[rgba(0,0,0,0.35)_0px_5px_15px] flex flex-col p-[25px] rounded-x">
          {error && (
            <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
              <div
                id="toast-danger"
                className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-red-100  rounded-lg shadow "
                role="alert"
              >
                <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg ">
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
                  </svg>
                  <span className="sr-only">Error icon</span>
                </div>
                <div className="ms-3 text-md font-normal mr-2">{error}</div>
                <button
                  type="button"
                  onClick={() => setError("")}
                  className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 "
                  data-dismiss-target="#toast-danger"
                  aria-label="Close"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
          <div className="flex justify-between mb-2">
            <h1 className="text-xl md:text-3xl pb-4 font-semibold">
              Update Event
            </h1>
            <button
              className="bg-transparent text-[25px] cursor-pointer border-[none] mr-2"
              onClick={handleModalClose}
            >
              X
            </button>
          </div>
          <form>
            <div className="mb-4">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                Title <span className="text-red-700">*</span>
              </label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => handleChange(e, setSelectedEvent)}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="desc"
                className="block text-sm font-medium text-gray-700"
              >
                Descriprion <span className="text-red-700">*</span>
              </label>
              <input
                type="text"
                id="desc"
                value={desc}
                onChange={(e) => handleChange(e, setSelectedEvent)}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                required
              />
            </div>
            <div className="w-full flex flex-col md:flex-row gap-5">
              <div className="mb-4 w-full flex flex-col">
                <label
                  htmlFor="startDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Start Date <span className="text-red-700">*</span>
                </label>
                <input
                  type="date"
                  id="startDate"
                  min={getCurrentDate()}
                  value={startDate}
                  onChange={(e) => handleChange(e, setSelectedEvent)}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>
              <div className="mb-4 w-full flex flex-col">
                <label
                  htmlFor="endDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  End Date <span className="text-red-700">*</span>
                </label>
                <input
                  type="date"
                  id="endDate"
                  min={startDate}
                  value={endDate}
                  onChange={(e) => handleChange(e, setSelectedEvent)}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>
            </div>
            <div className=" w-full flex flex-col md:flex-row gap-5">
              <div className="mb-4 w-full flex flex-col">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="startTime"
                >
                  Start Time <span className="text-red-700">*</span>
                </label>
                <input
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  id="startTime"
                  type="time"
                  value={startTime}
                  onChange={(e) => handleChange(e, setSelectedEvent)}
                  required
                />
              </div>
              <div className="mb-4 w-full flex flex-col">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="endTime"
                >
                  End Time <span className="text-red-700">*</span>
                </label>
                <input
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  id="endTime"
                  type="time"
                  value={endTime}
                  onChange={(e) => handleChange(e, setSelectedEvent)}
                  required
                />
              </div>
            </div>
            <div className="flex row justify-center gap-5 pt-3">
              <button
                type="button"
                onClick={handleUpdateEvent}
                className="px-4 py-2 bg-blue-700 text-white rounded-md hover:bg-[#063081] focus:outline-none"
              >
                Save
              </button>
              <button
                type="button"
                onClick={handleDeleteEvent}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-700 focus:outline-none"
              >
                Delete
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          {userRole == "admin" && 
          <><BreadCrumbs
            paths={[
              { url: "/admin-dashboard", label: "Home" },
              { url: "/admin-calendar", label: "Calendar" },
              { url: "", label: "Calendar" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/admin-dashboard" },
              { text: "Employees", path: "/employee-list" },
              { text: "Attendance", path: "/attendance-list" },
              { text: "Calendar", path: "/admin-calendar", active: true },
              { text: "Timesheets", path: "/timesheet-list" },
              { text: "Leaves", path: "/leaveslist" },
              { text: "Projects", path: "/projects-list" },
              { text: "Categories", path: "/categories-list" },
              { text: "Roles", path: "/role" },
              { text: "Departments", path: "/department" },
              { text: "Positions", path: "/position" },
              { text: "Reports", path: "/reports" },
            ]}
          /></>}
          {userRole == "hr" && 
          <><BreadCrumbs
            paths={[
              { url: "/hr-dashboard", label: "Home" },
              { url: "/employee-list", label: "Calendar" },
              { url: "", label: "Calendar" },
            ]}
          />
          <Sidebar
            menuItems={[
              { text: "Home", path: "/hr-dashboard" },
              { text: "Employees", path: "/employee-list"},
              { text: "Calendar", path: "/admin-calendar", active: true },
              { text: "Reports", path: "/reports" },
            ]}
          /></>}
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div>
            <header className="bg-white border-gray-300 border-b-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center mb-2">
                <h1 className="text-xl md:text-3xl font-semibold">Calendar</h1>
                <button
                  className="block rounded-md bg-blue-700 px-3 md:px-5 py-2 text-sm font-medium text-white transition hover:bg-[#063081]"
                  onClick={() => setShowModal(true)}
                >
                  {isLoading ? "Creating.." : " Create Event"}
                </button>
              </div>
            </header>
          </div>
          {showModal && popupModal()}
          {selectedEvent && updateEventModal()}
          {isCalendarLoading ? (
            <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
              Loading Calendar, Please Wait
            </div>
          ) : (
            <Calendar
              selectable
              onSelectEvent={handleSelectEvent}
              events={events}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminCalendar;
