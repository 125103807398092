import React from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { BiSort } from "react-icons/bi";

const DataTables = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    setGlobalFilter,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  // console.log(page);

  return (
    <>
      <div className="my-2">
        <div className="mb-2">
          <input
            type="text"
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            className="border p-2 w-full md:w-60 rounded-md"
          />
        </div>
        {page.length > 0 ? (
          <div className="overflow-x-auto rounded-md">
            <table
              {...getTableProps()}
              style={{ width: "100%", whiteSpace: "nowrap" }}
              className="w-full text-sm border-collapse border"
            >
              <thead className="bg-[#dc3545]">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="px-2 py-2 text-center text-white border border-slate-300"
                      >
                        <div className="flex justify-around items-center">
                          <span className="text-md">
                            {column.render("Header")}
                          </span>
                          <span className="flex items-center">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <BiSort />
                              ) : (
                                <BiSort />
                              )
                            ) : (
                              <BiSort />
                            )}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()} className="bg-white">
                {page.map((row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <tr key={rowIndex} {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className="px-2 py-2 text-center border border-slate-200 "
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className="my-3">
              <select
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                className="py-2 px-2 border mr-2 text-center rounded-md "
              >
                {[5, 10, 20, 50, 100, data.length].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize === data.length ? "All" : pageSize}
                  </option>
                ))}
              </select>
              <div className="inline-block text-right mt-2 md:mt-0">
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="px-4 py-2 mr-2 text-white bg-blue-800 hover:bg-[#063081] rounded-md"
                >
                  Previous
                </button>
                <span>
                  Page {pageIndex + 1} of {Math.ceil(data.length / pageSize)}
                </span>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="px-4 py-2 ml-2 text-white bg-blue-800 hover:bg-[#063081] float-right rounded-md"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
            No records found
          </div>
        )}
      </div>
    </>
  );
};

export default DataTables;
