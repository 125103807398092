import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import Select from "react-select";

const AddTimeSheet = () => {
  const id = localStorage.getItem("userID");
  let navigate = useNavigate();

  const [timesheetData, setTimesheetData] = useState({
    project: "",
    hoursWorked: "",
    date: "",
    billable: "",
    description: "",
    task: "",
    category: "",
  });
  const [employeeCode, setEmployeeCode] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getEmployeeById();
  }, [id]);

  //fetching projects from get api
  useEffect(() => {
    fetchProjects();
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("https://api.rekonsys.tech/categories");
      const activeCategories = response.data.filter(
        (category) => category.status === "active"
      );
      setCategories(activeCategories);
      //setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await axios.get("https://api.rekonsys.tech/all-projects");
      //console.log(response.data);
      const activeProjects = response.data.filter(
        (project) => project.status === "active"
      );
      //console.log(activeProjects);
      setProjects(activeProjects);
      //setProjects(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  //options mapping for select menu in project name

  // const options = projects.map((project) => ({
  //  value: project._id,
  //   label: `${project.projectname}`,
  // }));

  // const handleMenuChange = (selectedOption, name) => {
  //   setTimesheetData({
  //     ...timesheetData,
  //     [name]: selectedOption,
  //   });
  // };
  const errorClose = () => {
    setError("");
  };

  const successClose = () => {
    setSuccessMessage("");
  };
  const getEmployeeById = async () => {
    try {
      const res = await axios.get(`https://api.rekonsys.tech/auth/user/${id}`);
      setEmployeeName(`${res.data.firstName} ${res.data.lastName}`);
      setEmployeeCode(res.data.employeeCode);
    } catch (error) {
      console.error("Error fetching user", error);
    }
  };
  // Handler for form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTimesheetData({ ...timesheetData, [name]: value });
  };

  // Function to get current date in "YYYY-MM-DD" format
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    // Add leading zero if month/day is less than 10
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    return `${year}-${month}-${day}`;
  };

  const validateForm = () => {
    const requiredFields = [
      "project",
      "hoursWorked",
      "date",
      "billable",
      "description",
      "task",
      "category",
    ];

    for (const field of requiredFields) {
      if (!timesheetData[field]) {
        return { isValid: false, message: `${field} is required` };
      }
    }
    if (!employeeCode) {
      return { isValid: false, message: `Employee Code is required` };
    }
    if (!employeeName) {
      return { isValid: false, message: `Employee Name is required` };
    }
    return { isValid: true };
  };

  // Handler for form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(timesheetData);
    const validationResult = validateForm();
    if (validationResult.isValid) {
      const formData = {
        ...timesheetData,
        employeeCode: employeeCode,
        employeeName: employeeName,
      };
      setIsLoading(true);

      const res = await axios.post("https://api.rekonsys.tech/timesheet", formData);
      if (res.status == 200) {
        setSuccessMessage(res.data.message);
        // console.log(res.data.message)
        setIsLoading(false);
        setTimeout(() => {
          navigate("/employee-timesheet-list");
        }, 4000);
      } else {
        setError(res.data.message);
        setIsLoading(false);
      }
    } else {
      setError(validationResult.message);
    }
  };

  return (
    <div>
      {successMessage && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-success"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-green-100 rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg ">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              <span className="sr-only">Check icon</span>
            </div>
            <div className="ms-3 text-sm font-normal mx-5">
              {successMessage}
            </div>
            <button
              type="button"
              onClick={successClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
              data-dismiss-target="#toast-success"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      {error && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-danger"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-red-100  rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg ">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="ms-3 text-md font-normal mx-5">{error}</div>
            <button
              type="button"
              onClick={errorClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 "
              data-dismiss-target="#toast-danger"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          <BreadCrumbs
            paths={[
              { url: "/employee-dashboard", label: "Home" },
              { url: "/employee-timesheet-list", label: "Timesheets" },
              { url: "", label: "Add Timesheet" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/employee-dashboard" },
              { text: "Attendance", path: "/employee-attendance-list" },
              { text: "Calender", path: "/employee-calendar" },
              {
                text: "Timesheets",
                path: "/employee-timesheet-list",
                active: true,
              },
              { text: "Leaves", path: "/employee-leaves-list" },
            ]}
          />
        </div>

        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div>
            <header className="bg-white border-gray-300 border-b-2 m-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center">
                <h1 className="text-xl md:text-3xl pb-4 font-semibold">
                  Add Timesheet
                </h1>

                {/* Link to Leave Timesheet
                <Link
                  className="block rounded-md bg-[#6610f2] px-5 py-2.5 text-md font-medium text-white transition hover:bg-teal-700"
                  to="/new-timeSheet"
                >
                  Add Timesheet
                </Link> */}
              </div>
            </header>
          </div>
          <div className="border-b border-gray-900/10 pb-12">
            <form
              onSubmit={handleSubmit}
              className="mt-4 px-4 pb-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 items-center flex-auto"
            >
              {/* Project Name */}
              {/* <div className="sm:col-span-3">
                <label
                  htmlFor="project-name"
                  className="block text-md font-medium leading-6 text-gray-900"
                >
                  Project Name
                </label>
                <div className="mt-2">
                  <input
                    placeholder="Project Name"
                    type="text"
                    name="project"
                    id="project-name"
                    autoComplete="off"
                    className="w-full rounded-lg  border-2 border-gray-300 p-3 text-md"
                    value={timesheetData.project}
                    onChange={handleInputChange}
                  />
                </div>
              </div> */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="project-name"
                  className="block text-md font-medium leading-6 text-gray-900"
                >
                  Project Name
                </label>
                <div className="mt-2">
                  <select
                    name="project"
                    id="project-name"
                    className="w-full rounded-lg  border-2 border-gray-300 p-3 text-md"
                    value={timesheetData.project}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Select a project
                    </option>
                    {projects.map((project, index) => (
                      <option key={index} value={project.projectname}>
                        {project.projectname}
                      </option>
                    ))}
                  </select>
                </div>
                {/**  <Select
                    options={options}
                    name="project"
                    value={timesheetData.project}
                    onChange={(selectedOption) =>
                      handleMenuChange(selectedOption, "project")
                    }
                  />*/}
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="employee-name"
                  className="block text-md font-medium leading-6 text-gray-900"
                >
                  Task
                </label>
                <div className="mt-2">
                  <input
                    placeholder="Task"
                    type="text"
                    name="task"
                    id="employee-name"
                    autoComplete="name"
                    className="w-full rounded-lg  border-2 border-gray-300 p-3 text-md"
                    value={timesheetData.task}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="hours-worked"
                  className="block text-md font-medium leading-3 text-gray-900"
                >
                  Hours Worked
                </label>
                <div className="mt-2">
                  <input
                    placeholder="Hours Worked"
                    type="number"
                    name="hoursWorked"
                    id="hours-worked"
                    autoComplete="off"
                    className="w-full rounded-lg  border-2 border-gray-300 p-3 text-md"
                    value={timesheetData.hoursWorked}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="date"
                  className="block text-md font-medium leading-3 text-gray-900"
                >
                  Date
                </label>
                <div className="mt-2">
                  <input
                    placeholder="Date"
                    type="date"
                    name="date"
                    id="date"
                    autoComplete="off"
                    className="w-full rounded-lg  border-2 border-gray-300 p-3 text-md"
                    value={timesheetData.date}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label className="block text-md font-medium leading-3 text-gray-900">
                  Billable
                </label>
                <div className="mt-2">
                  <select
                    id="isBillable"
                    name="billable"
                    value={timesheetData.billable}
                    onChange={handleInputChange}
                    className="w-full rounded-lg  border-2 border-gray-300 p-3 text-md"
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    <option value="yes">Billable</option>
                    <option value="no">Non billable</option>
                  </select>
                </div>
              </div>
              <div className="sm:col-span-3">
                <label className="block text-md font-medium leading-3 text-gray-900">
                  Category :
                </label>
                <div className="mt-2">
                  <select
                    name="category"
                    value={timesheetData.category}
                    onChange={handleInputChange}
                    className="w-full rounded-lg  border-2 border-gray-300 p-3 text-md"
                  >
                    <option value="" disabled>
                      Select an option
                    </option>

                    {categories.map((category, index) => (
                      <option
                        key={index}
                        value={category.categoryName}
                        style={{ color: category.color }}
                      >
                        {category.categoryName}
                      </option>
                    ))}
                    {/* <option value='frontend'>frontend</option>
          {categories.map((category) => (
          <option key={category._id} value={category._id}>
            {category.categoryName} - {category.color} - {category.status}
          </option>
        ))}*/}
                  </select>
                </div>
              </div>

              {/* Notes */}
              <div className="col-span-full">
                <label
                  htmlFor="discription"
                  className="block text-md font-medium leading-6 text-gray-900"
                >
                  description
                </label>
                <div className="mt-2">
                  <textarea
                    placeholder="description"
                    name="description"
                    id="discription"
                    rows="3"
                    className="w-full rounded-lg  border-2 border-gray-300 p-3 text-md"
                    value={timesheetData.description}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>
              <div className="flex justify-center col-span-full">
                <button className="block rounded-md bg-blue-700 hover:bg-[#063081] px-5 py-2.5 text-sm font-medium text-white transition">
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTimeSheet;
