import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

function ResetPassword() {
  const {id, token} = useParams()
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState('');
  const [showSuccess, setShowSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password) {
      setShowAlert("Please enter a password.");
      return; // Exit the function if validation fails
    }

    // Perform the logic to send the password reset password
    const res = await axios.post(
      `https://api.rekonsys.tech/auth/reset-password/${id}/${token}`,
      {password: password}
    );
    
    // For demonstration purposes, let's just show the alert
    res.status == 200 ? setShowSuccess("Paasword reset successfully.") : setShowSuccess("");

    // You can add additional logic here to send the password and handle success/failure
  };

  useEffect(() => {
    // Hide the alert after 3 seconds
    const timeout = setTimeout(() => {
      setShowAlert(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [showAlert]);

  return (
    <div>
      {showSuccess && (
        <div className="fixed top-0 left-1/2 transform -translate-x-1/2 w-1/4 z-50 p-2 mt-4">
          <div
            className="flex items-center w-full max-w-xs p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white divide-x rtl:divide-x-reverse divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 mt-4"
            role="alert"
          >
            <svg
              className="w-5 h-5 text-blue-600 dark:text-blue-500 rotate-45"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9"
              />
            </svg>
            <div className="ps-4 text-sm font-normal">
              {showSuccess}
            </div>
          </div>
        </div>
      )}
      {showAlert && (
        <div className="fixed top-0 left-1/2 transform -translate-x-1/2 w-1/4 z-50 p-2 mt-4">
          <div
            className="flex items-center w-full max-w-xs p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white divide-x rtl:divide-x-reverse divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 mt-4"
            role="alert"
          >
            <svg
              className="w-5 h-5 text-blue-600 dark:text-blue-500 rotate-45"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9"
              />
            </svg>
            <div className="ps-4 text-sm font-normal">
              {showAlert}
            </div>
          </div>
        </div>
      )}

      <section className="bg-gray-50 ">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
          <Link to="/" className="flex items-center mb-6 text-2xl font-semibold text-gray-900">
            <img className="w-8 h-8 mr-2" src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=110,fit=crop,q=95/dJoB8GyOMNfKEGN3/1699858533334-Aq26ZoR9X1Irwyok.jpg" alt="logo" />
            REKONSYS
          </Link>
          <div className="w-full p-6 bg-white rounded-lg shadow md:mt-0 sm:max-w-md  sm:p-8">
            <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              Reset Password
            </h2>
            <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="reset password"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
              >
                Reset
              </button>
              <div className="mt-4 text-sm text-gray-700">
                <p>
                  Remember your password?{' '}
                  <Link to="/login" className="text-blue-600 hover:underline">
                    Login
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ResetPassword;
