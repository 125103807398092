import React from "react";
import { Link } from "react-router-dom";


function Sidebar({ menuItems }) {
  return (
    <div>
      <div className=" h-auto border bg-white shadow-xl rounded-lg hidden lg:block">
        <div className="h-full px-3 py-4 overflow-y-auto">
          <ul className="space-y-2 font-medium">
            {menuItems.map((menuItem, index) => (
              <li key={index}>
                <Link
                  to={menuItem.path}
                  className={`flex items-center p-3 pl-4 rounded-lg group text-[14px] ${
                    menuItem.active
                      ? "text-gray-900 bg-[#f1bd06]"
                      : "bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-gray-900"
                  }`}
                >
                  {menuItem.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
