import React, { useState } from "react";
import Navbar from "../components/Dashboard/navbar";
import Sidebar from "../components/Dashboard/Sidebar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddPosition = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [position, setPosition] = useState({
    name: "",
    status: "active",
    backgroundColor: "#ffffff", // Default background color
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");

  const validateForm = () => {
    const requiredFields = ["name", "status", "backgroundColor"];
    for (const field of requiredFields) {
      if (!position[field]) {
        setTimeout(() => {
          setError("");
        }, 3000);

        return { isValid: false, message: `${field} is required` };
      }
    }
    return { isValid: true };
  };

  const handleChange = (e) => {
    setPosition({
      ...position,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    const validationResult = validateForm();

    e.preventDefault();

    if (validationResult.isValid) {
      setIsLoading(true);
    try {
      const res = await axios.post("https://api.rekonsys.tech/position", {
        name: position.name,
        status: position.status,
        backgroundColor: position.backgroundColor,
      });

      if (res.status === 201) {
        setSuccessMessage(res.data.message);
        setIsLoading(false);
        setTimeout(() => {
          navigate("/position");
        }, 4000);
      } else {
        setError(res.data.error);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setError("An error occurred while submitting the form.");
    } finally {
      setIsLoading(false);
    }
  }else {
    // Handle form validation error
    setError(validationResult.message);
  }
  };

  const handleCancel = () => {
    navigate("/position");
  };

  const successClose = () => {
    setSuccessMessage("");
  };

  const errorClose = () => {
    setError("");
  };

  return (
    <div>
      {/* Success and error message alerts */}
      {successMessage && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-success"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-green-100 rounded-lg shadow"
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              <span className="sr-only">Check icon</span>
            </div>
            <div className="ms-3 text-md font-normal mx-5">
              {successMessage}
            </div>
            <button
              type="button"
              onClick={successClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
              data-dismiss-target="#toast-success"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      {error && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-danger"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-red-100 rounded-lg shadow"
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="ms-3 text-md font-normal mx-5">{error}</div>
            <button
              type="button"
              onClick={errorClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
              data-dismiss-target="#toast-danger"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      {/* Navbar */}
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          {/* Breadcrumbs */}
          <BreadCrumbs
            paths={[
              { url: "/admin-dashboard", label: "Home" },
              { url: "/position", label: "Position" },
              { url: "", label: "Add Position" },
            ]}
          />
          {/* Sidebar */}
          <Sidebar
            menuItems={[
              { text: "Home", path: "/admin-dashboard" },
              { text: "Employees", path: "/employee-list" },
              { text: "Attendance", path: "/attendance-list" },
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Timesheets", path: "/timesheet-list" },
              { text: "Leaves", path: "/leaveslist" },
              { text: "Projects", path: "/projects-list" },
              { text: "Categories", path: "/categories-list" },
              { text: "Roles", path: "/role" },
              { text: "Departments", path: "/department" },
              { text: "Positions", path: "/position", active: true },
              { text: "Reports", path: "/reports" },
            ]}
          />
        </div>

        {/* Main content */}
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div>
            {/* Page header */}
            <header className="bg-white border-gray-300 border-b-2 m-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center">
                <h1 className="text-xl md:text-3xl pb-4 font-semibold">
                  Add Position
                </h1>
              </div>
            </header>
          </div>
          <div>
            {/* Form */}
            <form
              className="mt-4 px-4 leading-relaxed flex flex-col gap-3 text-gray-700"
              onSubmit={handleSubmit}
            >
              <div className="w-full flex flex-col md:flex-row gap-5">
                {/* Position Name input */}
                <div className="w-full flex flex-col">
                  <label>Position Name:</label>
                  <input
                    className="w-full rounded-lg border-2 border-gray-300 p-3 text-sm"
                    type="text"
                    name="name"
                    value={position.name}
                    onChange={handleChange}
                  />
                </div>
                {/* Status selection */}
                <div className="w-full flex flex-col">
                  <label>Status:</label>
                  <select
                    className="w-full rounded-lg border-2 border-gray-300 p-3 text-sm"
                    name="status"
                    value={position.status}
                    onChange={handleChange}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
              </div>
              {/* Background color input */}
              <div className="w-full flex flex-col">
                <label>Background Color:</label>
                <input
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full md:w-1/2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                  type="color"
                  name="backgroundColor"
                  value={position.backgroundColor}
                  onChange={handleChange}
                />
              </div>
              {/* Submit and cancel buttons */}
              <div className="flex row justify-center gap-5 pt-3">
                <button
                  className="block rounded-md bg-[#00008B] px-5 py-2.5 text-sm font-medium text-white transition hover:bg-[#000435]"
                  type="submit"
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
                <button
                  className="block rounded-md bg-red-500 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-[#8B0000]"
                  type="button"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPosition;
