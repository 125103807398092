import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";

const EditEmployee = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    password: "",
    gender: "",
    dob: "",
    contactNo: "",
    bloodGroup: "",
    emergencyContactNo: "",
    hobbies: "",
    permanentAddress: "",
    presentAddress: "",
    dateOfJoining: "",
    employeeCode: "",
    employeeDocs: "",
    deviceToken: "",
    role: "",
    position: "",
    department: "",
    educationData: [{}],
    workExperienceData: [{}],
    bankAccountHolderName: "",
    bankAccountName: "",
    bankAccountNumber: "",
    IFSCCode: "",
    PANcardNo: "",
    UAN: "",
    permanentPresentSame: false,
  });
  const [roles, setRoles] = useState([]);
  const [department, setDepartment] = useState([]);
  const [position, setPosition] = useState([]);
  const [error, setError] = useState("");
  const [resetDeviceToken, setResetDeviceToken] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [file, setFile] = useState(null);


  const [userRole, setUserRole] = useState('');
  useEffect(() => {
    setUserRole(localStorage.getItem("userRole"));
    getEmployeeById();
  }, [id]);

  const getEmployeeById = async () => {
    try {
      const response = await axios.get(`https://api.rekonsys.tech/auth/user/${id}`);
      setFormData(response.data);
      setFormData((prevData) => ({
        ...prevData,
        dob: new Date(response.data.dob).toISOString().split("T")[0],
        dateOfJoining: new Date(response.data.dateOfJoining)
          .toISOString()
          .split("T")[0],
        educationData: response.data.educationData,
        workExperienceData: response.data.workExperienceData,
      }));
    } catch (error) {
      console.error("Error fetching user", error);
    }
  };
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get("https://api.rekonsys.tech/role-list");
        const activeRoles = response.data.filter(
          (role) => role.status === "active"
        );

        setRoles(activeRoles); // Update roles state with only active roles
      } catch (error) {
        console.error("Error fetching roles:", error);
        // Handle error if any
      }
    };

    fetchRoles(); // Call the fetchRoles function
  }, []);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(
          "https://api.rekonsys.tech/department-list"
        );
        const activeDepartments = response.data.filter(
          (department) => department.status === "active"
        );
        setDepartment(activeDepartments); // Update departments state with only active departments
      } catch (error) {
        console.error("Error fetching departments:", error);
        // Handle error if any
      }
    };

    fetchDepartments(); // Call the fetchDepartments function
  }, []);

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await axios.get("https://api.rekonsys.tech/position-list");
        const activePositions = response.data.filter(
          (position) => position.status === "active"
        );
        setPosition(activePositions); // Update positions state with only active positions
      } catch (error) {
        console.error("Error fetching positions:", error);
        // Handle error if any
      }
    };

    fetchPositions(); // Call the fetchPositions function
  }, []);

  const validateForm = () => {
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "password",
      "gender",
      "dob",
      "contactNo",
      "bloodGroup",
      "permanentAddress",
      "presentAddress",
      "dateOfJoining",
      "employeeCode",
      "role",
      "position",
      "department",
      "bankAccountHolderName",
      "bankAccountName",
      "bankAccountNumber",
      "IFSCCode",
      "PANcardNo",
      "UAN",
    ];

    const fieldsTitles = {
      "firstName": "First Name",
      "lastName": "Last Name",
      "email":"Email",
      "password":"Password",
      "gender":"Gender",
      "dob": "DOB",
      "contactNo":"Contact No",
      "bloodGroup":"Blood Group",
      "permanentAddress":"Permanent Address",
      "presentAddress":"Present Address",
      "dateOfJoining":"Date Of Joining",
      "role":"Role",
      "position":"Position",
      "department":"Department",
      "bankAccountHolderName":"Bank Account Holder Name",
      "bankAccountName":"Bank Account Name",
      "bankAccountNumber":"Bank Account Number",
      "IFSCCode":"IFSC Code",
      "PANcardNo" : "Pan Card No",
      "UAN":"UAN",
    };

    if (formData["contactNo"] && formData.contactNo.length < 10) {
      return {
        isValid: false,
        message: "Contact number cannot be less than 10 digits",
      };
    } else if (
      formData["emergencyContactNo"] &&
      formData.emergencyContactNo.length < 10
    ) {
      return {
        isValid: false,
        message: "Emergency contact number cannot be less than 10 digits",
      };
    }

    for (const field of requiredFields) {
      if (!formData[field]) {
        return { isValid: false, message: `${[fieldsTitles[field]]} is required` };
      }
    }

    if (formData.educationData.length === 0) {
      return { isValid: false, message: "Education details are required" };
    }

    return { isValid: true };
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
      presentAddress:
        name === "permanentPresentSame" && checked
          ? prevFormData.permanentAddress
          : prevFormData.presentAddress,
    }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setFormData((prevFormData) => ({
      ...prevFormData,
      employeeDocs: "",
    }));
  };

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    // Allow backspace and numeric input
    if (/^\d*$/.test(value) || value === "") {
      // Limit input to 10 digits
      if (value.length <= 10) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    }
  };

  const handleUserNameChange = (event) => {
    const { name, value } = event.target;
    const NewUserName = value.charAt(0).toUpperCase() + value.slice(1);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: NewUserName,
    }));
  };

  const handlePresentAddressChange = (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      presentAddress: value,
    }));
  };

  const handleInputChange = (index, fieldName, value, dataKey) => {
    const newData = [...formData[dataKey]];

    if (fieldName === "passingOfYear") {
      // Limit input to 4 digits if value is numeric
      if (/^\d{0,4}$/.test(value)) {
        newData[index][fieldName] = value; // Assign value
        setFormData({ ...formData, [dataKey]: newData }); // Update form data
      }
    } else {
      // For fields other than "passingOfYear", allow any input
      newData[index][fieldName] = value; // Assign value
      setFormData({ ...formData, [dataKey]: newData }); // Update form data
    }
  };

  const errorClose = () => {
    setError("");
  };
  const resetClose = () => {
    setResetDeviceToken(false);
  };
  const handleResetDeviceToken = () => {
    setResetDeviceToken(true);
    setFormData((prevFormData) => ({
      ...prevFormData,
      deviceToken: "newuser",
    }));
    setTimeout(() => {
      setResetDeviceToken(false);
    }, 5000);
  };
  const successClose = () => {
    setSuccessMessage("");
  };
  const addField = (dataKey) => {
    setFormData({
      ...formData,
      [dataKey]: [...formData[dataKey], {}],
    });
  };

  const removeField = (index, dataKey) => {
    const newData = [...formData[dataKey]];
    newData.splice(index, 1);
    setFormData({
      ...formData,
      [dataKey]: newData,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationResult = validateForm();
    if (validationResult.isValid) {
      // console.log("Form is valid, submitting data:", formData);
//loading page
      const data = new FormData();
      data.append("file", file);
      data.append("firstName", formData.firstName);
      data.append("middleName", formData.middleName);
      data.append("lastName", formData.lastName);
      data.append("email", formData.email);
      data.append("password", formData.password);
      data.append("gender", formData.gender);
      data.append("dob", formData.dob);
      data.append("contactNo", formData.contactNo);
      data.append("bloodGroup", formData.bloodGroup);
      data.append("emergencyContactNo", formData.emergencyContactNo);
      data.append("hobbies", formData.hobbies);
      data.append("permanentAddress", formData.permanentAddress);
      data.append("presentAddress", formData.presentAddress);
      data.append("dateOfJoining", formData.dateOfJoining);
      data.append("role", formData.role);
      data.append("position", formData.position);
      data.append("department", formData.department);
      data.append("educationData", JSON.stringify(formData.educationData));
      data.append("workExperienceData", JSON.stringify(formData.workExperienceData));
      data.append("bankAccountHolderName", formData.bankAccountHolderName);
      data.append("bankAccountName", formData.bankAccountName);
      data.append("bankAccountNumber", formData.bankAccountNumber);
      data.append("IFSCCode", formData.IFSCCode);
      data.append("PANcardNo", formData.PANcardNo);
      data.append("UAN", formData.UAN);
      data.append("remainingLeaves", formData.remainingLeaves);

      const res = await axios.put(
        `https://api.rekonsys.tech/auth/edit-user/${id}`,
        data
      );
      setIsLoading(true);
      if (res.status == 200) {
        setSuccessMessage(res.data.message);
        setIsLoading(false);
        setTimeout(() => {
          if (localStorage.getItem("userRole") === "admin") {
            navigate("/admin-dashboard");
          } else if (localStorage.getItem("userRole") === "employee") {
            navigate("/employee-dashboard");
          }
        }, 2000);
      } else {
        setError(res.data.message);
      }
    } else {
      setError(validationResult.message);
    }
  };

  const handleResetEdit = () => {
    getEmployeeById();
    setSuccessMessage("User data reseted Successfully...");
  };

  const handleCancelRoute = () => {
    navigate(`/employee-list`);
  };

  return (
    <div>
      {successMessage && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-success"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-green-100 rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg ">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <span className="sr-only">Check icon</span>
            </div>
            <div className="ms-3 text-sm font-normal mx-5">
              {successMessage}
            </div>
            <button
              type="button"
              onClick={successClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
              data-dismiss-target="#toast-success"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      {resetDeviceToken && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-success"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-green-100 rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg ">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <span className="sr-only">Check icon</span>
            </div>
            <div className="ms-3 text-sm font-normal mx-5">
              Employee Device token has been reseted
            </div>
            <button
              type="button"
              onClick={resetClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
              data-dismiss-target="#toast-success"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      {error && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-danger"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-red-100  rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg ">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="ms-3 text-md font-normal mx-5">{error}</div>
            <button
              type="button"
              onClick={errorClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 "
              data-dismiss-target="#toast-danger"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
        {userRole == "admin" &&
          <>
          <BreadCrumbs
            paths={[
              { url: "/admin-dashboard", label: "Home" },
              { url: "/employee-list", label: "Employees" },
              { url: "", label: "Employees" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/admin-dashboard" },
              { text: "Employees", path: "/employee-list" , active: true},
              { text: "Attendance", path: "/attendance-list" },
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Timesheets", path: "/timesheet-list" },
              { text: "Leaves", path: "/leaveslist" },
              { text: "Projects", path: "/projects-list" },
              { text: "Categories", path: "/categories-list"},
              { text: "Roles", path: "/role" },
              { text: "Departments", path: "/department" },
              { text: "Positions", path: "/position" },
              { text: "Reports", path: "/reports" },
            ]}
          />
          </>}
          {userRole == "hr" &&
          <>
          <BreadCrumbs
            paths={[
              { url: "/hr-dashboard", label: "Home" },
              { url: "/employee-list", label: "Employees" },
              { url: "", label: "Employees" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/hr-dashboard" },
              { text: "Employees", path: "/employee-list" , active: true},
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Reports", path: "/reports" },
            ]}
          /> </>}
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div>
            <header className="bg-white border-gray-300 border-b-2 m-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center">
                <h1 className="text-xl md:text-3xl pb-4 font-semibold">
                  Edit Employee
                </h1>
              </div>
            </header>
          </div>
          <div>
            <form onSubmit={handleSubmit} className="space-y-4" novalidate>
              {/* Basic details */}
              <details
                className="group [&_summary::-webkit-details-marker]:hidden"
                open
              >
                <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                  <h2 className="font-medium">Basic Details</h2>

                  <svg
                    className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </summary>

                <div className="mt-4 px-4 leading-relaxed flex flex-col gap-3 text-gray-700">
                  <div className="w-full flex flex-col md:flex-row gap-5">
                    <div className="w-full flex flex-col">
                      <label>
                        First Name <span className="text-red-700">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="First Name"
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleUserNameChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>Middle Name</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Middle Name"
                        type="text"
                        name="middleName"
                        value={formData.middleName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>
                        Last Name <span className="text-red-700">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Last Name"
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleUserNameChange}
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row gap-5">
                    <div className="w-full flex flex-col">
                      <label>
                        Email: <span className="text-red-700">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Email"
                        type="mail"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>
                        Password: <span className="text-red-700">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="w-full flex flex-col md:flex-row gap-5">
                    <div className="w-full flex flex-col">
                      <label>
                        Gender: <span className="text-red-700">*</span>
                      </label>
                      <select
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <div className="w-full flex flex-col">
                      <label>
                        Date of Birth: <span className="text-red-700">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Select Date of Birth"
                        type="date"
                        name="dob"
                        max={new Date().toISOString().split("T")[0]}
                        value={formData.dob}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>
                        Contact: <span className="text-red-700">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Contact"
                        type="text"
                        name="contactNo"
                        maxLength={10}
                        value={formData.contactNo}
                        onChange={handleContactChange}
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row gap-5">
                    <div className="w-full flex flex-col">
                      <label>
                        Blood Group: <span className="text-red-700">*</span>
                      </label>
                      <select
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        name="bloodGroup"
                        value={formData.bloodGroup}
                        onChange={handleChange}
                      >
                        <option value="">Select Blood Group</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                      </select>
                    </div>
                    <div className="w-full flex flex-col">
                      <label>Emergency Contact No:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Emergency Contact No"
                        type="text"
                        name="emergencyContactNo"
                        maxLength={10}
                        onChange={handleContactChange}
                        value={formData.emergencyContactNo}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>Hobbies:</label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Hobbies"
                        type="text"
                        name="hobbies"
                        value={formData.hobbies}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row gap-5">
                    <div className="w-full flex flex-col">
                      <label>
                        Permanent Address:
                        <span className="text-red-700">*</span>
                      </label>
                      <textarea
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Permanent Address"
                        name="permanentAddress"
                        value={formData.permanentAddress}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>
                        Present Address: <span className="text-red-700">*</span>
                      </label>
                      <textarea
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Present Address"
                        name="presentAddress"
                        value={formData.presentAddress}
                        onChange={handlePresentAddressChange}
                        disabled={formData.permanentPresentSame}
                      />
                    </div>
                    <div className="w-full flex items-center mt-5">
                      <input
                        type="checkbox"
                        id="permanentPresentSame"
                        name="permanentPresentSame"
                        checked={formData.permanentPresentSame}
                        onChange={handleChange}
                      />
                      <label htmlFor="permanentPresentSame" className="ml-2">
                        Same as Permanent Address
                      </label>
                    </div>
                  </div>
                </div>
              </details>
              {/* employee details */}
              <details
                className="group [&_summary::-webkit-details-marker]:hidden"
                open
              >
                <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                  <h2 className="font-medium">Employee Details</h2>

                  <svg
                    className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </summary>
                <div className="mt-4 px-4 leading-relaxed flex flex-col gap-3 text-gray-700">
                  <div className="w-full flex flex-col md:flex-row gap-5">
                    <div className="w-full flex flex-col">
                      <label>
                        Date of Joining: <span className="text-red-700">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Select Date of Joining"
                        type="date"
                        name="dateOfJoining"
                        value={formData.dateOfJoining}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="w-full flex flex-col">
                      <label>
                        Employee Code: <span className="text-red-700">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Employee Code"
                        type="text"
                        name="employeeCode"
                        value={formData.employeeCode}
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="w-full flex flex-col md:flex-row gap-5">
                    <div className="w-full flex flex-col">
                      <label>
                        Role: <span className="text-red-700">*</span>
                      </label>
                      <select
                        className="w-full rounded-lg border-2 border-gray-300 p-3 text-sm"
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                      >
                        <option value="">Select Role</option>
                        {roles.map((role) => (
                          <option key={role._id} value={role.name}>
                            {role.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="w-full flex flex-col">
                      <label>
                        Position: <span className="text-red-700">*</span>
                      </label>
                      <select
                        className="mt-1 w-full rounded-lg border-2 border-gray-300 p-3 text-sm"
                        name="position"
                        value={formData.position}
                        onChange={handleChange}
                      >
                        <option value="">Select Position</option>
                        {position.map((pos) => (
                          <option key={pos._id} value={pos.name}>
                            {pos.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="w-full flex flex-col">
                      <label>
                        Department: <span className="text-red-700">*</span>
                      </label>
                      <select
                        className="w-full rounded-lg border-2 border-gray-300 p-3 text-sm"
                        name="department"
                        value={formData.department}
                        onChange={handleChange}
                      >
                        <option value="">Select Department</option>
                        {department.map((department) => (
                          <option key={department._id} value={department.name}>
                            {department.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </details>
              {/* banking details  */}
              <details className="group [&_summary::-webkit-details-marker]:hidden">
                <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                  <h2 className="font-medium">Banking Details</h2>

                  <svg
                    className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </summary>

                <div className="mt-4 px-4 leading-relaxed flex flex-col gap-3 text-gray-700">
                  <div className="w-full flex flex-col md:flex-row gap-5">
                    <div className="w-full flex flex-col">
                      <label>
                        Bank Account Holder Name:
                        <span className="text-red-700">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Bank Account Number"
                        type="text"
                        name="bankAccountHolderName"
                        value={formData.bankAccountHolderName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>
                        Bank Name: <span className="text-red-700">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Bank Account Name"
                        type="text"
                        name="bankAccountName"
                        value={formData.bankAccountName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row gap-5">
                    <div className="w-full flex flex-col">
                      <label>
                        Bank Account Number:
                        <span className="text-red-700">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Bank Account Number"
                        type="number"
                        name="bankAccountNumber"
                        value={formData.bankAccountNumber}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>
                        IFSC Code: <span className="text-red-700">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="IFSC Code"
                        type="text"
                        name="IFSCCode"
                        value={formData.IFSCCode}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row gap-5">
                    <div className="w-full flex flex-col">
                      <label>
                        PAN Card: <span className="text-red-700">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Enter PAN Card Number"
                        type="text"
                        name="PANcardNo"
                        value={formData.PANcardNo}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <label>
                        UAN: <span className="text-red-700">*</span>
                      </label>
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="Enter UAN Number"
                        type="number"
                        name="UAN"
                        value={formData.UAN}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </details>
              {/* education details */}
              <details className="group [&_summary::-webkit-details-marker]:hidden">
                <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                  <h2 className="font-medium">Education Details</h2>

                  <svg
                    className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </summary>

                <div className="mt-4 px-4 leading-relaxed flex flex-col gap-3 text-gray-700">
                  <div>
                    {formData.educationData.map((education, index) => (
                      <div
                        className="mt-3 px-1 leading-relaxed flex flex-col gap-3 text-gray-700"
                        key={index}
                      >
                        <div className="w-full flex justify-between gap-5">
                          <label className="text-md font-medium">
                            Education {index + 1}:
                          </label>
                          <button
                            type="button"
                            className="rounded-md bg-red-500 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-red-700"
                            onClick={() => removeField(index, "educationData")}
                          >
                            Remove
                          </button>
                        </div>
                        <div className="w-full flex flex-col gap-5">
                          <div className="w-full flex flex-col md:flex-row gap-5">
                            <div className="w-full flex flex-col">
                              <label>
                                School/University
                                <span className="text-red-700">*</span>
                              </label>

                              <input
                                type="text"
                                className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                                value={education.schoolUniversity || ""}
                                name="schoolUniversity"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "schoolUniversity",
                                    e.target.value,
                                    "educationData"
                                  )
                                }
                                placeholder="School/University"
                              />
                            </div>
                            <div className="w-full flex flex-col">
                              <label>
                                Degree <span className="text-red-700">*</span>
                              </label>

                              <input
                                type="text"
                                className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                                value={education.degree || ""}
                                name="degree"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "degree",
                                    e.target.value,
                                    "educationData"
                                  )
                                }
                                placeholder="Degree"
                              />
                            </div>
                          </div>
                          <div className="w-full flex flex-col md:flex-row gap-5">
                            <div className="w-full flex flex-col">
                              <label>
                                Passing Of Year
                                <span className="text-red-700">*</span>
                              </label>

                              <input
                                type="text"
                                className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                                value={education.passingOfYear || ""}
                                name="passingOfYear"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "passingOfYear",
                                    e.target.value,
                                    "educationData"
                                  )
                                }
                                placeholder="Passing Of Year"
                              />
                            </div>
                            <div className="w-full flex flex-col">
                              <label>
                                CGPA/Percentage
                                <span className="text-red-700">*</span>
                              </label>

                              <input
                                type="number"
                                className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                                value={education.cgpa || ""}
                                name="cgpa"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "cgpa",
                                    e.target.value,
                                    "educationData"
                                  )
                                }
                                placeholder="CGPA/Percentage"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="rounded-md bg-green-500 my-4 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-green-700"
                      onClick={() => addField("educationData")}
                    >
                      Add Education
                    </button>
                  </div>
                </div>
              </details>
              {/* work Experience details */}
              <details className="group [&_summary::-webkit-details-marker]:hidden">
                <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                  <h2 className="font-medium">Work Experience</h2>

                  <svg
                    className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </summary>

                <div className="mt-4 px-4 leading-relaxed flex flex-col gap-3 text-gray-700">
                  <div>
                    {formData.workExperienceData.map((work, index) => (
                      <div
                        className="mt-3 px-1 leading-relaxed flex flex-col gap-3 text-gray-700"
                        key={index}
                      >
                        <div className="w-full flex justify-between gap-5">
                          <label className="text-md font-medium">
                            Work Experience {index + 1}:
                          </label>
                          <button
                            type="button"
                            className="rounded-md bg-red-500 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-red-700"
                            onClick={() =>
                              removeField(index, "workExperienceData")
                            }
                          >
                            Remove
                          </button>
                        </div>
                        <div className="w-full flex flex-col gap-5">
                          <div className="w-full flex flex-col md:flex-row gap-5">
                            <div className="w-full flex flex-col">
                              <label>
                                Company Name
                                <span className="text-red-700">*</span>
                              </label>

                              <input
                                type="text"
                                className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                                value={work.companyName || ""}
                                name="companyName"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "companyName",
                                    e.target.value,
                                    "workExperienceData"
                                  )
                                }
                                placeholder="Company Name"
                              />
                            </div>
                            <div className="w-full flex flex-col">
                              <label>
                                Designation
                                <span className="text-red-700">*</span>
                              </label>

                              <input
                                type="text"
                                className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                                value={work.designation || ""}
                                name="designation"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "designation",
                                    e.target.value,
                                    "workExperienceData"
                                  )
                                }
                                placeholder="Designation"
                              />
                            </div>
                          </div>
                          <div className="w-full flex flex-col md:flex-row gap-5">
                            <div className="w-full flex flex-col">
                              <label>
                                Start Date
                                <span className="text-red-700">*</span>
                              </label>
                              <input
                                className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                                placeholder="Select Start Date"
                                type="date"
                                value={work.startDate || ""}
                                max={formData.dateOfJoining}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "startDate",
                                    e.target.value,
                                    "workExperienceData"
                                  )
                                }
                              />
                            </div>
                            <div className="w-full flex flex-col">
                              <label>
                                End Date <span className="text-red-700">*</span>
                              </label>
                              <input
                                className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                                placeholder="Select End Date"
                                type="date"
                                value={work.endDate || ""}
                                min={work.startDate}
                                max={formData.dateOfJoining}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "endDate",
                                    e.target.value,
                                    "workExperienceData"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="rounded-md bg-green-500 my-4 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-green-700"
                      onClick={() => addField("workExperienceData")}
                    >
                      Add Work Experience
                    </button>
                  </div>
                </div>
              </details>
              {/* File upload */}
              <details className="group [&_summary::-webkit-details-marker]:hidden">
                <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                  <h2 className="font-medium">Upload Documents</h2>

                  <svg
                    className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </summary>

                <div className="mt-4 leading-relaxed flex flex-col gap-3 text-gray-700">
                  <div>
                    <div className="w-full flex flex-col md:flex-row gap-5">
                      <div className="w-full flex flex-col">
                        {formData.employeeDocs}
                        <label>
                          Upload File
                          <span className="text-red-700">*</span>
                        </label>
                        <input
                          className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                          placeholder="Select Start Date"
                          type="file"
                          accept="application/pdf"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </details>
              <div className="flex row justify-center gap-2 md:gap-5">
                <button
                  className="block rounded-md bg-blue-700 px-3 md:px-5 py-2.5  text-sm font-medium text-white transition hover:bg-[#063081]"
                  type="submit"
                >
                  {isLoading ? "Submitting...." : "Submit"}
                </button>
                <button
                  onClick={handleResetEdit}
                  className="block rounded-md bg-red-500 px-3 md:px-5 py-2.5 text-sm font-medium text-white transition hover:bg-red-700"
                  type="button"
                >
                  Reset
                </button>
                <button
                  onClick={handleResetDeviceToken}
                  className="block rounded-md bg-red-500 px-3 md:px-5 py-2.5 text-sm font-medium text-white transition hover:bg-red-700"
                  type="button"
                >
                  Reset Device
                </button>
                <button
                  onClick={handleCancelRoute}
                  className="block rounded-md bg-gray-600 px-3 md:px-5 py-2.5 text-sm font-medium text-white transition hover:bg-gray-700"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEmployee;
