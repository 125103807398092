import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AddLeave = () => {
  const id = localStorage.getItem("userID");
  let navigate = useNavigate();
  const [leaveData, setLeaveData] = useState({
    leavetype: "",
    fromDate: "",
    toDate: "",
    reasonForLeave: "",
    day: "",
    noOfLeaves: 0,
    status: "pending",
  });
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [employeeRemainingLeaves, setEmployeeRemainingLeaves] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getEmployeeById();
  }, [id]);

  const getEmployeeById = async () => {
    try {
      const res = await axios.get(`https://api.rekonsys.tech/auth/user/${id}`);
      setEmployeeEmail(res.data.email);
      setEmployeeCode(res.data.employeeCode);
      setEmployeeName(res.data.firstName + res.data.lastName);
      setEmployeeRemainingLeaves(res.data.remainingLeaves);
    } catch (error) {
      console.error("Error fetching user", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaveData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "day") {
      calculateLeavesInDays(leaveData.fromDate, leaveData.toDate, value);
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  };

  const handleFromDateChange = (event) => {
    setLeaveData((prevState) => ({
      ...prevState,
      fromDate: event.target.value,
      toDate: "",
      day: "",
    }));
    calculateLeavesInDays(event.target.value, leaveData.toDate);
  };

  const handleToDateChange = (event) => {
    setLeaveData((prevState) => ({
      ...prevState,
      toDate: event.target.value,
      day: "",
    }));
    calculateLeavesInDays(leaveData.fromDate, event.target.value);
  };

  const calculateLeavesInDays = (fromDate, toDate, day) => {
    if (!fromDate || !toDate) return;

    const start = new Date(fromDate);
    const end = new Date(toDate);
    let leavesInDays = 0;

    // Iterate through each date between start and end dates
    for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
      const dayOfWeek = date.getDay();

      // Check if the current date is not a weekend (Saturday or Sunday)
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        // If it's a full day leave, increment by 1
        if (day === "fullday") {
          leavesInDays++;
        }
        // If it's a half day leave, increment by 0.5
        else if (day === "halfday") {
          leavesInDays += 0.5;
        }
      }
    }

    setLeaveData((prevState) => ({
      ...prevState,
      noOfLeaves: leavesInDays,
    }));
  };

  const validateForm = () => {
    const requiredFields = [
      "leavetype",
      "fromDate",
      "toDate",
      "reasonForLeave",
      "day",
      "noOfLeaves",
    ];

    for (const field of requiredFields) {
      if (!leaveData[field]) {
        setTimeout(() => {
          setError("");
        }, 3000);

        return { isValid: false, message: `${field} is required` };
      }
    }
    if (!employeeCode) {
      return { isValid: false, message: `Employee Code is required` };
    }
    if (!employeeEmail) {
      return { isValid: false, message: `Employee Email is required` };
    }
    if (!employeeRemainingLeaves) {
      return {
        isValid: false,
        message: `Employee Remaining Leaves is required`,
      };
    }

    return { isValid: true };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data
    const validationResult = validateForm();

    if (validationResult.isValid) {
      try {
        // Fetch existing leave data for the user
        const res = await axios.get(
          `https://api.rekonsys.tech/employee-leaves?employeeCode=${employeeCode}`
        );
        const existingLeaves = res.data;

        // Check for overlapping leave
        const overlappingLeave = existingLeaves.find((leave) => {
          //console.log(leave.status);
          return (
            (leave.fromDate <= leaveData.fromDate &&
              leave.toDate >= leaveData.fromDate) ||
            (leave.fromDate <= leaveData.toDate &&
              leave.toDate >= leaveData.toDate) ||
            leave.status == "pending"
          );
        });

        if (overlappingLeave) {
          setError("Leave already applied for this date range");
          setTimeout(() => {
            setError("");
          }, 5000);
          return;
        }
      } catch (error) {
        console.error("Error fetching leave data", error);
        setError("An error occurred while fetching leave data");
        return;
      }

      // Proceed with leave application submission
      //console.log("Leave Application submitted:", leaveData);
      const formData = {
        ...leaveData,
        employeeRemainingLeaves: employeeRemainingLeaves,
        employeeCode: employeeCode,
        employeeName: employeeName,
        employeeEmail: employeeEmail,
      };

      // Set loading state
      setIsLoading(true);
      setSuccessMessage("leave applied");

      try {
        // Submit leave application
        const res = await axios.post(
          "https://api.rekonsys.tech/apply-leave",
          formData
        );

        if (res.status === 200) {
          // Handle success response
          setSuccessMessage(res.data.message);
          setIsLoading(false);
          setTimeout(() => {
            navigate("/employee-leaves-list");
          }, 4000);
        } else {
          // Handle other status codes
          setError(res.data.message);
        }
      } catch (error) {
        // Handle submission error
        console.error("Error submitting leave application", error);
        setError("An error occurred while submitting leave application");
        setIsLoading(false);
      }
    } else {
      // Handle form validation error
      setError(validationResult.message);
    }
  };

  const handleCancel = () => {
    navigate("/employee-leaves-list");
  };

  const successClose = () => {
    setSuccessMessage("");
  };

  const errorClose = () => {
    setError("");
  };

  return (
    <div>
      {successMessage && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-success"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-green-100 rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg ">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <span className="sr-only">Check icon</span>
            </div>
            <div className="ms-3 text-sm font-normal mx-5">
              {successMessage}
            </div>
            <button
              type="button"
              onClick={successClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
              data-dismiss-target="#toast-success"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      {error && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-danger"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-red-100  rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg ">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="ms-3 text-md font-normal mx-5">{error}</div>
            <button
              type="button"
              onClick={errorClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 "
              data-dismiss-target="#toast-danger"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          <BreadCrumbs
            paths={[
              { url: "/employee-dashboard", label: "Home" },
              { url: "/employee-leaves-list", label: "Leaves" },
              { url: "", label: "Apply Leave" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/employee-dashboard" },
              { text: "Attendance", path: "/employee-attendance-list" },
              { text: "Calender", path: "/admin-calendar" },
              { text: "Timesheets", path: "/employee-timesheet-list" },
              { text: "Leaves", path: "/employee-leaves-list", active: true },
            ]}
          />
        </div>

        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div>
            <header className="bg-white border-gray-300 border-b-2 m-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center">
                <h1 className="text-xl md:text-3xl pb-4 font-semibold">
                  Apply Leave
                </h1>
              </div>
            </header>
          </div>
          <div>
            <div className="mt-4 px-4 leading-relaxed flex flex-col gap-3 text-gray-700">
              <div className="w-full flex flex-col md:flex-row gap-5">
                <div className="w-full flex flex-col">
                  <label>Leave Type</label>
                  <select
                    className="w-full rounded-lg  border-2 border-gray-300 p-3 text-md"
                    name="leavetype"
                    value={leaveData.leavetype}
                    onChange={handleChange}
                  >
                    <option value="">Select Leave</option>
                    <option value="workfromhome">Work From Home</option>
                    <option value="sickleave">Sick Leave</option>
                    <option value="normalleave">Normal Leave</option>
                  </select>
                </div>
                <div className="w-full flex flex-col">
                  <label>Remaining Leaves</label>
                  <input
                    className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm bg-gray-300"
                    type="number"
                    value={employeeRemainingLeaves}
                    readOnly
                  />
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row gap-5">
                <div className="w-full flex flex-col">
                  <div className="mb-4">
                    <label
                      htmlFor="fromDate"
                      className="block text-gray-700  mb-2"
                    >
                      From Date
                    </label>
                    <input
                      type="date"
                      name="fromDate"
                      required
                      min={getCurrentDate()} // Set min attribute to the current date
                      value={leaveData.fromDate}
                      onChange={handleFromDateChange}
                      className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col">
                  <div className="mb-4">
                    <label
                      htmlFor="toDate"
                      className="block text-gray-700  mb-2"
                    >
                      To Date
                    </label>
                    <input
                      type="date"
                      name="toDate"
                      required
                      min={leaveData.fromDate} // Set min attribute to the selected fromDate
                      value={leaveData.toDate}
                      onChange={handleToDateChange}
                      className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      disabled={!leaveData.fromDate}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row gap-5">
                <div className="w-full flex flex-col">
                  <label>Day</label>
                  <select
                    className="w-full rounded-lg  border-2 border-gray-300 p-3 text-md"
                    name="day"
                    value={leaveData.day}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="fullday">Full Day</option>
                    <option value="halfday">Half Day</option>
                  </select>
                </div>
                <div className="w-full flex flex-col">
                  <label>Number of Days Leave</label>
                  <input
                    className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm bg-gray-300"
                    type="number"
                    value={leaveData.noOfLeaves}
                    readOnly
                  />
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="reason" className="block text-gray-700 mb-2">
                  Reason:
                </label>
                <textarea
                  name="reasonForLeave"
                  required
                  value={leaveData.reasonForLeave}
                  onChange={handleChange}
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex row justify-center gap-5 pt-2">
                <button
                  className="block rounded-md bg-blue-700 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-[#063081]"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
                <button
                  className="block rounded-md bg-red-500 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-red-700"
                  type="cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddLeave;
