import React, { useState, useEffect } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import DataTables from "../components/Datatables";
import axios from "axios";

function TimeSheetList() {
  const [timesheets, setTimesheets] = useState([]);
  const [filteredTimesheets, setFilteredTimesheets] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    setUserRole(localStorage.getItem("userRole"));
    fetchTimesheets();
  }, []);

  useEffect(() => {
    filterTimesheetsByDate();
  }, [startDate, endDate, timesheets]); // Trigger filtering whenever startDate, endDate, or timesheets change

  const fetchTimesheets = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://api.rekonsys.tech/alltimesheets");
      const reversedData = response.data.reverse();
      const formattedData = reversedData.map((data) => ({
        ...data,
        date: data.date.split("T")[0].slice(0, 10),
      }));
      setTimesheets(formattedData);
      setFilteredTimesheets(formattedData); // Set both timesheets and filteredTimesheets
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching timesheets:", error);
    }
  };

  const filterTimesheetsByDate = () => {
    let filteredData;
    if (startDate && !endDate) {
      const startDateObj = new Date(startDate);
      filteredData = timesheets.filter((timesheet) => {
        const timesheetDate = new Date(timesheet.date);
        return timesheetDate.getTime() === startDateObj.getTime();
      });
    } else {
      filteredData = timesheets.filter((timesheet) => {
        const timesheetDate = new Date(timesheet.date);
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        return timesheetDate >= startDateObj && timesheetDate <= endDateObj;
      });
    }
    setFilteredTimesheets(filteredData);
  };

  const clearFilters = () => {
    setStartDate("");
    setEndDate("");
    fetchTimesheets();
  };

  const columns = [
    { Header: "Sno.", accessor: "rowNumber", Cell: ({ row }) => row.index + 1 },
    { Header: "Employee Name", accessor: "employeeName" },
    { Header: "Employee Code", accessor: "employeeCode" },
    { Header: "Task", accessor: "task" },
    { Header: "Date", accessor: "date" },
    { Header: "Project", accessor: "project" },
    { Header: "Category", accessor: "category" },
    { Header: "Hours Worked", accessor: "hoursWorked" },
    { Header: "Bill Type", accessor: "billable" },
  ];

  return (
    <div>
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          
          {userRole == "admin" &&
          <>
          <BreadCrumbs
           paths={[
            { url: "/admin-dashboard", label: "Home" },
            { url: "/timesheet-list", label: "Timesheets" },
              { url: "", label: "Timesheets" },
          ]}
          />

          <Sidebar
             menuItems={[
              { text: "Home", path: "/admin-dashboard" },
              { text: "Employees", path: "/employee-list" },
              { text: "Attendance", path: "/attendance-list" },
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Timesheets", path: "/timesheet-list", active: true },
              { text: "Leaves", path: "/leaveslist"},
              { text: "Projects", path: "/projects-list" },
              { text: "Categories", path: "/categories-list" },
              { text: "Roles", path: "/role" },
              { text: "Departments", path: "/department" },
              { text: "Positions", path: "/position" },
              { text: "Reports", path: "/reports" },
            ]}
          />
          </>}
          {userRole == "manager" &&
          <>
          <BreadCrumbs
            paths={[
              { url: "/manager-dashboard", label: "Home" },
              { url: "/timesheet-list", label: "Timesheets" },
              { url: "", label: "Timesheets" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/manager-dashboard"},
              { text: "Attendance", path: "/attendance-list"},
              { text: "Timesheets", path: "/timesheet-list", active: true },
              { text: "Leaves", path: "/leaveslist" },
              { text: "Projects", path: "/projects-list" }
            ]}
          /> </>}
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div>
            <header className="bg-white border-gray-300 border-b-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center mb-2">
                <h1 className="text-xl md:text-3xl font-semibold">
                  Timesheets List
                </h1>
              </div>
            </header>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-5 my-2">
            <div className="w-full flex flex-col">
              <label htmlFor="startDate" className="mr-2">
                Start Date:
              </label>
              <input
                id="startDate"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="border border-gray-300 p-2 rounded-md mr-2"
                placeholder="Start Date"
              />
            </div>
            <div className="w-full flex flex-col">
              <label htmlFor="endDate" className="mr-2">
                End Date:
              </label>
              <input
                id="endDate"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                min={startDate} // Set min attribute to the selected start date
                disabled={!startDate} // Disable end date if start date is not selected
                className="border border-gray-300 p-2 rounded-md mr-2"
                placeholder="End Date"
              />
            </div>
            <div className="w-full flex md:mt-6">
              <button
                onClick={clearFilters}
                className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
              >
                Clear
              </button>
            </div>
          </div>
          <div>
            {isLoading ? (
              <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
                Loading Records.....
              </div>
            ) : (
              <div className="overflow-x-auto">
                {startDate || endDate ? ( // Check if either start date or end date is applied
                  filteredTimesheets && filteredTimesheets.length > 0 ? (
                    <DataTables columns={columns} data={filteredTimesheets} />
                  ) : (
                    <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
                      No records found
                    </div>
                  )
                ) : // If no filter is applied, display full table
                timesheets && timesheets.length > 0 ? (
                  <DataTables columns={columns} data={timesheets} />
                ) : (
                  <div className="w-full mt-10 p-4 bg-gray-100 text-gray-600 text-xl text-center">
                    No records found
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimeSheetList;
