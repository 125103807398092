import React, { useState, useEffect } from "react";
import Navbar from "../components/Dashboard/navbar";
import BreadCrumbs from "../components/Dashboard/BreadCrumbs";
import Sidebar from "../components/Dashboard/Sidebar";
import { Link } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";import axios from "axios";
import { FaTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import DataTables from "../components/Datatables";
import { useNavigate } from "react-router-dom";

function Role() {
  const [Role, setRole] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    fetchRole();
  }, []);
  const fetchRole = async () => {
    try {
      const response = await axios.get("https://api.rekonsys.tech/role-list");
      setRole(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const errorClose = () => {
    setError("");
  };
  const handleEdit = (roleId) => {
    // Navigate to the edit role page with the roleId
    navigate(`/role-update/${roleId}`);
  };

  const handleDelete = async (roleId) => {
    // Show confirmation dialog
    const confirmDelete = await Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this role!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    // If user confirms deletion
    if (confirmDelete.isConfirmed) {
      try {
        // Send delete request to server
        await axios.delete(`https://api.rekonsys.tech/role-delete/${roleId}`);
        // Remove the deleted role from the state
        setRole((prevRoles) => prevRoles.filter((role) => role._id !== roleId));
        // Show success message
        Swal.fire("Deleted!", "The role has been deleted.", "success");
      } catch (error) {
        console.error("Error deleting role:", error);
        // Show error message
        Swal.fire("Error", "Failed to delete the role.", "error");
      }
    }
  };

  const columns = [
    { Header: "Sno.", accessor: "rowNumber", Cell: ({ row }) => row.index + 1 },
    { Header: "Role Name", accessor: "name" },
    { Header: "Status", accessor: "status" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <div className="flex justify-center items-center">
          {/* Edit Button */}
          <FaRegEdit
            onClick={() => handleEdit(row.original._id)}
            style={{ cursor: "pointer" }}
            className="h-5 w-5 mr-2 text-blue-500"
          />
          {/* Delete Button */}
          <FaTrashAlt
            onClick={() => handleDelete(row.original._id)}
            style={{ cursor: "pointer" }}
            className="h-4 w-4 text-red-500"
          />
        </div>
      ),
      disableSortBy: true,
    },
  ];

  return (
    <>
      <Navbar />
      <div className="w-100 lg:grid lg:grid-cols-5 my-2">
        <div className="h-20 lg:h-32 rounded-lg m-2 w-100">
          <BreadCrumbs
            paths={[
              { url: "/admin-dashboard", label: "Home" },
              { url: "/role", label: "Roles" },
              { url: "", label: "Roles" },
            ]}
          />

          <Sidebar
            menuItems={[
              { text: "Home", path: "/admin-dashboard" },
              { text: "Employees", path: "/employee-list" },
              { text: "Attendance", path: "/attendance-list" },
              { text: "Calendar", path: "/admin-calendar" },
              { text: "Timesheets", path: "/timesheet-list" },
              { text: "Leaves", path: "/leaveslist" },
              { text: "Projects", path: "/projects-list" },
              { text: "Categories", path: "/categories-list" },
              { text: "Roles", path: "/role", active: true },
              { text: "Departments", path: "/department" },
              { text: "Positions", path: "/position" },
              { text: "Reports", path: "/reports" },
            ]}
          />
        </div>
        <div className="rounded-lg lg:col-span-4 p-4 m-2 shadow-xl border border-gray-200">
          <div>
            <header className="bg-white border-gray-300 border-b-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center mb-2">
                <h1 className="text-xl md:text-3xl font-semibold">Role List</h1>

                <Link
                  className="block rounded-md bg-[#6610f2] px-5 py-2.5 text-sm font-medium text-white transition hover:bg-teal-700"
                  to="/add-role"
                >
                  Add Role
                </Link>
              </div>
            </header>
          </div>
          <div>
            <DataTables columns={columns} data={Role} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Role;
