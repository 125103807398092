import React from "react";
import { useState } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment-timezone"
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

// const events = [
//   {
//     title: "All Day Event very long title",
//     start: new Date(2024, 2, 16, 10, 0, 0, 0),
//     end: new Date(2024, 2, 16, 13, 30, 0, 0),
//     desc: "This is a test event",
//   },
// ];

// console.log(events)

// [
//   {
//     "_id": "65fa7fdd5e4e018e09682202",
//     "title": "James Wedding",
//     "desc": "jhvkuwg",
//     "start": "2024-03-03T10:06:00.000Z",
//     "end": "2024-03-04T16:04:00.000Z",
//     "__v": 0
//   },
//   {
//     "_id": "65fa7ff65e4e018e09682206",
//     "title": "Vamsi Party",
//     "desc": "bwuf",
//     "start": "2024-03-11T04:30:00.000Z",
//     "end": "2024-03-12T21:00:00.000Z",
//     "__v": 0
//   },
//   {
//     "_id": "65fa86105e4e018e09682239",
//     "title": "kil",
//     "desc": "gyl",
//     "start": "2024-03-18T04:30:00.000Z",
//     "end": "2024-03-18T00:00:00.000Z",
//     "__v": 0
//   },
//   {
//     "_id": "65fa87b45e4e018e09682246",
//     "title": "fqdwyvkej",
//     "desc": " khgvdk",
//     "start": "2024-03-19T00:00:00.000Z",
//     "end": "2024-03-20T00:00:00.000Z",
//     "__v": 0
//   },
//   {
//     "_id": "65fac10578c0ab7c5884e7d9",
//     "title": "Karthik's BirthaDay",
//     "desc": "Best Wishesh to Karthik",
//     "start": "2024-03-20T00:00:00.000Z",
//     "end": "2024-03-23T00:00:00.000Z",
//     "__v": 0
//   },
//   {
//     "_id": "65fbc9f88fb91cbbe3f227e0",
//     "title": "cydwqcjydc",
//     "desc": " vcjkgvr",
//     "start": "2024-03-21T00:00:00.000Z",
//     "end": "2024-03-21T00:00:00.000Z",
//     "__v": 0
//   },
//   {
//     "_id": "65fd1059d988b0744e056b58",
//     "title": "fwa",
//     "desc": "wda",
//     "start": "2024-03-23T00:00:00.000Z",
//     "end": "2024-03-23T00:00:00.000Z",
//     "__v": 0
//   },
//   {
//     "_id": "65fd108dd988b0744e056b5e",
//     "title": "xf",
//     "desc": "xfsf",
//     "start": "2024-03-08T00:00:00.000Z",
//     "end": "2024-03-08T00:00:00.000Z",
//     "__v": 0
//   }
// ]


export default function Calendar(props) {

  const [activeTBN, setTbn] = useState("TODAY")
  const [activeMWD, setMwd] = useState("month")

  const CustomToolbar = ({ label, onView, onNavigate, views }) => {
    return (
      <div className="flex justify-between items-center bg-gray-200 p-4 rounded-lg shadow-lg mb-4 mt-2">
        <div className="flex flex-col md:flex-row gap-1">
          <button
            className={`ml-2 ${activeTBN === 'TODAY' ? "bg-[#063081]" : "bg-blue-600"} hover:bg-[#063081] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
            onClick={() => {
              onNavigate("TODAY")
              setTbn("TODAY")
            }}
          >
            Today
          </button>
          <button
            className={`ml-2 ${activeTBN === 'PREV' ? "bg-[#063081]" : "bg-blue-600"} hover:bg-[#063081] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
            onClick={() => {
              onNavigate("PREV")
              setTbn("PREV")
            }}
          >
            Back
          </button>
          <button
            className={`ml-2 ${activeTBN === 'NEXT' ? "bg-[#063081]" : "bg-blue-600"} hover:bg-[#063081] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
            onClick={() => {
              onNavigate("NEXT")
              setTbn("NEXT")
            }}
          >
            Next
          </button>
        </div>
        <h2 className="text-lg font-bold">{label}</h2>
        <div className="flex flex-col md:flex-row gap-1">
          {views.map((view) => (
            <button
              key={view}
              className={`ml-2 ${activeMWD === view ? "bg-[#063081]" : "bg-blue-600"} hover:bg-[#063081] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
              onClick={() => {
                onView(view)
                setMwd(view)
              }}
            >
              {view}
            </button>
          ))}
        </div>
      </div>
    );
  };



  return (
      <BigCalendar
        localizer={localizer}
        views={{
          month: true,
          week: true,
          day: true,
        }}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100vh" }}
        components={{
          toolbar: CustomToolbar,
        }}
        {...props}
      />
  );
}