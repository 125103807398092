import { useState, useEffect } from "react";
import axios from "axios";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { FcLeave } from "react-icons/fc";
import { MdOutlineEventAvailable } from "react-icons/md";

function TodayUpdates() {
  const [todayUpdates, setTodayUpdates] = useState(null);
  // console.log(todayUpdates)

  useEffect(() => {
    getTodayUpdates();
  }, []);

  const getTodayUpdates = async () => {
    try {
      const response = await axios.get("https://api.rekonsys.tech/today-updates");
      setTodayUpdates(response.data);
    } catch (e) {
      console.log({ message: e.message });
    }
  };

  const birthdayUsersList = (user) => {
    return (
      <article className="flex items-center gap-4 rounded-lg border-b border-gray-100 bg-white px-8 py-4">
        <span>
          <LiaBirthdayCakeSolid className="text-blue-500 h-6 w-6" />
        </span>
        <p>{`${user.firstName}'s Birthday Today...`}</p>
      </article>
    );
  };

  const eventsList = (event) => {
    return (
      <article className="flex items-center gap-4 rounded-lg border-b border-gray-100 bg-white px-8 py-4">
        <span>
          <MdOutlineEventAvailable className="text-blue-500 h-6 w-6" />
        </span>
        <p>{`${event.title} Today...`}</p>
      </article>
    );
  };

  const leavesList = (leave) => {
    return (
      <article className="flex items-center gap-4 rounded-lg border-b border-gray-100 bg-white px-8 py-4">
        <span>
          <FcLeave className="text-blue-500 h-6 w-6" />
        </span>
        <p>{`${leave.employeeName} is ${leave.leavetype} Today...`}</p>
      </article>
    );
  };

  const getTodayBirthdays = () => {
    const birthdays = todayUpdates.todayBirthdays;
    return birthdays.length > 0 ? (
      birthdays.map((user) => birthdayUsersList(user))
    ) : (
      <article className="flex items-center gap-4 rounded-lg border-b border-gray-100 bg-white px-8 py-3">
        <span>
          <LiaBirthdayCakeSolid className="text-blue-500 h-6 w-6" />
        </span>
        <p>There is no birthdays today...</p>
      </article>
    );
  };

  const getTodayLeaves = () => {
    const leaves = todayUpdates.todayLeaves;
    return leaves.length > 0 ? (
      leaves.map((leave) => leavesList(leave))
    ) : (
      <article className="flex items-center gap-4 rounded-lg border-b border-gray-100 bg-white px-8 py-3">
        <span>
          <FcLeave className="text-blue-500 h-6 w-6" />
        </span>
        <p>There is no leaves today...</p>
      </article>
    );
  };

  const getTodayEvets = () => {
    const events = todayUpdates.todayEvents;
    return events.length > 0 ? (
      events.map((event) => eventsList(event))
    ) : (
      <article className="flex items-center gap-4 rounded-lg border-b border-gray-100 bg-white px-8 py-3">
        <span>
          <MdOutlineEventAvailable className="text-blue-500 h-6 w-6" />
        </span>
        <p>There is no events today...</p>
      </article>
    );
  };

  return (
    <div className="bg-white w-full md:w-1/2 rounded-lg gap-2 shadow-md flex flex-col items-center">
      <div className="border-b border-gray-200 w-full text-center">
        <h3 className="text-xl font-semibold py-2">Today Updates</h3>
      </div>
      <div className="w-full h-80 flex flex-col justify-start overflow-y-auto">
        {todayUpdates && getTodayBirthdays()}
        {todayUpdates && getTodayLeaves()}
        {todayUpdates && getTodayEvets()}
      </div>
    </div>
  );
}

export default TodayUpdates;
