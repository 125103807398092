import React from "react";

const Testmonial = () => {
  return (
    <div>
      <div className="py-16 white">
        <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
          <h2 className="mb-12 text-center text-2xl text-gray-900 font-bold md:text-4xl">
          Build Trust and Credibility with Social Proof
          </h2>
          <div className="grid gap-8 md:grid-rows-2 lg:grid-cols-2">
            <div className="row-span-2 p-6 border border-gray-100 rounded-xl bg-gray-50 text-center sm:p-8">
              <div className="h-full flex flex-col justify-center space-y-4">
                <img
                  className="w-20 h-20 mx-auto rounded-full"
                  src="https://tailus.io/sources/blocks/grid-cards/preview/images/avatars/second_user.webp"
                  alt="user avatar"
                  height="220"
                  width="220"
                  loading="lazy"
                />
                <p className="text-gray-600 md:text-xl">
                  
                  <span className="font-serif">"</span> Working with Rekonsys has been a game-changer for our business. Their AI solutions have helped us automate processes, increase efficiency, and stay ahead of the competition.
                  <span className="font-serif">"</span>
                </p>
                <div>
                  <h6 className="text-lg font-semibold leading-none">
                  Sarah Johnson,
                  </h6>
                  <span className="text-xs text-gray-500">
                  COO of Acme Corporation
                  </span>
                </div>
              </div>
            </div>

            <div className="p-6 border border-gray-100 rounded-xl bg-gray-50 sm:flex sm:space-x-8 sm:p-8">
              <img
                className="w-20 h-20 mx-auto rounded-full"
                src="https://tailus.io/sources/blocks/grid-cards/preview/images/avatars/first_user.webp"
                alt="user avatar"
                height="220"
                width="220"
                loading="lazy"
              />
              <div className="space-y-4 mt-4 text-center sm:mt-0 sm:text-left">
                <p className="text-gray-600">
                  
                  <span className="font-serif">"</span> Rekonsys' expertise in Blockchain technology has been invaluable to us. Their solutions have enhanced transparency, security, and trust in our supply chain, resulting in tangible benefits for our organization.
                  <span className="font-serif">"</span>
                </p>
                <div>
                  <h6 className="text-lg font-semibold leading-none">
                  Michael Smith, 
                  </h6>
                  <span className="text-xs text-gray-500">
                  CEO of Global Logistics Inc.
                  </span>
                </div>
              </div>
            </div>
            <div className="p-6 border border-gray-100 rounded-xl bg-gray-50 sm:flex sm:space-x-8 sm:p-8">
              <img
                className="w-20 h-20 mx-auto rounded-full"
                src="https://tailus.io/sources/blocks/grid-cards/preview/images/avatars/third_user.webp"
                alt="user avatar"
                height="220"
                width="220"
                loading="lazy"
              />
              <div className="space-y-4 mt-4 text-center sm:mt-0 sm:text-left">
                <p className="text-gray-600">
                  
                  <span className="font-serif">"</span> We couldn't be happier with Rekonsys' mobile application development services. Their team's creativity, attention to detail, and commitment to quality have made our app a success in the market.
                  <span className="font-serif">"</span>
                </p>
                <div>
                  <h6 className="text-lg font-semibold leading-none">
                  David Brown,
                  </h6>
                  <span className="text-xs text-gray-500">
                  CTO of ABC Inc.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testmonial;
