import React, { useState, useEffect } from "react";
import Navbar from "../components/Dashboard/navbar";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const EditProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    password: "",
    gender: "",
    dob: "",
    contactNo: "",
    bloodGroup: "",
    emergencyContactNo: "",
    hobbies: "",
    permanentAddress: "",
    presentAddress: "",
    dateOfJoining: "",
    employeeCode: "",
    role: "",
    position: "",
    department: "",
    educationData: [{}],
    workExperienceData: [{}],
    bankAccountHolderName: "",
    bankAccountName: "",
    bankAccountNumber: "",
    IFSCCode: "",
    PANcardNo: "",
    UAN: "",
    permanentPresentSame: false,
  });
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    getEmployeeById();
  }, [id]);

  const getEmployeeById = async () => {
    try {
      const response = await axios.get(`https://api.rekonsys.tech/auth/user/${id}`);
      setFormData(response.data);
      setFormData((prevData) => ({
        ...prevData,
        dob: new Date(response.data.dob).toISOString().split("T")[0],
        educationData: response.data.education,
        workExperienceData: response.data.workExperience,
      }));
    } catch (error) {
      console.error("Error fetching user", error);
    }
  };

  const validateForm = () => {
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "password",
      "gender",
      "dob",
      "contactNo",
      "bloodGroup",
      "permanentAddress",
      "presentAddress",
    ];

    const fieldsTitles = {
      "firstName": "First Name",
      "lastName": "Last Name",
      "email":"Email",
      "password":"Password",
      "gender":"Gender",
      "dob": "DOB",
      "contactNo":"Contact No",
      "bloodGroup":"Blood Group",
      "permanentAddress":"Permanent Address",
      "presentAddress":"Present Address",
    };

    if (formData["contactNo"] && formData.contactNo.length < 10) {
      return {
        isValid: false,
        message: "Contact number cannot be less than 10 digits",
      };
    } else if (
      formData["emergencyContactNo"] &&
      formData.emergencyContactNo.length < 10
    ) {
      return {
        isValid: false,
        message: "Emergency contact number cannot be less than 10 digits",
      };
    }

    for (const field of requiredFields) {
      if (!formData[field]) {
        return { isValid: false, message: `${[fieldsTitles[field]]} is required` };
      }
    }

    return { isValid: true };
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
      presentAddress:
        name === "permanentPresentSame" && checked
          ? prevFormData.permanentAddress
          : prevFormData.presentAddress,
    }));
  };

  // const handleContactChange = (e) => {
  //   const { name, value } = e.target;

  //   // Ensure only numeric input
  //   if (/^[0-9\b]+$/.test(value)) {
  //     // Limit input to 4 digits
  //     if (value.length <= 10) {
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         [name]: value,
  //       }));
  //     }
  //   }
  // };

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    // Allow backspace and numeric input
    if (/^\d*$/.test(value) || value === "") {
      // Limit input to 10 digits
      if (value.length <= 10) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    }
  };

  const handleUserNameChange = (event) => {
    const { name, value } = event.target;
    const NewUserName = value.charAt(0).toUpperCase() + value.slice(1);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: NewUserName,
    }));
    // console.log(NewUserName)
  };

  const handlePresentAddressChange = (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      presentAddress: value,
    }));
  };

  const errorClose = () => {
    setError("");
  };
  const successClose = () => {
    setSuccessMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationResult = validateForm();
    if (validationResult.isValid) {

      const res = await axios.put(
        `https://api.rekonsys.tech/auth/edit-profile/${id}`,
        formData
      );
      setIsLoading(true);
      if (res.status == 200) {
        setSuccessMessage(res.data.message);
        setIsLoading(false);
        setTimeout(() => {
          if (localStorage.getItem("userRole") === "admin") {
            navigate("/admin-dashboard");
          } else if (localStorage.getItem("userRole") === "employee") {
            navigate("/employee-dashboard");
          }
        }, 2000);
      } else {
        setError(res.data.message);
      }
    } else {
      setError(validationResult.message);
    }
  };

  const handleCancelRoute = () => {
    const userRole = localStorage.getItem("userRole");
    navigate(`/${userRole}-dashboard`);
  };

  const handleResetEdit = () => {
    getEmployeeById();
    setSuccessMessage("User data reseted Successfully...");
  };

  // console.log(new Date(formData.dateOfJoining).toLocaleDateString())

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      {successMessage && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-success"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-green-100 rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg ">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <span className="sr-only">Check icon</span>
            </div>
            <div className="ms-3 text-sm font-normal mx-5">
              {successMessage}
            </div>
            <button
              type="button"
              onClick={successClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
              data-dismiss-target="#toast-success"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      {error && (
        <div className="fixed flex justify-center left-2/4 lg:left-1/2  -translate-x-1/2 w-max p-2 mt-4">
          <div
            id="toast-danger"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-red-100  rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg ">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="ms-3 text-md font-normal mx-5">{error}</div>
            <button
              type="button"
              onClick={errorClose}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 "
              data-dismiss-target="#toast-danger"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <Navbar />
      <div className="w-100 m-4">
        <div className="rounded-lg p-4 shadow-xl">
          <div>
            <header className="bg-white border-gray-300 border-b-2 m-2">
              <div className="w-full flex h-10 md:h-14 justify-between items-center">
                <h1 className="text-xl md:text-3xl pb-4 font-semibold">
                  Edit Profile
                </h1>
              </div>
            </header>
          </div>
          <div>
            <form onSubmit={handleSubmit} className="space-y-4 pb-3">
              <div className="mt-4 px-4 leading-relaxed flex flex-col gap-3 text-gray-700">
                <div className="w-full flex flex-col md:flex-row gap-5">
                  <div className="w-full flex flex-col">
                    <label>
                      First Name <span className="text-red-700">*</span>
                    </label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      placeholder="First Name"
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleUserNameChange}
                    />
                  </div>
                  <div className="w-full flex flex-col">
                    <label>Middle Name</label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      placeholder="Middle Name"
                      type="text"
                      name="middleName"
                      value={formData.middleName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full flex flex-col">
                    <label>
                      Last Name <span className="text-red-700">*</span>
                    </label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      placeholder="Last Name"
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleUserNameChange}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-row gap-5">
                  <div className="w-full flex flex-col">
                    <label>
                      Email: <span className="text-red-700">*</span>
                    </label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      placeholder="Email"
                      type="mail"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      disabled
                    />
                  </div>
                  <div className="w-full flex flex-col">
                    <label>
                      Password: <span className="text-red-700">*</span>
                    </label>
                    <div className="relative">
                      <input
                        className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                        placeholder="password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />

                      <span className="absolute inset-y-0 end-0 grid w-10 place-content-center">
                        <button
                          type="button"
                          className="text-gray-600 hover:text-gray-700"
                          onClick={handleShowPassword}
                        >
                          {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="w-full flex flex-col md:flex-row gap-5">
                  <div className="w-full flex flex-col">
                    <label>
                      Gender: <span className="text-red-700">*</span>
                    </label>
                    <select
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="w-full flex flex-col">
                    <label>
                      Date of Birth: <span className="text-red-700">*</span>
                    </label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      placeholder="Select Date of Birth"
                      type="date"
                      name="dob"
                      max={new Date().toISOString().split("T")[0]}
                      value={formData.dob}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full flex flex-col">
                    <label>
                      Contact: <span className="text-red-700">*</span>
                    </label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      placeholder="Contact"
                      type="text"
                      name="contactNo"
                      maxLength={10}
                      value={formData.contactNo}
                      onChange={handleContactChange}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-row gap-5">
                  <div className="w-full flex flex-col">
                    <label>
                      Blood Group: <span className="text-red-700">*</span>
                    </label>
                    <select
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      name="bloodGroup"
                      value={formData.bloodGroup}
                      onChange={handleChange}
                    >
                      <option value="">Select Blood Group</option>
                      <option value="A+">A+</option>
                      <option value="A-">A-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                    </select>
                  </div>
                  <div className="w-full flex flex-col">
                    <label>Emergency Contact No:</label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      placeholder="Emergency Contact No"
                      type="text"
                      name="emergencyContactNo"
                      maxLength={10}
                      onChange={handleContactChange}
                      value={formData.emergencyContactNo}
                    />
                  </div>
                  <div className="w-full flex flex-col">
                    <label>Hobbies:</label>
                    <input
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      placeholder="Hobbies"
                      type="text"
                      name="hobbies"
                      value={formData.hobbies}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-row gap-5">
                  <div className="w-full flex flex-col">
                    <label>
                      Permanent Address: <span className="text-red-700">*</span>
                    </label>
                    <textarea
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      placeholder="Permanent Address"
                      name="permanentAddress"
                      value={formData.permanentAddress}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full flex flex-col">
                    <label>
                      Present Address: <span className="text-red-700">*</span>
                    </label>
                    <textarea
                      className="w-full rounded-lg  border-2 border-gray-300 p-3 text-sm"
                      placeholder="Present Address"
                      name="presentAddress"
                      value={formData.presentAddress}
                      onChange={handlePresentAddressChange}
                      disabled={formData.permanentPresentSame}
                    />
                  </div>
                  <div className="w-full flex items-center mt-5">
                    <input
                      type="checkbox"
                      id="permanentPresentSame"
                      name="permanentPresentSame"
                      checked={formData.permanentPresentSame}
                      onChange={handleChange}
                    />
                    <label htmlFor="permanentPresentSame" className="ml-2">
                      Same as Permanent Address
                    </label>
                  </div>
                </div>
              </div>

              <div className="flex row justify-center gap-5 pt-3">
                <button
                  onClick={handleSubmit}
                  className="block rounded-md bg-blue-700 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-[#063081]"
                  type="submit"
                >
                  {isLoading ? "Submitting...." : "Submit"}
                </button>
                <button
                  onClick={handleResetEdit}
                  className="block rounded-md bg-red-500 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-red-700"
                  type="button"
                >
                  Reset
                </button>
                <button
                  onClick={handleCancelRoute}
                  className="block rounded-md bg-gray-600 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-gray-700"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
